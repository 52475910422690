import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { CardColumns } from "react-bootstrap";
import _ from "lodash";

import http from "../../services/APIService";

import ControlSystemContext from "../../contexts/ControlSystemContext";

import EqupmentCard from "./Equipment/EqupmentCard";
import LoadingSpinner from "./../common/LoadingSpinner";

import portDeviceSchema from "../../schemas/portDevice.json";
import portDeviceUiSchema from "../../schemas/portDeviceUi.json";
import portDeviceSchemaRules from "../../schemas/portDeviceRules.json";

//import switcherSchema from "../../schemas/switcher.json";
import switcherUiSchema from "../../schemas/switcherUi.json";

import audioDSPSchema from "../../schemas/audioDSP.json";
import audioDSPUiSchema from "../../schemas/audioDSPUi.json";

import touchPanelSchema from "../../schemas/touchPanel.json";
import touchPanelUiSchema from "../../schemas/touchPanelUi.json";

import keypadSchema from "../../schemas/keypad.json";
import keypadUiSchema from "../../schemas/keypadUi.json";

import sourceDeviceSchema from "../../schemas/sourceDevice.json";
import sourceDeviceUiSchema from "../../schemas/sourceDeviceUi.json";

import displayDeviceSchema from "../../schemas/displayDevice.json";
import displayDeviceUiSchema from "../../schemas/displayDeviceUi.json";

import videoConferenceCodecSchema from "../../schemas/videoConferenceCodec.json";
import videoConferenceCodecUiSchema from "../../schemas/videoConferenceCodecUi.json";
import SingularEqupmentCard from "./Equipment/SingularEqupmentCard";

//import videoProcessorSchema from "../../schemas/videoProcessor.json";
import videoProcessorUiSchema from "../../schemas/videoProcessorUi.json";
import videoProcessorSchemaRules from "../../schemas/videoProcessorRules.json";
import ControlSystem from "./ControlSystem";

function ControlSystemEquipment(props) {
  let { id: controlSystemId } = useParams();
  const controlSystem = useContext(ControlSystemContext);
  const [loading, setLoading] = useState("");

  const switcherType = "";

  const refresh = () => {
    console.log("TIME TO REFRESH");
    props.getControlSystem();
  };

  // async function getControlSystem() {
  //   setLoading("loading");

  //   const loadingTimeout = setTimeout(() => {
  //     setLoading("done");
  //   }, 500);

  //   const endpoint = "controlSystem/" + controlSystemId;
  //   console.log("controlSystemEquipment getControlSystem() endpoint", endpoint);
  //   const result = await http.callApi("get", endpoint);
  //   if (result) {
  //     console.log("current controlSystem : ", result.data);
  //     // setControlSystem(result.data);
  //     clearTimeout(loadingTimeout);
  //     setLoading("done");
  //     console.log("<><>< setControlSystem() result", result.data);
  //   }
  // }

  if (loading === "loading") return <h4>loading</h4>;

  if (
    controlSystem !== undefined &&
    !_.isEmpty(controlSystem) &&
    typeof controlSystem === "object"
  )
    return (
      <CardColumns>
        {/* PORT DEVICES */}
        <EqupmentCard
          controlSystem={controlSystem}
          icon="fa-expand"
          header="Control Port Expansion"
          title="Port Devices"
          description=""
          footer="If you require assistance, contact Control Gadgets"
          name="portDevice"
          idName="portDeviceId"
          commsInTitle
          //schemaController="portDeviceType/equipmentSchema/{{id}}"
          schema={portDeviceSchema}
          uiSchema={portDeviceUiSchema}
          showCopyButtons
          showDelete
          //formData={controlSystem.portDevices}
          performUpdate={refresh}
          //schemaRules={portDeviceSchemaRules} // was for testing conditions
        />

        {/* AV DISTRIBUTION/ SWITCHING */}
        <EqupmentCard
          controlSystem={controlSystem}
          icon="fa-exchange"
          header="Audio Visual Distribution"
          title={
            controlSystem?.switchers[0] !== undefined
              ? controlSystem.switchers[0].switcherType.name
              : "Add a Switcher or NVX"
          }
          description=""
          footer="If you require assistance, contact Control Gadgets"
          name="switcher"
          idName="switcherId"
          schemaController={
            controlSystem?.switchers[0] !== undefined
              ? "switcherType/equipmentSchema/{{id}}?switcherId=" +
                controlSystem.switchers[0].switcherId
              : "switcherType/equipmentSchema/{{id}}"
          }
          uiSchema={switcherUiSchema}
          //showCopyButtons
          showDelete
          singleInstance
          formData={
            controlSystem.switchers[0] !== undefined
              ? controlSystem.switchers[0]
              : []
          }
          link={
            controlSystem.switchers[0] !== undefined
              ? controlSystem.switchers[0].switcherType.categoryName.toLowerCase()
              : undefined
          }
          performUpdate={refresh}
        />
        {controlSystem.switchers[0] !== undefined &&
          Array.isArray(
            controlSystem.switchers[0].switcherType.secondarySwitcherTypeList
          ) &&
          controlSystem.switchers[0].switcherType.secondarySwitcherTypeList
            .length > 0 && (
            <EqupmentCard
              controlSystem={controlSystem}
              icon="fa-exchange"
              header="Secondary Audio Visual Distribution"
              title={
                controlSystem.switchers[1] !== undefined
                  ? controlSystem.switchers[1].switcherType.name
                  : "Add a Secondary Switcher or NVX"
              }
              description=""
              footer="If you require assistance, contact Control Gadgets"
              name="switcher"
              idName="switcherId"
              schemaController={
                controlSystem.switchers[1] !== undefined
                  ? `switcherType/equipmentSchema/{{id}}/?switcherId=
              ${controlSystem.switchers[1].switcherId}`
                  : "switcherType/equipmentSchema/{{id}}"
              }
              uiSchema={switcherUiSchema}
              //showCopyButtons
              showDelete
              singleInstance
              formData={
                controlSystem.switchers[1] !== undefined
                  ? controlSystem.switchers[1]
                  : []
              }
              link={
                controlSystem.switchers[1] !== undefined
                  ? controlSystem.switchers[1].switcherType.categoryName.toLowerCase()
                  : undefined
              }
              performUpdate={refresh}
            />
          )}

        {/* AUDIO DSP */}
        <EqupmentCard
          controlSystem={controlSystem}
          icon="fa-volume-up"
          header="Audio DSP"
          title="Audio DSP"
          description=""
          footer="If you require assistance, contact Control Gadgets"
          name="audioDSP"
          idName="audioDSPId"
          commsInTitle
          schemaController="audioDSPType/equipmentSchema/{{id}}"
          uiSchema={audioDSPUiSchema}
          showCopyButtons
          singleInstance
          showDelete
          //formData={controlSystem.audioDSP}
          performUpdate={refresh}
        />

        {/* USER INTERFACES/ TOUCH PANELS */}
        <EqupmentCard
          controlSystem={controlSystem}
          icon="fa-hand-o-up"
          header="User Interface"
          title="Touch Panels/ Xpanels"
          description=""
          footer="If you require assistance, contact Control Gadgets"
          name="userInterface"
          idName="userInterfaceId"
          commsInTitle
          schema={touchPanelSchema}
          uiSchema={touchPanelUiSchema}
          showCopyButtons
          showDelete
          formData={controlSystem.userInterfaces}
          performUpdate={refresh}
          // localSchema={{
          //   type: "object",
          //   properties: {
          //     xpanelId: {
          //       type: "number",
          //       title: "Main XPanel",
          //       cgpopulatelist: {
          //         pathToData: "userInterfaces",
          //         idField: "userInterfaceId",
          //         nameField: "name",
          //         filter:
          //           "u => u.userInterfaceType.name.toLowerCase().includes('xpanel')",
          //       },
          //     },
          //     userInterfaceId: {
          //       type: "number",
          //       title: "Main Touch Panel",
          //       cgpopulatelist: {
          //         pathToData: "userInterfaces",
          //         idField: "userInterfaceId",
          //         nameField: "name",
          //         filter:
          //           "u => u.userInterfaceType.name.toLowerCase().includes('xpanel') == false",
          //       },
          //     },
          //   },
          // }}
          // localUiSchema={{
          //   xpanelId: {
          //     classNames: "col-sm-6",
          //   },
          //   userInterfaceId: {
          //     classNames: "col-sm-6",
          //   },
          // }}
        />

        {/* SOURCE EQUIPMENT */}
        <EqupmentCard
          controlSystem={controlSystem}
          icon="fa-play-circle"
          header="Input Devices"
          title="Source Equipment"
          description=""
          footer="If you require assistance, contact Control Gadgets"
          name="sourceDevice"
          idName="sourceDeviceId"
          //schema={sourceDeviceSchema}
          schemaController="sourceDevice/equipmentSchema/{{id}}"
          uiSchema={sourceDeviceUiSchema}
          showCopyButtons
          showDelete
          formData={controlSystem.sourceDevices}
          performUpdate={refresh}
        />

        {/* DISPLAY DEVICES */}
        <EqupmentCard
          controlSystem={controlSystem}
          icon="fa-tv"
          header="Output Devices"
          title="Displays"
          description=""
          footer="If you require assistance, contact Control Gadgets"
          name="displayDevice"
          idName="displayDeviceId"
          //schema={displayDeviceSchema}
          schemaController="displayBrand/equipmentSchema/{{id}}"
          uiSchema={displayDeviceUiSchema}
          showCopyButtons
          showDelete
          //formData={controlSystem.displayDevices}
          performUpdate={refresh}
        />

        {/* VIDEO CONFERENCE CODEC */}
        {/* <EqupmentCard
          controlSystem={controlSystem}
          icon="fa-video-camera"
          header="Video Conferencing"
          title="VC Codec"
          description=""
          footer="If you require assistance, contact Control Gadgets"
          name="vcCodec"
          idName="vcCodecId"
          schemaController="vcCodec/schema/{{id}}"
          uiSchema={videoConferenceCodecUiSchema}
          showCopyButtons
          showDelete
          singleInstance
        /> */}

        {/* VIDEO PROCESSOR */}
        {/* <EqupmentCard
          controlSystem={controlSystem}
          icon="fa-video"
          header="Video Processor"
          title="Video Processor"
          description=""
          footer="If you require assistance, contact Control Gadgets"
          name="videoProcessor"
          schemaController="videoProcessor/equipmentSchema/{{id}}"
          uiSchema={videoProcessorUiSchema}
          showCopyButtons
          singleInstance
          showDelete
          formData={controlSystem.videoProcessor}
          schemaRules={videoProcessorSchemaRules}
          performUpdate={refresh}
        /> */}
      </CardColumns>
    );

  return (
    <>
      {console.log("controlSystem", controlSystem)}
      Control System :: {controlSystem}
    </>
  );
}

export default ControlSystemEquipment;
