import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";

import http from "../services/APIService";
import CustomForm from "./common/CustomForm";

const Mustache = require("mustache");

function Processor(props) {
  console.log("Processor props", props);

  const params = useParams();
  const [schema, setSchema] = useState(undefined);
  const [formData, setFormData] = useState(props.controlSystem?.processor);
  const [functionType, setFunctionType] = useState("Add New");
  const [functionIcon, setFunctionIcon] = useState("fa-plus");

  useEffect(() => {
    setFunctionType(props.controlSystem?.processor ? "Edit" : "Add New");
  }, [props.controlSystem]);

  const schemaJson = {
    title: "Processor",
    // description: "Add a new control system processor",
    type: "object",
    required: ["processorTypeId", "hostname", "mac"],
    properties: {
      organisationId: {
        type: "string",
      },
      controlSystemId: {
        type: "string",
        default: "{{id}}",
      },
      processorId: {
        type: "number",
      },
      processorTypeId: {
        type: "number",
        title: "Model",
      },
      hostname: {
        type: "string",
        title: "Hostname",
      },
      mac: {
        type: "string",
        title: "MAC",
      },
      username: {
        type: "string",
        title: "Username",
      },
      password: {
        type: "string",
        title: "Password",
      },
    },
  };

  const uiSchema = {
    organisationId: {
      "ui:widget": "hidden",
    },
    controlSystemId: {
      "ui:widget": "hidden",
    },
    processorId: {
      "ui:widget": "hidden",
    },
    processorTypeId: {
      classNames: "form-input-fixed",
      "ui:field": "cg:autoPopulateSelectField",
      "ui:options": {
        "cg:endpoint": "processorType",
        "cg:titleAccessor": "name",
        "cg:idAccessor": "processorTypeId",
        //"cg:filter": "i => i.name.toLowerCase().includes('av')",
      },
      //"ui:disabled": props.controlSystem?.processor !== undefined ?? false,
      "ui:placeholder": "--select--",
    },
    hostname: {
      classNames: "form-input-fixed",
    },
    mac: {
      classNames: "form-input-fixed",
    },
    username: {
      classNames: "form-input-fixed",
    },
    password: {
      classNames: "form-input-fixed",
    },
  };

  useEffect(() => {
    let renderedSChema = Mustache.render(JSON.stringify(schemaJson), params);
    console.log(
      "processor.jsx renderedSChema",
      JSON.parse(renderedSChema, undefined, 2)
    );
    setSchema(JSON.parse(renderedSChema, undefined, 2));
  }, []);

  const handleSubmit = async (e) => {
    if (props.controlSystem?.processor) {
      const result = await http.callApi(
        "put",
        `processor/${params.id}/${props.controlSystem?.processor.processorId}`,
        e.formData
      );
      if (result) {
        toast.success("Processor updated successfully");
      }
      props.handleClose();
    } else {
      console.log(
        "+++++++++++++++ handleSubmit() add processor",
        e.formData,
        params.id
      );

      const result = await http.callApi(
        "post",
        `processor/${params.id}`,
        e.formData
      );
      if (result) {
        toast.success("Processor added successfully");
        props.handleFormDataUpdate(result.data);
      }
    }
  };

  return (
    <>
      <Button variant="link" onClick={props.handleShow}>
        <span className={`fa ${functionIcon} mr-2`}></span>
        {functionType} Processor
      </Button>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className={`fa ${functionIcon} mr-2`}></span>
            {functionType} Processor
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {schema && (
            <CustomForm
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              handleSubmit={handleSubmit} // via the validate function of dynamic form then back here
              handleCancel={props.handleClose}
              controlSystemId={props.controlSystemId}
              controlSystem={props.controlSystem}
              rules={undefined} // must not use rules on this form so that it uses the non conditional form otherwise will fail
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          If you require assistance, contact Control Gadgets
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Processor;
