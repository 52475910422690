import React from "react";
import { Image } from "react-bootstrap";

import cg from "../../client-logos/cg.ico";
import monash from "../../client-logos/monash.ico";
import utas from "../../client-logos/utas-icon.png";
import uon from "../../client-logos/uon.ico";
import acu from "../../client-logos/acu-icon.png";
import massey from "../../client-logos/massey-icon.png";

function ClientIcon(props) {
  let icon = {};
  switch (props.org) {
    default:
    case "control gadgets":
      icon = cg;
      break;
    case "monash":
      icon = monash;
      break;
    case "utas":
      icon = utas;
      break;
    case "uon":
      icon = uon;
      break;
    case "acu":
      icon = acu;
      break;
    case "massey":
      icon = massey;
      break;
  }

  return (
    <React.Fragment>
      {props.org && (
        <Image
          src={icon}
          alt={props.org.toUpperCase()}
          title={props.org.toUpperCase()}
          //className="Client-icon"
          className="mr-1"
          height="16"
          width="16"
        />
      )}
    </React.Fragment>
  );
}

export default ClientIcon;
