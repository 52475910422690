import React, { useEffect, useState } from "react";

import http from "../../services/APIService";

function DynamicSchema(props) {
  const [data, setData] = useState(undefined);

  const getData = async () => {
    const result = await http.callApi(
      "get",
      `source/33c2d3d4-b327-4be5-39b9-08d919c96a15/11`
    );
    if (result) {
      setData(result.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      let newSchema = {
        title: "A registration form 1111",
        description: "A simple form example.",
        type: "object",
        properties: {
          source: {
            type: "number",
            enum: data.map((a) => a["sourceId"]),
            enumNames: data.map((a) => a["name"]),
          },
        },
      };
      props.onUpdate(newSchema);
    }
  }, [data]);

  return <div>Dynamic Schema has been built</div>;
}

export default DynamicSchema;
