import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import http from "../../services/APIService";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

function GenerateConfigFile(props) {
  const [configuration, setConfiguration] = useState({});
  const params = useParams();
  //   useEffect(() => {
  //     console.log(
  //       "generateConfigFile get config  .............................."
  //     );
  //     getConfiguration();
  //   }, [params.configurationId]);

  const getConfiguration = async () => {
    const result = await http.callApi(
      "get",
      `configuration/${props.controlSystem.controlSystemId}/${params.configurationId}/avSystem`
    );

    if (result) {
      console.log(
        "generateConfigFile.jsx :{} result.Data ",
        result !== undefined ? result.data : "NULL"
      );

      if (result.data) {
        setConfiguration(result.data);
        toast.success("Configuration System json Copied");
        copy(JSON.stringify(result.data, undefined, 2));
      }
    }
  };

  return (
    <div>
      <h1>Generate Config</h1>
      <Button onClick={getConfiguration}>Generate AV System</Button>
    </div>
  );
}

export default GenerateConfigFile;
