import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { CardColumns } from "react-bootstrap";
import _ from "lodash";

import http from "../../services/APIService";
import LoadingSpinner from "../common/LoadingSpinner";
import SwitcherCard from "./Equipment/SwitcherCard";

import ControlSystemContext from "../../contexts/ControlSystemContext";

function ControlSystemSwitcher(props) {
  let { id: controlSystemId } = useParams();
  const controlSystem = useContext(ControlSystemContext);
  const params = useParams();
  const [switcher, setSwitcher] = useState({});
  const [schema, setSchema] = useState({});
  const [inputs, setInputs] = useState([]);
  const [loading, setLoading] = useState("loading");

  console.log("CONTROLSYSTEM DMFRAME props", props);

  async function getCurrent() {
    const loadingTimeout = setTimeout(() => {
      setLoading("done");
    }, 500);

    const endpoint = `switcher/${controlSystemId}/${params.switcherId}`;

    const result = await http.callApi("get", endpoint);
    if (result) {
      setSwitcher(result.data);
      clearTimeout(loadingTimeout);
      setLoading("done");
      console.log(" getSwitcher() result", result);
    }
  }

  useEffect(() => {
    getCurrent();
  }, [controlSystemId]);

  const dmInputCardSchema = {
    title: "Switcher Input Configuration",
    type: "object",
    properties: {
      card: {
        type: "object",
        title: "",
        properties: {
          dmInputCardId: {
            type: "number",
          },
          dmInputCardTypeId: {
            type: "number",
            title: "Card Type",
          },
          switcherId: {
            type: "number",
          },

          dmTransmitters: {
            type: "array",
            title: "Transmitter",

            items: {
              type: "object",
              required: ["ipId"],
              properties: {
                dmTransmitterId: {
                  type: "number",
                },
                dmInputCardId: {
                  type: "number",
                },
                identifier: {
                  type: "string",
                },
                dmTransmitterTypeId: {
                  type: "number",
                  title: "Type",
                },
                name: {
                  type: "string",
                  title: "Name",
                },
                ipId: {
                  type: "number",
                  title: "IP ID",
                },
              },
            },
          },
        },
      },
    },
  };

  const dmInputCardUi = {
    card: {
      "ui:placeholder": "--select--",
      "ui:field": "cg:autoPopulateOneOfField",
      "ui:options": {
        "cg:endpoint": `dmInputCardType/cardAndTXTypes/${controlSystemId}/${switcher.switcherId}`,
        "cg:titleAccessor": "name",
        "cg:idAccessor": "dmInputCardTypeId",
      },
      dmInputCardId: {
        "ui:widget": "hidden",
      },
      switcherId: {
        "ui:widget": "hidden",
      },
      dmTransmitters: {
        "ui:options": {
          "cg:resultFieldNameAccessor": "dmTransmitterTypes",
          // because its nested i need to define here
          "cg:idAccessor": "dmTransmitterTypeId",
          "cg:titleAccessor": "name",
          "cg:maxItemsFieldAccessor": "numberOfInputs",
        },
        items: {
          dmTransmitterId: {
            "ui:widget": "hidden",
          },
          dmInputCardId: {
            "ui:widget": "hidden",
          },
          identifier: {
            "ui:widget": "hidden",
          },
          ipId: {
            "ui:field": "cg:autoPopulateSelectField",
            "ui:options": {
              "cg:endpoint": "controlSystem.occupiedIpIds",
              "cg:titleAccessor": "description",
              "cg:idAccessor": "itemId",
              "cg:pad": {
                min: 3,
                max: 254,
                prefix: "",
              },
              "cg:displayHex": true,
            },
            "ui:placeholder": "--select--",
            "ui:help": "Values are in HEX",
          },
        },
      },
    },
  };

  const dmOutputCardSchema = {
    title: "Switcher Output Configuration",
    type: "object",
    properties: {
      card: {
        type: "object",
        title: "",
        properties: {
          dmOutputCardId: {
            type: "number",
          },
          dmOutputCardTypeId: {
            type: "number",
            title: "Card Type",
          },
          switcherId: {
            type: "number",
          },
          dmReceivers: {
            type: "array",
            title: "Receiver",

            items: {
              type: "object",
              required: ["ipId"],
              properties: {
                dmReceiverId: {
                  type: "number",
                },
                dmOutputCardId: {
                  type: "number",
                },
                identifier: {
                  type: "string",
                },
                dmReceiverTypeId: {
                  type: "number",
                  title: "Type",
                },
                name: {
                  type: "string",
                  title: "Name",
                },
                ipId: {
                  type: "number",
                  title: "IP ID",
                },
                disableFusionReporting: {
                  type: "boolean",
                  title: "Disable Fusion Reporting",
                },
              },
            },
          },
        },
      },
    },
  };

  const dmOutputCardUi = {
    card: {
      "ui:placeholder": "--select--",
      "ui:field": "cg:autoPopulateOneOfField",
      "ui:options": {
        "cg:endpoint": `dmOutputCardType/cardAndTXTypes/${controlSystemId}/${switcher.switcherId}`,
        "cg:titleAccessor": "name",
        "cg:idAccessor": "dmOutputCardTypeId",
      },
      dmOutputCardId: {
        "ui:widget": "hidden",
      },
      switcherId: {
        "ui:widget": "hidden",
      },
      dmReceivers: {
        "ui:options": {
          orderable: false,
          "cg:resultFieldNameAccessor": "dmReceiverTypes",
          // because its nested i need to define here
          "cg:idAccessor": "dmReceiverTypeId",
          "cg:titleAccessor": "name",
          "cg:maxItemsFieldAccessor": "numberOfOutputs",
        },
        items: {
          dmReceiverId: {
            "ui:widget": "hidden",
          },
          dmOutputCardId: {
            "ui:widget": "hidden",
          },
          identifier: {
            "ui:widget": "hidden",
          },
          ipId: {
            "ui:field": "cg:autoPopulateSelectField",
            "ui:options": {
              "cg:endpoint": "controlSystem.occupiedIpIds",
              "cg:titleAccessor": "description",
              "cg:idAccessor": "itemId",
              "cg:pad": {
                min: 3,
                max: 254,
                prefix: "",
              },
              "cg:displayHex": true,
            },
            "ui:placeholder": "--select--",
            "ui:help": "Values are in HEX",
          },
        },
      },
    },
  };

  // const dmpsReceiverSchema = {
  //   title: "DMPS Receiver Configuration",
  //   type: "object",
  //   properties: {
  //     dmReceiverTypeId: {
  //       type: "number",
  //       title: "Receiver Type",
  //     },
  //     name: {
  //       type: "string",
  //     },
  //     ipId: {
  //       type: "number",
  //       title: "IP ID",
  //     },
  //     disableFusionReporting: {
  //       type: "boolean",
  //       title: "Disable Dusion Reporting",
  //     },
  //   },
  // };

  // const dmpsReceiverUiSchema = {
  //   dmOutputCardId: {
  //     "ui:widget": "hidden",
  //   },
  //   switcherId: {
  //     "ui:widget": "hidden",
  //   },
  // };

  return (
    <>
      <h5>DM Frame Configuration</h5>
      {console.log(
        "SWITCHER ",
        switcher,
        " TYPE CATEGOREY",
        switcher?.switcherType?.category
      )}
      <CardColumns>
        {!_.isEmpty(switcher) ? (
          <>
            <SwitcherCard
              icon="fa-server"
              header="Input Cards"
              title="Inputs"
              name="dmInputCard"
              endpoint="dmInputCard/{{id}}/{{switcherId}}"
              currentId={switcher.switcherId}
              idName="dmInputCardId"
              schema={dmInputCardSchema} // if no schema defined, it get one constructed on the server using schemaController prop
              //schemaController="dmInputCardType/schema/{{id}}/{{switcherId}}"
              uiSchema={dmInputCardUi}
              controlSystemId={controlSystemId}
              //showCopyButtons={true}
              showDelete={true}
              singleInstance={true}
              liveValidate={false}
              formTitle="DM Input Card"
              formTitleIcon="fa fa-pencil"
              performUpdate={() => {
                props.getControlSystem();
              }}
            />

            <SwitcherCard
              icon="fa-server"
              header="Output Cards"
              title="Outputs"
              endpoint="dmOutputCard/{{id}}/{{switcherId}}"
              name="dmOutputCard"
              currentId={switcher.switcherId}
              idName="dmOutputCardId"
              schema={dmOutputCardSchema} // if no schema defined, it get one constructed on the server using schemaController prop
              //schemaController="dmOutputCard/schema/{{id}}/{{switcherId}}"
              uiSchema={dmOutputCardUi}
              controlSystemId={controlSystemId}
              showCopyButtons={true}
              showDelete={true}
              singleInstance={true}
              liveValidate={false}
              formTitle="DM Output Card"
              formTitleIcon="fa fa-pencil"
              performUpdate={() => {
                props.getControlSystem();
              }}
            />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </CardColumns>
    </>
  );
}

export default ControlSystemSwitcher;
