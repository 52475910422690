import React from "react";

function RelatedList(props) {
  // console.log(
  //   "relatedList: formData ",
  //   props.formData,
  //   typeof props.formData["configSectionItemList"]
  // );

  return (
    <div>
      <h5>{props.name}</h5>
      <ul>
        {props.formData &&
          props.formData !== undefined &&
          props.formData[props.name] &&
          props.formData[props.name].map((item) => {
            return (
              <li>
                {item.name}
                <br /> {JSON.stringify(item.jsonSchema)}
                <hr />
                {item.items.map((subItem) => {
                  return (
                    <span>
                      ++ {subItem.name} <br />{" "}
                      {JSON.stringify(subItem.jsonFormData)}
                    </span>
                  );
                })}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default RelatedList;
