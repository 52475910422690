import React from "react";
import { Image } from "react-bootstrap";

import cg from "../../client-logos/cg-logo.png";
import monash from "../../client-logos/monash-logo.png";
import utas from "../../client-logos/utas-logo.png";
import uon from "../../client-logos/uon-logo.png";
import acu from "../../client-logos/acu-logo.png";
import massey from "../../client-logos/massey-logo.png";

function ClientLogo(props) {
  let logo = {};
  switch (props.org) {
    default:
    case "control gadgets":
      logo = cg;
      break;
    case "monash":
      logo = monash;
      break;
    case "utas":
      logo = utas;
      break;
    case "uon":
      logo = uon;
      break;
    case "acu":
      logo = acu;
      break;
    case "massey":
      logo = massey;
      break;
  }

  return (
    <React.Fragment>
      {props.org && (
        // <Badge className="client-logo-badge" variant="light mb-2">
        <Image
          src={logo}
          alt={props.org.toUpperCase()}
          title={props.org.toUpperCase()}
          //className="Client-logo"
          className="my-2"
          thumbnail
        />
        // </Badge>
      )}
    </React.Fragment>
  );
}

export default ClientLogo;
