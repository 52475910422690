import React from "react";
import { useParams, useHistory } from "react-router-dom";
import CustomForm from "../common/CustomForm";
import http from "../../services/APIService";
import { toast } from "react-toastify";

const schema = {
  title: "Processor",
  // description: "Add a new control system processor",
  type: "object",
  required: ["processorTypeId", "hostname", "mac"],
  properties: {
    organisationId: {
      type: "string",
    },
    controlSystemId: {
      type: "string",
      default: "{{id}}",
    },
    processorId: {
      type: "number",
    },
    processorTypeId: {
      type: "number",
      title: "Model",
    },
    hostname: {
      type: "string",
      title: "Hostname",
    },
    mac: {
      type: "string",
      title: "MAC",
    },
    username: {
      type: "string",
      title: "Username",
    },
    password: {
      type: "string",
      title: "Password",
    },
  },
};

const uiSchema = {
  organisationId: {
    "ui:widget": "hidden",
  },
  controlSystemId: {
    "ui:widget": "hidden",
  },
  processorId: {
    "ui:widget": "hidden",
  },
  processorTypeId: {
    "ui:field": "cg:autoPopulateSelectField",
    "ui:options": {
      "cg:endpoint": "processorType",
      "cg:titleAccessor": "name",
      "cg:idAccessor": "processorTypeId",
      //"cg:filter": "i => i.name.toLowerCase().includes('av')",
    },
    "ui:placeholder": "--select--",
  },
};

const addNewSchema = {
  title: "Add New",
  type: "object",
  required: ["name"],
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    locationObject: {
      title: "Location",
      type: "object",
    },
    systemType: {
      title: "System Type",
      type: "number",
      enum: [0, 1, 2],
      enumNames: ["Standard", "Pod", "Centralised Switching"],
      default: 0,
    },
  },
};

const addNewUiSchema = {
  name: {
    "ui:placeholder": "Enter Control System Name",
  },
  locationObject: {
    "ui:field": "cg:autoPopulateObjectField",
    "ui:options": {
      "cg:endpoint": "location/schema",
      "cg:titleAccessor": "name",
      "cg:idAccessor": "locationId",
    },
    locationId: {
      "ui:placeholder": "--select--",
      "ui:help-link": "/admin/location/new",
    },
    buildingId: {
      "ui:placeholder": "--select--",
    },
  },
};

function ControlSystemProcessor(props) {
  const params = useParams();
  const history = useHistory();

  const handleSubmit = async (e) => {
    if (params.id === "new") {
      console.log("Posting new Control System, formData: ", e.formData);
      const result = await http.callApi("post", "controlsystem", e.formData);
      if (result.status === 200) {
        toast.success("Item added successfully");
        //history.push("/controlsystem/" + result.data.controlSystemId);
        window.location = "/controlsystem/" + result.data.controlSystemId;
      } else {
        toast.error("Error occured adding new control system");
      }
    } else {
      try {
        const data = e.formData;

        console.log("control system put data", data);

        const result = await http.callApi(
          "put",
          `processor/${params.id}/${data.processorId}`,
          data
        );
        toast.success("Item updated successfully");
        console.log("Item updated successfully " + result);

        window.location = "/controlsystem/" + params.id + "/processor";
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {console.log("ControlSystemProcessor Props", props, "params", params)}
      <CustomForm
        schema={params.id === "new" ? addNewSchema : schema}
        uiSchema={params.id === "new" ? addNewUiSchema : uiSchema}
        formData={props.controlSystem?.processor}
        controlSystem={props.controlSystem}
        handleSubmit={(e) => handleSubmit(e)}
      />
    </>
  );
}

export default ControlSystemProcessor;
