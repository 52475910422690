import Form from "@rjsf/core";
import React, { useState, useEffect } from "react";

function MustacheTest(props) {
  const [schema, setSchema] = useState(undefined);

  const testSchema = {
    title: "Mustache Test",
    type: "object",
    properties: {
      configurationId: {
        type: "number",
        default: "{{configuration.configurationId}}",
      },
      controlSystemId: {
        type: "string",
        default: "{{controlSystem.controlSystemId}}",
      },
      "inputVideoZones Array Length": {
        type: "number",
        default:
          "{{configuration.videoProcessorConfig.inputVideoZones.length}}",
      },
      somethingRandom: {
        type: "string",
        default: "{{somethingRandom}}",
      },
    },
  };

  const Mustache = require("mustache");

  useEffect(() => {
    if (props.configuration) {
      let renderedSChema = Mustache.render(JSON.stringify(testSchema), props);
      console.log("renderedSChema", JSON.parse(renderedSChema, undefined, 2));
      setSchema(JSON.parse(renderedSChema, undefined, 2));
    }
  }, [props.configuration]);

  return (
    <div>
      {/* MUSTACHE {props.configuration?.configurationId} */}
      {schema && <Form schema={schema} />}
    </div>
  );
}

export default MustacheTest;
