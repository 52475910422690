import React, { useEffect, useState } from "react";
// packages
import {
  withRouter,
  Route,
  Redirect,
  Switch,
  useParams,
} from "react-router-dom";
import { ToastContainer, toast, Flip } from "react-toastify";
import { isEmpty } from "lodash";

// Services
import { AuthService } from "./services/AuthService";
import http from "./services/APIService";

// Contexts
import UserContext from "./contexts/userContext";

// Styles
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/react-simple-tree-menu/dist/main.css";

// Components
import Home from "./components/Home";
import ProtectedRoute from "./components/common/protectedRoute";

import PageLayout from "./components/common/PageLayout";
import { Admin } from "./components/admin/admin";

import ItemsContainer from "./components/admin/common/AdminItemsContainer";
import FormContainer from "./components/admin/common/FormContainer";

import DynamicFormContainer from "./components/admin/common/DynamicFormContainer";
import JsonEditor from "./components/admin/JsonEditor";

import ControlSystem from "./components/ControlSystem/ControlSystem";
import Configuration from "./components/ControlSystem/Configuration";

import ConditionalsTest from "./components/testing/conditionalsTest";
import AutoPopulateTest from "./components/testing/autoPopulateTest";

// json schemas
import systemType from "./schemas/admin/systemType.json";
import systemTypeUi from "./schemas/admin/systemTypeUi.json";
import organisation from "./schemas/admin/organisation.json";
import location from "./schemas/admin/location.json";
import locationUi from "./schemas/admin/locationUi.json";
import commProtocol from "./schemas/admin/commProtocol.json";
import commProtocolUi from "./schemas/admin/commProtocolUi.json";
import processorType from "./schemas/admin/processorType.json";
import processorTypeUi from "./schemas/admin/processorTypeUi.json";
import sourceType from "./schemas/admin/sourceType.json";
import sourceTypeUi from "./schemas/admin/sourceTypeUi.json";
import videoZoneType from "./schemas/admin/videoZoneType.json";
import videoZoneTypeUi from "./schemas/admin/videoZoneTypeUi.json";
import portDeviceType from "./schemas/admin/portDeviceType.json";
import portDeviceTypeUi from "./schemas/admin/portDeviceTypeUi.json";
import audioDSPType from "./schemas/admin/audioDSPType.json";
import audioDSPTypeUi from "./schemas/admin/audioDSPTypeUi.json";
import switcherType from "./schemas/admin/switcherType.json";
import switcherTypeUi from "./schemas/admin/switcherTypeUi.json";
import dmInputCardType from "./schemas/admin/dmInputCardType.json";
import dmInputCardTypeUi from "./schemas/admin/dmInputCardTypeUi.json";
import dmOutputCardType from "./schemas/admin/dmOutputCardType.json";
import dmOutputCardTypeUi from "./schemas/admin/dmOutputCardTypeUi.json";
import dmTransmitterType from "./schemas/admin/dmTransmitterType.json";
import dmTransmitterTypeUi from "./schemas/admin/dmTransmitterTypeUi.json";
import dmReceiverType from "./schemas/admin/dmReceiverType.json";
import dmReceiverTypeUi from "./schemas/admin/dmReceiverTypeUi.json";
import nvxEndpointType from "./schemas/admin/nvxEndpointType.json";
import nvxEndpointTypeUi from "./schemas/admin/nvxEndpointTypeUi.json";
import userInterfaceType from "./schemas/admin/userInterfaceType.json";
import userInterfaceTypeUi from "./schemas/admin/userInterfaceTypeUi.json";
import displayBrand from "./schemas/admin/displayBrand.json";
import displayBrandUi from "./schemas/admin/displayBrandUi.json";
import vcCodecBrand from "./schemas/admin/vcCodecBrand.json";
import vcCodecBrandUi from "./schemas/admin/vcCodecBrandUi.json";
import vcCodecBrandType from "./schemas/admin/vcCodecBrandType.json";
import vcCodecBrandTypeUi from "./schemas/admin/vcCodecBrandTypeUi.json";
import videoProcessorType from "./schemas/admin/videoProcessorType.json";
import videoProcessorTypeUi from "./schemas/admin/videoProcessorTypeUi.json";
// config
import configSectionGroup from "./schemas/admin/configSectionGroup.json";
import configSectionGroupUi from "./schemas/admin/configSectionGroupUi.json";
import configSectionType from "./schemas/admin/configSectionType.json";
import configSectionTypeUi from "./schemas/admin/configSectionTypeUi.json";
import configSectionSetType from "./schemas/admin/configSectionSetType.json";
import configSectionSetTypeUi from "./schemas/admin/configSectionSetTypeUi.json";

// template
import template from "./schemas/admin/template.json";
import templateUi from "./schemas/admin/templateUi.json";
import templateSection from "./schemas/admin/templateSection.json";
import templateSectionUi from "./schemas/admin/templateSectionUi.json";
// organisation config section types

import organisationSection from "./schemas/admin/organisationSection.json";
import organisationSectionUi from "./schemas/admin/organisationSectionUi.json";
import HelpComponentTest from "./components/testing/HelpComponentTest";
import autoPopulateTest from "./components/testing/autoPopulateTest";
import ConditionalsTestAdvanced from "./components/testing/conditionalsTestAdvanced";
import Dashboard from "./components/Dashboard";
import testDynamicSchema from "./components/testing/testDynamicSchema";
import context from "react-bootstrap/esm/AccordionContext";

// console.log(process.env);
// console.log(process.pid);
const authService = new AuthService();

function App() {
  const params = useParams();
  const { id: controlSystemId } = params;
  const [user, setUser] = useState(undefined);
  const [controlSystem, setControlSystem] = useState(undefined);

  // initial load data
  useEffect(() => {
    // user
    getUser();
  }, []);

  const getUser = async () => {
    const user = await authService.getUser();

    if (user) {
      //toast.success("User has been successfully loaded from data store.");
      console.log("user:> ", user);
      console.log("<><>");
    } else {
      //toast.info("You are not logged in.");
      console.log("You are not logged in.");
    }

    const token =
      user != null
        ? user.access_token != null
          ? user.access_token
          : ""
        : null;

    setUser(user);
  };

  if (user === undefined) return <></>;

  return (
    <UserContext.Provider value={user}>
      <PageLayout>
        <ToastContainer
          position="top-right"
          autoClose={1200}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          transition={Flip}
        />
        <Switch>
          <ProtectedRoute
            path="/admin/systemType/:id"
            component={DynamicFormContainer}
            name="systemType"
            requiredRoleClaim="cg.admin"
            schema={systemType}
            uiSchema={systemTypeUi}
            serverSideValidation={false}
          />
          <ProtectedRoute
            path="/admin/systemType"
            component={ItemsContainer}
            name="systemType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
          />

          <ProtectedRoute
            path="/admin/organisation/:id/configSectionType/:configSectionTypeId"
            component={DynamicFormContainer}
            name="organisation"
            morename="configSectionType"
            requiredRoleClaim="cg.admin"
            schema={organisationSection}
            uiSchema={organisationSectionUi}
            showCopyButtons
          />

          <ProtectedRoute
            path="/admin/organisation/:id/configSectionType"
            component={ItemsContainer}
            name="organisation"
            morename="configSectionType"
            requiredRoleClaim="cg.admin"
            showDelete
            idName="configSectionTypeId"
            nameField="configSectionTypeName"
          />

          <ProtectedRoute
            path="/admin/template/:id/configSectionType/:configSectionTypeId"
            component={DynamicFormContainer}
            name="template"
            morename="configSectionType"
            requiredRoleClaim="cg.admin"
            schema={templateSection}
            uiSchema={templateSectionUi}
            showCopyButtons
          />

          <ProtectedRoute
            path="/admin/template/:id/configSectionType"
            component={ItemsContainer}
            name="template"
            morename="configSectionType"
            returnPath="/admin/template"
            requiredRoleClaim="cg.admin"
            showDelete
            idName="configSectionTypeId"
            nameField="configSectionTypeName"
          />

          <ProtectedRoute
            path="/admin/template/:id"
            component={DynamicFormContainer}
            name="template"
            requiredRoleClaim="cg.admin"
            schema={template}
            uiSchema={templateUi}
            showCopyButtons
          />
          <ProtectedRoute
            path="/admin/template"
            component={ItemsContainer}
            name="template"
            requiredRoleClaim="cg.admin"
            showDelete={false}
            more="configSectionType"
            moretitle="Sections"
            showDelete
          />

          {/* <ProtectedRoute
              path="/admin/configSectionType/:id/configSectionSetType/:configSectionSetTypeId"
              component={DynamicFormContainer}
              name="configSectionType"
              morename="configSectionSetType"
              requiredRoleClaim="cg.admin"
              schema={configSectionSetType}
              uiSchema={configSectionSetTypeUi}
            />
            <ProtectedRoute
              path="/admin/configSectionType/:id/configSectionSetType"
              component={ItemsContainer}
              name="configSectionType"
              morename="configSectionSetType"
              returnPath="/admin/configSectionType"
              requiredRoleClaim="cg.admin"
              showDelete
            /> */}
          <ProtectedRoute
            path="/admin/configSectionType/:id"
            component={DynamicFormContainer}
            name="configSectionType"
            endpoint={`configSectionType`}
            //morename="configSectionSetType"
            requiredRoleClaim="cg.admin"
            schema={configSectionType}
            uiSchema={configSectionTypeUi}
            showCopyButtons
          />
          <ProtectedRoute
            path="/admin/configSectionType"
            component={ItemsContainer}
            name="configSectionType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
            // more="configSectionSetType"
            // moretitle="Sets"
          />
          <ProtectedRoute
            path="/admin/configSectionGroup/:id"
            component={DynamicFormContainer}
            name="configSectionGroup"
            endpoint={`configSectionGroup`}
            requiredRoleClaim="cg.admin"
            schema={configSectionGroup}
            uiSchema={configSectionGroupUi}
          />
          <ProtectedRoute
            path="/admin/configSectionGroup"
            component={ItemsContainer}
            name="configSectionGroup"
            requiredRoleClaim="cg.admin"
            //showDelete={false}
          />
          <ProtectedRoute
            path="/admin/vcCodecBrand/:id/vcCodecBrandType/:vcCodecBrandTypeId"
            component={DynamicFormContainer}
            name="vcCodecBrand"
            morename="vcCodecBrandType"
            requiredRoleClaim="cg.admin"
            schema={vcCodecBrandType}
            uiSchema={vcCodecBrandTypeUi}
            //serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/vcCodecBrand/:id/:vcCodecBrandType"
            component={ItemsContainer}
            name="vcCodecBrand"
            morename="vcCodecBrandType"
            returnPath="/admin/vcCodecBrand"
            requiredRoleClaim="cg.admin"
            showDelete
          />
          <ProtectedRoute
            path="/admin/vcCodecBrand/:id"
            component={DynamicFormContainer}
            name="vcCodecBrand"
            requiredRoleClaim="cg.admin"
            schema={vcCodecBrand}
            uiSchema={vcCodecBrandUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/vcCodecBrand"
            component={ItemsContainer}
            name="vcCodecBrand"
            more="vcCodecBrandType"
            moretitle="Types"
            requiredRoleClaim="cg.admin"
            //showDelete
          />
          <ProtectedRoute
            path="/admin/displayBrand/:id"
            component={DynamicFormContainer}
            name="displayBrand"
            requiredRoleClaim="cg.admin"
            schema={displayBrand}
            uiSchema={displayBrandUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/displayBrand"
            component={ItemsContainer}
            name="displayBrand"
            requiredRoleClaim="cg.admin"
            //showDelete
          />
          <ProtectedRoute
            path="/admin/userInterfaceType/:id"
            component={DynamicFormContainer}
            name="userInterfaceType"
            requiredRoleClaim="cg.admin"
            schema={userInterfaceType}
            uiSchema={userInterfaceTypeUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/userInterfaceType"
            component={ItemsContainer}
            name="userInterfaceType"
            requiredRoleClaim="cg.admin"
            //showDelete={true}
          />
          <ProtectedRoute
            path="/admin/portDeviceType/:id"
            component={DynamicFormContainer}
            name="portDeviceType"
            requiredRoleClaim="cg.admin"
            schema={portDeviceType}
            uiSchema={portDeviceTypeUi}
            serverSideValidation={false}
          />
          <ProtectedRoute
            path="/admin/portDeviceType"
            component={ItemsContainer}
            name="portDeviceType"
            requiredRoleClaim="cg.admin"
            //showDelete
          />
          <ProtectedRoute
            path="/admin/audioDSPType/:id"
            component={DynamicFormContainer}
            name="audioDSPType"
            requiredRoleClaim="cg.admin"
            schema={audioDSPType}
            uiSchema={audioDSPTypeUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/audioDSPType"
            component={ItemsContainer}
            name="audioDSPType"
            requiredRoleClaim="cg.admin"
            //showDelete={true}
          />
          <ProtectedRoute
            path="/admin/videoProcessorType/:id"
            component={DynamicFormContainer}
            name="videoProcessorType"
            requiredRoleClaim="cg.admin"
            schema={videoProcessorType}
            uiSchema={videoProcessorTypeUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/videoProcessorType"
            component={ItemsContainer}
            name="videoProcessorType"
            requiredRoleClaim="cg.admin"
            //showDelete={true}
          />
          <ProtectedRoute
            path="/admin/nvxEndpointType/:id"
            component={DynamicFormContainer}
            name="nvxEndpointType"
            requiredRoleClaim="cg.admin"
            schema={nvxEndpointType}
            uiSchema={nvxEndpointTypeUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/nvxEndpointType"
            component={ItemsContainer}
            name="nvxEndpointType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
          />
          <ProtectedRoute
            path="/admin/dmReceiverType/:id"
            component={DynamicFormContainer}
            name="dmReceiverType"
            requiredRoleClaim="cg.admin"
            schema={dmReceiverType}
            uiSchema={dmReceiverTypeUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/dmReceiverType"
            component={ItemsContainer}
            name="dmReceiverType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
          />
          <ProtectedRoute
            path="/admin/dmTransmitterType/:id"
            component={DynamicFormContainer}
            name="dmTransmitterType"
            requiredRoleClaim="cg.admin"
            schema={dmTransmitterType}
            uiSchema={dmTransmitterTypeUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/dmTransmitterType"
            component={ItemsContainer}
            name="dmTransmitterType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
          />
          <ProtectedRoute
            path="/admin/dmOutputCardType/:id"
            component={DynamicFormContainer}
            name="dmOutputCardType"
            requiredRoleClaim="cg.admin"
            schema={dmOutputCardType}
            uiSchema={dmOutputCardTypeUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/dmOutputCardType"
            component={ItemsContainer}
            name="dmOutputCardType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
          />
          <ProtectedRoute
            path="/admin/dmInputCardType/:id"
            component={DynamicFormContainer}
            name="dmInputCardType"
            requiredRoleClaim="cg.admin"
            schema={dmInputCardType}
            uiSchema={dmInputCardTypeUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/dmInputCardType"
            component={ItemsContainer}
            name="dmInputCardType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
          />
          <ProtectedRoute
            path="/admin/switcherType/:id"
            component={DynamicFormContainer}
            name="switcherType"
            requiredRoleClaim="cg.admin"
            schema={switcherType}
            uiSchema={switcherTypeUi}
            serverSideValidation={false}
            showCopyButtons={true}
          />
          <ProtectedRoute
            path="/admin/switcherType"
            component={ItemsContainer}
            name="switcherType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
          />
          <ProtectedRoute
            path="/admin/sourceType/:id"
            component={DynamicFormContainer}
            name="sourceType"
            requiredRoleClaim="cg.admin"
            schema={sourceType}
            uiSchema={sourceTypeUi}
            serverSideValidation={false}
          />
          <ProtectedRoute
            path="/admin/sourceType"
            component={ItemsContainer}
            name="sourceType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
            //additionalData={["Guid"]}
          />
          <ProtectedRoute
            path="/admin/videoZoneType/:id"
            component={DynamicFormContainer}
            name="videoZoneType"
            requiredRoleClaim="cg.admin"
            schema={videoZoneType}
            uiSchema={videoZoneTypeUi}
            serverSideValidation={false}
          />
          <ProtectedRoute
            path="/admin/videoZoneType"
            component={ItemsContainer}
            name="videoZoneType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
            //additionalData={["Guid"]}
          />
          <ProtectedRoute
            path="/admin/commProtocol/:id"
            component={DynamicFormContainer}
            name="commProtocol"
            requiredRoleClaim="cg.admin"
            schema={commProtocol}
            uiSchema={commProtocolUi}
            serverSideValidation={false}
          />
          <ProtectedRoute
            path="/admin/commProtocol"
            component={ItemsContainer}
            name="commProtocol"
            requiredRoleClaim="cg.admin"
            showDelete={false}
          />
          <ProtectedRoute
            path="/admin/processorType/:id"
            component={DynamicFormContainer}
            name="processorType"
            requiredRoleClaim="cg.admin"
            schema={processorType}
            uiSchema={processorTypeUi}
          />
          <ProtectedRoute
            path="/admin/processorType"
            component={ItemsContainer}
            name="processorType"
            requiredRoleClaim="cg.admin"
            showDelete={false}
            //additionalData={["Guid"]}
          />
          <ProtectedRoute
            path="/admin/location/:id"
            component={FormContainer}
            name="Location"
            requiredRoleClaim="av.manager"
            schema={location}
            uiSchema={locationUi}
          />
          <ProtectedRoute
            path="/admin/location"
            component={ItemsContainer}
            name="Location"
            requiredRoleClaim="av.manager"
            showDelete={true}
            // relatedData="Building"
            // relatedDataIcon="fa-building-o"
            //additionalData={["Guid"]}
          />
          <ProtectedRoute
            path="/admin/organisation/:id"
            component={FormContainer}
            name="Organisation"
            requiredRoleClaim="cg.admin"
            schema={organisation}
          />
          <ProtectedRoute
            path="/admin/organisation"
            component={ItemsContainer}
            name="organisation"
            requiredRoleClaim="cg.admin"
            showDelete={false}
            more="configSectionType"
            moretitle="Configuration Sections"
            //additionalData={["Guid"]}
          />
          {/* <Route
              path="/admin/organisation"
              render={props => {
                return <Organisation {...props} asd="bday" />;
              }}
            /> */}
          <ProtectedRoute
            exact
            path="/admin"
            component={Admin}
            requiredRoleClaim="av.manager"
          />
          <ProtectedRoute
            path="/controlsystem/:id"
            component={ControlSystem}
            requiredRoleClaim="av.manager"
          />

          {/* TESTS */}
          <Route path="/test/conditionals" exact component={ConditionalsTest} />
          <Route
            path="/test/conditionals/advanced"
            exact
            component={ConditionalsTestAdvanced}
          />
          <Route path="/test/help" exact component={HelpComponentTest} />
          <Route path="/test/jsonEditor" exact component={JsonEditor} />
          <Route path="/test/autoPopulate" exact component={AutoPopulateTest} />
          <Route
            path="/test/dynamicSchema"
            exact
            component={testDynamicSchema}
          />
          <Route path="/" component={Dashboard} />
          {/* <Route path="/" exact component={Home} /> */}
          <Redirect to="/not-found" />
        </Switch>
      </PageLayout>
    </UserContext.Provider>
  );
}

export default App;
