import axios from "axios";
import { toast } from "react-toastify";
import logger from "./logService";
import { AuthService } from "./AuthService";

import _ from "lodash";

const apiUrl = process.env.REACT_APP_AUTHORITY_API_BASEADDRESS;

const authService = new AuthService();

async function callApi(type, endpoint, data, dataType) {
  console.log("callingAPI() type: [", type, "] endpoint: ", apiUrl + endpoint);

  if (data !== undefined) console.log("callingAPI() data: ", data);

  const user = await authService.getUser();

  console.log("get user >", user);

  //toast("apiService user :: " + user.profile.name);
  //toast(type + " api call | " + apiUrl + endpoint);
  if (user && user.access_token) {
    try {
      //toast("get data1");
      return await _callApi(type, user.access_token, apiUrl + endpoint, data);
    } catch (error) {
      if (error.response === null || error.response === undefined) {
        // toast.error(
        //   "Error: No reponse from Configured.API."
        // );
        throw new Error(
          "Error: No response from Configured.API - contact Control Gadgets"
        );
      }
      if (error.response.status === 401) {
        //toast("401 renew token!");
        try {
          const renewedUser = await authService.renewToken();
          let renewResult = await _callApi(
            type,
            renewedUser.access_token,
            apiUrl + endpoint,
            data
          );
          console.log("renewResult", renewResult);
          //window.location = renewResult.config.url; // no good
          window.location = "/"; // TODO as i need to catch this exception, now have to go back to where the browser was
          // } else if (error.response.status === 405) {
          //   toast("405: Method Not Allowed");
        } catch (error) {
          console.log("renew token failed - need to login", error);
          toast.error("Session renewal failed, please login again");
          authService.removeCurrentUser();
          window.location = "/";
        }
      } else if (error.response.status === 403) {
        toast.error("403: Forbidden");
      } else if (
        error.response.status === 400 || // bad request
        error.response.status === 404 // not found (return rejected promise)
      ) {
        console.log("apiService ERROR:", error);
        toast.error(
          "Error: " + error.response.status + " " + error.response.statusText
        );
        return Promise.reject(error.response.data);
      } else if (error.response.status === 409) {
        // conflict
        toast.error("Server side validation failed");
        return Promise.reject(error.response);
      } else if (error.response.status === 422) {
        // unprocessable entity
        console.log("error.response.data", error.response.data);
        //toast.error("Server side validation failed");
        return Promise.reject(error);
      } else {
        console.log("apiService ERROR:", error.response);
        toast.error(
          "Error: " + error.response.status + " " + error.response.statusText
        );
        throw new Error(error);
      }
    }
  } else if (user) {
    //toast("get data3");
    const renewedUser = await authService.renewToken();
    return await _callApi(
      type,
      renewedUser.access_token,
      apiUrl + endpoint,
      data
    );
  } else {
    //throw new Error("user is not logged in");
    console.log("User is not logged in!");
    //window.location = "/";
  }
}

async function callRemoteApi(type, uri, data) {
  //console.log("callingRemoteAPI ", type, uri, data);

  const user = await authService.getUser();
  //toast("apiService user :: " + user.profile.name);
  //toast(type + " api call | " + apiUrl + endpoint);
  if (user && user.access_token) {
    try {
      //toast("get data1");
      return await _callApi(type, user.access_token, uri, data);
    } catch (error) {
      if (error.response === null) {
        //toast.error("No response from API, check it has been started");
        //throw new Error("no reponse from api");
        window.location.href =
          "/error?message=Error: no reponse from Configured.API - Contact Control Gadgets";
      }
      if (error.response.status === 401) {
        //toast("get data2");
        toast("Renew Token");
        const renewedUser = await authService.renewToken();
        return await _callApi(type, renewedUser.access_token, uri, data);
      }
      // else if (error.response.status === 405) {
      //   toast("405 error");
      // } else if (error.response.status === 403) {
      //   toast.error("Forbidden");
      // }
    }
  } else if (user) {
    //toast("get data3");
    const renewedUser = await authService.renewToken();
    return await _callApi(type, renewedUser.access_token, uri, data);
  } else {
    window.location.href = "/forbidden?claim=Error: user is not logged in";
    //throw new Error("user is not logged in");
  }
}

async function _callApi(type, token, endpoint, data) {
  //console.log("_callApi", type, endpoint);

  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  };

  //try {
  switch (type) {
    default:
    case "get":
      return await axios.get(endpoint, { headers });
    case "put":
      return await axios.put(endpoint, data, { headers });
    case "patch":
      return await axios.patch(endpoint, data, { headers });
    case "post":
      return await axios.post(endpoint, data, { headers });
    case "delete":
      return await axios.delete(endpoint, { headers });
  }
  // } catch (error) {
  //   // if (error.response.status === 401) {
  //   //   window.location.href =
  //   //     "/forbidden?claim=Error Code: " + error.response.status;
  //   // } else {
  //   if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  //     console.log("api error response", endpoint, error.response.status);
  //   } else {
  //     // redirect
  //     window.location.href =
  //       "/error?message=" +
  //       endpoint +
  //       " - Error Code: " +
  //       error.response.status;
  //   }
  //   //}
  // }
}

// const service = axios.create({
//   timeout: 20000 // request timeout
// });

// request interceptor
// service.interceptors.request.use(
//   config => {
//     // Do something before request is sent
//     config.headers["Authorization"] = "bearer " + getToken();
//     return config;
//   },
//   error => {
//     Promise.reject(error);
//   }
// );

axios.interceptors.response.use(null, (error) => {
  // (success, error)
  logger.log("axios intercepter called - error");
  console.log(
    "axios intercepter error",
    error,
    error.response,
    error.response.status
  );

  console.log("axios interceptaaa", error.response.statusText);

  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error, true, true);
  }
  // pass control to the outer try catch block, return a rejected promise

  //if (error.response.status === 401) authService.logout();

  return Promise.reject(error);
});

function setJWT(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

function setBearer(token) {
  console.log("setBearer token ", token);
  axios.defaults.headers["Authorization"] = "Bearer " + token;
}

function handleHttpErrors(error) {
  console.log("handleHttpErrors", error);
  let serverErrors = {};
  if (error?.response?.status === 422) {
    // expected error
    toast.error("Server side validation error(s), check form");
    console.log("//error.response.data.message//", error.response.data.message);
    // loop over return key value pairs
    if (error.response.data !== undefined) {
      console.log("KEYS", Object.keys(error.response.data));

      Object.keys(error.response.data).map((item) => {
        console.log("item", item);
        // key is path to property in serverErrors, val is error message to display
        let key = item; // usage key[0] as Object.keys returns an Array!!!
        let val = error.response.data[key];

        console.log("key", key);
        console.log("val", val);

        //console.log("hdnaleHttpErrors key[0]", key[0]);
        // https://lodash.com/docs/4.17.15#set
        // Sets the value at path of object.
        // If a portion of path doesn't exist, it's created.
        // Arrays are created for missing index properties while objects are created for all other missing properties. Use _.setWith to customize path creation.
        // _.set(serverErrors, key, val !== "" ? { __errors: [val] } : {});
        let msgArr = [];
        val.map((msg) => {
          msgArr.push(msg);
        });

        _.set(serverErrors, key, { __errors: msgArr });

        console.log("serverErrors:::", serverErrors);
      });
    }
    //console.log("serverErrors", JSON.stringify(serverErrors));
  } else {
    serverErrors = {};
  }
  return serverErrors;
}

export default {
  get: axios.get,
  put: axios.put,
  patch: axios.patch,
  post: axios.post,
  delete: axios.delete,
  setJWT,
  setBearer,
  apiUrl,
  callApi,
  callRemoteApi,
  handleHttpErrors,
};
