import React, { useState, useEffect } from "react";
import {
  Route,
  Link,
  useLocation,
  useParams,
  useHistory,
  useRouteMatch,
  Switch,
} from "react-router-dom";
import {
  ListGroup,
  Container,
  Row,
  Col,
  Badge,
  Alert,
  Button,
  Jumbotron,
  Nav,
  NavDropdown,
  Card,
} from "react-bootstrap";

import _ from "lodash";
import { toast } from "react-toastify";

import http from "../../services/APIService";
import LoadingSpinner from "./../common/LoadingSpinner";
import DynamicForm from "../common/DynamicForm";

import ProtectedRoute from "../common/protectedRoute";

import Sections from "./../Configuration/Sections";
import ConfigItemsSection from "./../Configuration/ConfigItemsSection";
import GenericSortedItemList from "../Configuration/GenericSortedItemList";

// schemas
import AudioDefaults from "../../schemas/configuration/audioDefaults.json";
import AudioDefaultsUi from "../../schemas/configuration/audioDefaultsUi.json";

import SystemOptions from "../../schemas/configuration/systemOption.json";
import SystemOptionsUi from "../../schemas/configuration/systemOptionUi.json";
import SystemOptionsRules from "../../schemas/configuration/systemOptionRules.json";

import GUI from "../../schemas/configuration/gui.json";
import GUIUi from "../../schemas/configuration/guiUi.json";
import GUIDefaults from "../../schemas/configuration/guiDefaults.json";
import GUIRules from "../../schemas/configuration/guiRules.json";

import Fusion from "../../schemas/configuration/fusion.json";
import FusionUi from "../../schemas/configuration/fusionUi.json";
import FusionRules from "../../schemas/configuration/fusionRules.json";

import Contacts from "../../schemas/configuration/contacts.json";
import ContactsUi from "../../schemas/configuration/contactsUi.json";
import ContactsDefaults from "../../schemas/configuration/contactsDefaults.json";
import ContactsRules from "../../schemas/configuration/contactsRules.json";

import Sources from "../../schemas/configuration/source.json";
import SourcesUi from "../../schemas/configuration/sourceUi.json";
import SourcesDefaults from "../../schemas/configuration/sourceDefaults.json";
import SourcesRules from "../../schemas/configuration/sourceRules.json";

import SourceGroup from "../../schemas/configuration/sourceGroup.json";
import SourceGroupUi from "../../schemas/configuration/sourceGroupUi.json";
// import SourceGroupDefaults from "../../schemas/configuration/sourceGroupDefaults.json";
// import SourceGroupRules from "../../schemas/configuration/sourceGroupRules.json";

import VideoZones from "../../schemas/configuration/videoZone.json";
import VideoZonesUi from "../../schemas/configuration/videoZoneUi.json";
import VideoZonesRules from "../../schemas/configuration/videoZoneRules.json";
import VideoZonesDefaults from "../../schemas/configuration/videoZoneDefaults.json";

// add new schema (cut down version to be used in modal for setting the TYPE)
// as it is getting too difficult to create schemas with different options for each type
import VideoZonesAddNew from "../../schemas/configuration/videoZoneAddNew.json";
import VideoZonesAddNewUi from "../../schemas/configuration/videoZoneAddNewUi.json";
// import VideoZonesRules from "../../schemas/configuration/videoZoneRules.json";
// import VideoZonesDefaults from "../../schemas/configuration/videoZoneDefaults.json";

import Displays from "../../schemas/configuration/displays.json";
import DisplaysUi from "../../schemas/configuration/displaysUi.json";
import DisplaysRules from "../../schemas/configuration/displaysRules.json";
import DisplaysDefaults from "../../schemas/configuration/displaysDefaults.json";

import VideoProcessorConfig from "../../schemas/configuration/videoProcessor.json";
//import VideoProcessorConfigUi from "../../schemas/configuration/videoProcessorUi.json";
import VideoProcessorConfigRules from "../../schemas/configuration/videoProcessorRules.json";
import VideoProcessorConfigUi from "../../schemas/configuration/videoProcessorUi";

import VideoProcessorModes from "../../schemas/configuration/videoProcessorMode.json";
import VideoProcessorModesUi from "../../schemas/configuration/videoProcessorModeUi";
import VideoProcessorModesRules from "../../schemas/configuration/videoProcessorModeRules.json";

import MustacheTest from "../testing/MustacheTest";
import ConfigurationMenu from "../Configuration/ConfigurationMenu";
import GenericSortedItemListWrapperForRelatedData from "./../Configuration/GenericSortedItemListWrapperForRelatedData";

// custom sections (wrappers of ConfifItemSection)
import ConfigItemsSection_VideoZone from "./../Configuration/ConfigItemsSection_VideoZone";
import ConfigItemsSection_Display from "./../Configuration/ConfigItemsSection_Display";

import TestDynamicSchema from "../testing/testDynamicSchema";

var jp = require("jsonpath");

const ArrayFieldTemplate = (props) => {
  return (
    <div>
      {props.items.map((element) => element.children)}
      {props.canAdd && (
        <button type="button" onClick={props.onAddClick}>
          Add+
        </button>
      )}
    </div>
  );
};

const CustomTitleField = (props) => {
  const {
    id,
    classNames,
    label,
    help,
    required,
    description,
    errors,
    children,
    showIdInTitle,
    preIdTitle,
  } = props;

  const lastIndex = id.lastIndexOf("_") + 1;
  var idNumber = parseInt(id.substr(lastIndex, id.length - lastIndex)) + 1;

  return (
    <div className={classNames}>
      <label htmlFor={id}>
        {`${preIdTitle ?? ""} ${showIdInTitle ? idNumber : null} `} {label}
        {required ? "*" : null}
      </label>
      {description}
      {children}
      {errors}
      {help}
    </div>
  );
};

const fields = {
  TitleField: (props) => <CustomTitleField {...props} showIdInTitle={true} />,
};

// const VideoProcessorConfigUi = {
//   configurationId: {
//     "ui:widget": "hidden",
//   },
//   name: {
//     "ui:readonly": true,
//   },
//   inputs: {
//     "ui:options": {
//       orderable: false,
//     },
//     //"ui:ArrayFieldTemplate": (props) => <ArrayFieldTemplate {...props} />,
//     items: {
//       "ui:FieldTemplate": (props) => (
//         <CustomTitleField
//           {...props}
//           preIdTitle={"Input"}
//           showIdInTitle={true}
//         />
//       ),
//     },
//   },
// };

const Schemas = {
  AudioDefaults,
  AudioDefaultsUi,
  GUI,
  GUIUi,
  GUIDefaults,
  GUIRules,
  Fusion,
  FusionUi,
  FusionRules,
  Contacts,
  ContactsUi,
  ContactsDefaults,
  ContactsRules,
  Sources,
  SourcesUi,
  SourcesRules,
  SourcesDefaults,
  SourceGroup,
  SourceGroupUi,
  // SourceGroupDefaults,
  // SourceGroupRules,
  VideoZones,
  VideoZonesUi,
  VideoZonesRules,
  VideoZonesDefaults,
  VideoZonesAddNew, // modal for a basic name and type to add a new videoZone, then can be editted, if want to change videoZone type delete it first and add a new one
  VideoZonesAddNewUi,
  Displays,
  DisplaysUi,
  DisplaysRules,
  DisplaysDefaults,
  SystemOptions,
  SystemOptionsUi,
  SystemOptionsRules,
  VideoProcessorConfig,
  VideoProcessorConfigUi,
  VideoProcessorConfigRules,
  VideoProcessorModes,
  VideoProcessorModesUi,
  VideoProcessorModesRules,
};

function ConfigurationForm(props) {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState("loading");
  const [controlSystem, setControlSystem] = useState(undefined);
  const [configuration, setConfiguration] = useState(undefined);

  useEffect(() => {
    if (props.controlSystem) setControlSystem(props.controlSystem);
  }, [props.controlSystem]);

  const loadingTimeout = setTimeout(() => {
    setLoading("done");
  }, 500);

  const [sections, setSections] = useState([]);

  const getSections = async () => {
    console.log("GET SECTIONS");
    const result = await http.callApi("get", `configSectionType`);

    if (result) {
      console.log(
        ">configuration.jsx :{} result.Data ",
        result !== undefined ? result.data : "NULL"
      );
      const sectionData = result.data;
      if (result.data) {
        setSections(result.data);
        console.log("setting sections menu data:>", result.data);
        console.log("params.sectionTypeId", params.sectionTypeId);

        clearTimeout(loadingTimeout);
      }
    }
  };

  const getConfiguration = async () => {
    // const set =
    //   params.setItemId !== undefined ? `/configSectionSetType/${params.setItemId}` : "";
    // const result = await http.callApi("get", "configSectionType" + type + set);
    const result = await http.callApi(
      "get",
      `configuration/${params.id}/${params.configurationId}`
    );

    if (result) {
      console.log(
        "configurationForm.jsx :{} result.Data ",
        result !== undefined ? result.data : "NULL"
      );

      if (result.data) {
        setConfiguration(result.data);
        console.log("setting current configuration:>", result.data);
        clearTimeout(loadingTimeout);
      }
    }
  };

  useEffect(() => {
    getSections();
    if (params.configurationId !== "new") {
      getConfiguration();
    }
  }, [params.id, params.configurationId, params.sectionTypeId]);

  const newConfigSchema = {
    title: "New Configuration",
    description: "Add a new configuration to the Control System",
    type: "object",
    required: ["name"],

    properties: {
      name: {
        type: "string",
        title: "System Name",
      },
    },
  };

  const updateSystemNameSchema = {
    title: "Control System",
    type: "object",
    required: ["name"],

    properties: {
      name: {
        type: "string",
        title: "Name",
      },
    },
  };

  const uiSchema = {
    name: {
      "ui:autofocus": true,
      "ui:placeholder": "Enter System Name",
    },
  };

  const handleSubmit = async (e) => {
    console.log("handleSubmit ", e);
    let result = {};
    if (params.configurationId === "new") {
      console.log(
        "Adding a new Configuration - post formdata to server :: formData",
        e.formData
      );
      let formData = e.formData;
      _.set(formData, "controlSystemId", params.id);
      console.log(">>> formData with CS", formData);
      result = await http.callApi(
        "post",
        "configuration" + `/${params.id}`,
        formData
      );
      console.log("post result", result);
      if (result && result.data && result.data.configurationId) {
        history.push(
          `/controlsystem/${params.id}/configuration/${result.data.configurationId}`
        );
      }
    } else {
      console.log(
        "updating an existing configuration (" +
          params.configurationId +
          ") section id (" +
          params.sectionTypeId +
          ") :: formData",
        e.formData
      );

      result = await handleChange(e.formData);
      if (result) {
        toast("Section updated successfully");
      }
    }
  };

  const handleChange = async (formData) => {
    console.log("HANDLE CHANGE formData : ", formData);
    let result = {};

    // if (section) {
    //   // update section data
    //   console.log(
    //     "put change : csId",
    //     params.id,
    //     "configId",
    //     configuration.configurationId,
    //     "sectionTypeId",
    //     section.configSectionTypeId
    //   );

    //   result = await http.callApi(
    //     "put",
    //     "configSection" +
    //       `/${params.id}/${params.configurationId}/${section.configSectionTypeId}`,
    //     formData
    //   );
    // } else {
    _.set(formData, "controlSystemId", params.id);
    _.set(formData, "configurationId", params.configurationId);
    // update configuration data (ie name)
    result = await http.callApi(
      "put",
      "configuration" + `/${params.id}/${params.configurationId}`,
      formData
    );

    if (result) {
      getConfiguration();
    }
    //}

    return result;
  };

  const handleSectionLink = (sectionTypeId) => {
    console.log("handleSectionLink sectionTypeId", sectionTypeId);
    history.push(
      `/controlsystem/${params.id}/configuration/${params.configurationId}/${sectionTypeId}`
    );
  };

  const handleFieldChange = (e) => {
    console.log("configurationForm.handleFieldChange() e", e);
  };

  const getExpressionArray = (schema) => {
    let expressionArray = [];
    let temp = [];

    temp = jp.paths(schema, "$..cgexpression");

    for (let path of temp) {
      path.pop(); // remove cgcontroller from path array
      let pathStr = jp.stringify(path);
      if (jp.query(schema, pathStr + ".cgdone")[0] === undefined)
        expressionArray.push(pathStr);
    }

    return expressionArray;
  };
  const executeUIExpressions = (uiSchema) => {
    console.log("executeUIExpressions() ", uiSchema);
    console.log(getExpressionArray(uiSchema));
  };

  // if (!configuration) return <>NO CONFIG</>;

  if (!controlSystem) {
    return <>Loading Configuration Form...</>;
  }

  return (
    <div>
      {/* <h1>Configuration Form</h1> */}
      <Alert variant="primary">
        <Badge variant="primary">Id {params.configurationId} </Badge>
        <span className="fa fa-file mx-2"></span>
        <Link
          to={`/controlsystem/${params.id}/configuration/${params.configurationId}`}
        >
          {params.configurationId !== "new"
            ? configuration?.name
            : "New Configuration"}
        </Link>
        {params.sectionTypeName && (
          <>
            <span className="fa fa-angle-double-right mx-2"></span>{" "}
            {params.sectionTypeName}
          </>
        )}
        {/* ConfigID {params.configurationId} */}
      </Alert>
      {params.configurationId !== "new" ? (
        <Container fluid>
          <Row>
            <Col lg="3">
              {/* left configuration section nav menu */}
              {/* <Sections current={params.sectionTypeId} sections={sections} /> */}
              <Card variant="secondary">
                <Card.Header>Configuration Sections</Card.Header>
                <Card.Body>
                  <ConfigurationMenu
                    controlSystem={controlSystem}
                    controlSystemId={params.id}
                    configurationId={params.configurationId}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Switch>
                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/sourceGroup/:itemId"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={ConfigItemsSection}
                  returnPath={`/controlsystem/${params.id}/configuration/${params.configurationId}/section/${params.sectionTypeId}/sourceGroup`}
                  //endpoint="source"
                  schemas={Schemas}
                />

                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/sourceGroup"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={GenericSortedItemList}
                  endpoint="sourceGroup"
                  orderable
                />

                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/source/:itemId"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={ConfigItemsSection}
                  returnPath={`/controlsystem/${params.id}/configuration/${params.configurationId}/section/${params.sectionTypeId}/sources`}
                  //endpoint="source"
                  schemas={Schemas}
                />
                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/sources"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={GenericSortedItemList}
                  endpoint="source"
                  orderable
                />

                {/* <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/videoZone/:itemId/display/:relatedItemId"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={ConfigItemsSection}
                  //endpoint="videoZone"
                  returnPath={`/controlsystem/${params.id}/configuration/${params.configurationId}/section/${params.sectionTypeId}/videoZones`}
                  schemas={Schemas}
                />

                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/videoZone/:itemId/displays"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={GenericSortedItemListWrapperForRelatedData}
                  sectionName="Video Zone"
                  itemName="Display"
                  endpoint={`display`}
                  returnPath={`/controlsystem/${params.id}/configuration/${params.configurationId}/section/${params.sectionTypeId}/videoZones`}
                  schemas={Schemas}
                /> */}

                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/videoZone/:itemId"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  // exact
                  component={ConfigItemsSection_VideoZone}
                  //endpoint="videoZone"
                  returnPath={`/controlsystem/${params.id}/configuration/${params.configurationId}/section/${params.sectionTypeId}/videoZones`}
                  schemas={Schemas}
                />
                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/videoZones"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={GenericSortedItemList}
                  endpoint="videoZone"
                  orderable
                  addItemUseModal
                  schemas={Schemas}
                  additionalData={["videoZoneTypeObject.type"]}
                  // allows jumping to display for the selected video zone
                  // relatedData="Displays"
                  // relatedSectionTypeId={12}
                  // relatedSectionTypeName="displays"
                  // queryParamName="videoZoneId"
                />
                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/display/:itemId"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={ConfigItemsSection}
                  returnPath={`/controlsystem/${params.id}/configuration/${params.configurationId}/section/${params.sectionTypeId}/displays`}
                  schemas={Schemas}
                  endpoint={"display"}
                  // if query param has a value, the results will be filtered for this specific item id
                  // relativeEndpoint="videoZone"
                  // relativeNameAccessor="name"
                  // relativeIdAccessor="videoZoneId"
                />
                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/displays"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={GenericSortedItemListWrapperForRelatedData}
                  endpoint="display"
                  orderable
                  // if query param has a value, the results will be filtered for this specific item id
                  // relativeEndpoint="videoZone"
                  // relativeNameAccessor="name"
                  // relativeIdAccessor="videoZoneId"
                />

                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/mustacheTest"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  somethingRandom={"yeh nah"}
                  exact
                  component={MustacheTest}
                />

                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/videoProcessorMode/:itemId"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={ConfigItemsSection}
                  returnPath={`/controlsystem/${params.id}/configuration/${params.configurationId}/section/${params.sectionTypeId}/videoProcessorModes`}
                  schemas={Schemas}
                />

                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/videoProcessorModes"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={GenericSortedItemList}
                  endpoint="videoProcessorMode"
                  returnPath={`/controlsystem/${params.id}/configuration/${params.configurationId}/section/29/videoProcessorConfig`}
                />

                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/videoProcessorConfig"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={ConfigItemsSection}
                  schemas={Schemas}
                  links={[
                    {
                      title: "Video Processor Modes",
                      path: `/controlsystem/${params.id}/configuration/${params.configurationId}/section/30/videoProcessorModes`,
                    },
                  ]}
                  returnPath={`/controlsystem/${params.id}/configuration/${params.configurationId}/section/29/videoProcessorConfig`}
                />

                {/* {console.log(">>++ ", params.sectionTypeName)} */}
                <ProtectedRoute
                  path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/fusion"
                  controlSystem={controlSystem}
                  configuration={configuration}
                  exact
                  component={ConfigItemsSection}
                  schemas={Schemas}
                />

                {console.log("CONTROL SYSTEM ", controlSystem)}
                {console.log("CONFIGURATION ", configuration)}
                {controlSystem && (
                  <ProtectedRoute
                    path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/:sectionTypeName"
                    controlSystem={controlSystem}
                    configuration={configuration}
                    exact
                    component={ConfigItemsSection}
                    schemas={Schemas}
                  />
                )}

                {!_.isEmpty(configuration) && (
                  <ProtectedRoute
                    path="/controlsystem/:id/configuration/:configurationId"
                    controlSystem={controlSystem}
                    configuration={configuration}
                    exact
                    component={DynamicForm}
                    controlSystemId={params.id}
                    configurationId={params.configurationId}
                    schema={updateSystemNameSchema}
                    uiSchema={uiSchema}
                    formData={{
                      name: configuration.name,
                    }}
                    handleSubmit={handleSubmit}
                    liveValidate
                    showErrorList={false}
                    serverSideValidation={false}
                    showCopyButtons
                  />
                )}
              </Switch>

              {/* CONFIGURATION INFO AND UPDATE NAME FORM */}
              {/* {params.sectionTypeId === undefined && configuration && (
                <>
                  <DynamicForm
                    controlSystemId={params.id}
                    configurationId={params.configurationId}
                    schema={updateSystemNameSchema}
                    //uiSchema={section.uiSchema}
                    formData={{ name: configuration.name }}
                    handleSubmit={handleSubmit}
                    liveValidate
                    showErrorList={false}
                    serverSideValidation={false}
                    showCopyButtons={false}
                    handleFormChange={handleChange}
                    hideSubmitAndCancel
                  />
                  <br />

                  <Jumbotron>
                    <h3>{configuration.name}</h3>
                    <p>Configuration information here</p>
                    <p>
                      <Button variant="primary">Learn more</Button>
                    </p>
                  </Jumbotron>
                </>
              )} */}
            </Col>
          </Row>
        </Container>
      ) : (
        // ADD NEW CONFIG
        <DynamicForm
          controlSystem={controlSystem}
          configuration={configuration}
          controlSystemId={params.id}
          configurationId={params.configurationId}
          schema={newConfigSchema}
          uiSchema={uiSchema}
          handleSubmit={handleSubmit}
          //onError={console.log("ConfigurationForm.jsx Add New Config DynamicForm errors")}
          liveValidate
          showErrorList={false}
          serverSideValidation={props.serverSideValidation}
          handleCancel={() =>
            props.history.push(`/controlsystem/${params.id}/configuration`)
          }
          showCopyButtons
        />
      )}
    </div>
  );
}

export default ConfigurationForm;
