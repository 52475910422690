import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import http from "../../services/APIService";
import GenericSortedItemList from "./GenericSortedItemList";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function GenericSortedItemListWrapperForRelatedData(props) {
  const params = useParams();
  const query = useQuery();
  const [relatedItem, setRelatedItem] = useState(undefined);
  console.log("GenericSortedItemListWrapperForRelatedData props", props);
  console.log("GenericSortedItemListWrapperForRelatedData params", params);

  let {
    controlSystem,
    configuration,
    relativeNameAccessor,
    relativeEndpoint,
    relativeIdAccessor,
    relatedDataReturnPath,
  } = props;

  let endpoint = `${props.endpoint}?${relativeIdAccessor}=${query.get(
    relativeIdAccessor
  )}`;

  let queryParamObj = query.get(relativeIdAccessor) && {
    name: relativeIdAccessor,
    value: query.get(relativeIdAccessor),
  };

  console.log(
    "GenericSortedItemListWrapperForRelatedData relativeIdAccessor",
    relativeIdAccessor,
    query.get(relativeIdAccessor)
  );
  const getData = async () => {
    const result = await http.callApi(
      "get",
      `${relativeEndpoint}/${controlSystem.controlSystemId}/${
        configuration.configurationId
      }/${query.get(relativeIdAccessor)}`
    );
    if (result) {
      setRelatedItem(result.data);
    }
  };
  useEffect(() => {
    if (controlSystem && configuration) getData();
  }, [controlSystem, configuration]);

  return (
    <>
      {query.get(relativeIdAccessor) && relatedItem && (
        <>
          <h1>
            {relativeEndpoint}
            {relatedItem[relativeIdAccessor]} "
            {relatedItem[relativeNameAccessor]}"
          </h1>
          <h5>{relativeEndpoint}</h5>
          <h5>
            {relativeIdAccessor} {query.get(relativeIdAccessor)}
          </h5>
          <Link to={relatedDataReturnPath}>Back</Link>
          <hr />
        </>
      )}
      <GenericSortedItemList
        sectionName={props.itemName}
        endpoint={endpoint}
        queryParamObj={queryParamObj}
        {...props}
      />
    </>
  );
}

export default GenericSortedItemListWrapperForRelatedData;
