// AutoPopulateObjectFieldTemplate
// this is used to load a field's schema directly to an object type element
// ie mainly used for loading oneOf schemas built on the backend

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import http from "../../../services/APIService";
import _ from "lodash";
const Mustache = require("mustache");

function AutoPopulateObjectFieldTemplate(props) {
  const params = useParams();
  const {
    id,
    name,
    classNames,
    required,
    idSchema,
    schema,
    //uiSchema,
    formData,
    formContext, // holds extra props (ie configuration, controlSystem)
    onChange,
    registry,
    errorSchema,
    idPrefix,
  } = props;

  const { TitleField, DescriptionField, ObjectField, OneOfField, SchemaField } =
    registry.fields;

  console.log("AutoPopulateObjectFieldTemplate props", props);

  const getData = async (tmpSchema) => {
    let mustachedUISchema = props.uiSchema;
    try {
      mustachedUISchema = JSON.parse(
        Mustache.render(JSON.stringify(props.uiSchema), props.formContext),
        undefined,
        2
      );
    } catch (ex) {
      console.error("Mustache failed on uiSchema", ex);
      mustachedUISchema = props.uiSchema;
    }
    console.log(
      "AutoPopulateObjectFieldTemplate mustachedUISchema",
      mustachedUISchema
    );

    let options = mustachedUISchema["ui:options"];

    if (!options) return;
    let endpoint = options["cg:endpoint"];

    let results = _.get(formContext, endpoint);
    // try get data from local configuration (eg props.FormContext.configuration or props.FormContext.controlSystem) first
    console.log("AutoPopulateObjectFieldTemplate getData()", props, results);

    if (endpoint && !results) {
      const result = await http.callApi("get", endpoint);

      if (!result || result.status !== 200) return;
      results = result.data;
    }

    console.log("AutoPopulateObjectFieldTemplate results", results);

    if (results instanceof Object) {
      formContext.setFieldSchema(name, results);
    }
  };

  useEffect(() => {
    if (schema !== undefined) {
      console.log("AutoPopulateObjectFieldTemplate GETDATA USEEFFECT CALLED");
      getData(schema);
    }
  }, []);

  return <></>;
}

export default AutoPopulateObjectFieldTemplate;
