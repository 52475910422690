import React from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

function CopyToClip(props) {
  return (
    <CopyToClipboard onCopy={() => toast("Copied")} text={props.text}>
      <Button variant="light" className="btn btn-sm mt-1">
        <i className="fas fa-copy mr-2"></i>
        {props.label}
      </Button>
    </CopyToClipboard>
  );
}

export default CopyToClip;
