import React, { useEffect, useState } from "react";

import _ from "lodash";

function LoadingSpinner({ result, error, title, timeout, state, data }) {
  const [loading, setLoading] = useState("loading");
  const [description, setDescription] = useState(title);
  let timerId = null;

  // useEffect(() => {
  //   timerId = setTimeout(() => {
  //     if (!_.isEmpty(error)) {
  //       setDescription(error.title + " (" + error.status + ")");
  //       console.log("loading description", description);
  //     } else {
  //       if (_.isEmpty(data)) setLoading("no data");
  //       else setLoading("done");
  //       console.log("timeout ended", data, result);
  //     }
  //   }, timeout);
  // }, []);

  return (
    <React.Fragment>
      {loading === "no data" ? (
        <>
          <span className="fa fa-info mr-2"></span>
          <span>No Data</span>
        </>
      ) : loading === "done" ? (
        console.log("done loading")
      ) : (
        <>
          <span className="fa fa-spinner fa-spin mr-2"></span>
          <span>{title ?? "Loading data.."}</span>
        </>
      )}
    </React.Fragment>
  );
}

export default LoadingSpinner;
