import React from "react";

import applyRules from "react-jsonschema-form-conditionals";
import Engine from "json-rules-engine-simplified";
import Form from "react-jsonschema-form";

let schema = {
  title: "A registration form tests",
  description: "A simple form example.",
  type: "object",
  required: ["firstName"],
  properties: {
    firstName: {
      type: "string",
      title: "First name",
    },
    lastName: {
      type: "string",
      title: "Last name",
    },
    choice: {
      type: "number",
      title: "Choice",
      enum: [0, 1, 2],
    },
  },
  dependencies: {
    choice: {
      oneOf: [
        {
          properties: {
            choice: {
              enum: [0],
            },
            asd: {
              type: "string",
            },
          },
        },
      ],
    },
  },
};

let rules = [
  {
    conditions: {
      choice: { is: 0 },
    },
    event: {
      type: "require",
      params: {
        //field: "$.dependencies.choice.oneOf[0].properties.asd",
        field: "lastName",
      },
    },
  },
  {
    conditions: {
      lastName: { is: "allen" },
    },
    event: {
      type: "require",
      params: {
        field: "choice",
      },
    },
  },
];

let FormWithConditionals = applyRules(schema, {}, rules, Engine)(Form);
function ConditionalsTest(props) {
  return (
    <div>
      <FormWithConditionals />
    </div>
  );
}

export default ConditionalsTest;
