import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import { Modal, Button, Badge, Table } from "react-bootstrap";

import { toast } from "react-toastify";
import _ from "lodash";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import http from "../../services/APIService";

import ItemList from "../admin/common/itemList";
import ControlSystem from "./../ControlSystem/ControlSystem";

import ConfigItemsSection from "./ConfigItemsSection";

function GenericSortedItemList(props) {
  const params = useParams();
  const location = useLocation();

  const [data, setData] = useState({});

  const [modalState, setModalState] = useState(false);

  const endpoint = props.endpoint;
  const itemName = props.itemName || _.startCase(endpoint);
  const idName = props.idName || props.endpoint + "Id";

  async function getData() {
    const result = await http.callApi(
      "get",
      `${endpoint}/${params.id}/${params.configurationId}`
    );
    console.log("GenericSortedItemList DATA", result.data);
    if (result) {
      setData(result.data);
    }
  }

  useEffect(() => {
    console.log("SECTION CHANGED", params.sectionTypeId);
    getData();
  }, [params.sectionTypeId]);

  const handleClick = (e) => {
    console.log("GenericSortedItemList.handleClick()", e);
    if (e.edit !== undefined) {
      //console.log("handleClick", e.edit);
      let url = `${endpoint}/${e.edit}`;
      if (props.queryParamObj) {
        url += `?${props.queryParamObj.name}=${props.queryParamObj.value}`;
      }
      props.history.push(url);
    } else if (e.delete !== undefined) {
      //console.log("handleClick", e.delete);
      handleDelete(e.delete);
    } else if (e.relatedData && props.queryParamName) {
      let url = `/controlSystem/${props.controlSystem.controlSystemId}/configuration/${props.configuration.configurationId}/section/${props.relatedSectionTypeId}/${props.relatedSectionTypeName}?${props.queryParamName}=${e.itemId}`;
      props.history.push(url);
    }
  };

  const handleDelete = (itemId) => {
    console.log("handleDelete ", itemId, endpoint, params.configurationId);

    confirmAlert({
      title: "Please Confirm",
      message:
        "This will permenently delete the selected " +
        itemName +
        ". Do you want to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            // console.log("delete", id);
            try {
              await http.callApi(
                "delete",
                `${endpoint}/${params.configurationId}/${itemId}`
              );
              toast.info(itemName + " deleted successfully");
              //window.location.reload();
              getData();
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const updateListOrder = async (idArray) => {
    console.log("GenericSortedItemList.updateListOrder()", idArray);
    const result = await http.callApi(
      "post",
      `${endpoint}/sort/${params.id}/${params.configurationId}`,
      idArray
    );
    setData(result.data);
  };

  const handleClose = () => {
    setModalState(false);
  };

  const handleOnSuccess = () => {
    console.log("handleOnSuccess");
    setModalState(false);
    getData();
  };

  return (
    <>
      <Modal
        dialogClassName="modal-standard"
        show={modalState}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a New {itemName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.addItemUseModal && props.schemas && (
            <ConfigItemsSection
              addNew
              {...props}
              onSuccess={handleOnSuccess}
              onCancel={handleClose}
            />
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      {props.returnPath && (
        <Link to={props.returnPath} className="mr-2">
          <span className="fa fa-arrow-left mr-1"></span>Back
        </Link>
      )}

      {props.queryParamObj ? (
        <Link
          to={`./${endpoint}/new?${props.queryParamObj.name}=${props.queryParamObj.value}`}
        >
          <Badge variant="primary" className="mr-1">
            +
          </Badge>
          Add a New {itemName}
        </Link>
      ) : props.addItemUseModal ? (
        <Button
          variant="link"
          size="sm"
          onClick={() => {
            console.log("show modal");
            setModalState(true);
          }}
        >
          <Badge variant="primary" className="mr-1">
            +
          </Badge>
          Add a New {itemName}
        </Button>
      ) : (
        <Link to={`./${endpoint}/new`}>
          <Badge variant="primary" className="mr-1">
            +
          </Badge>
          Add a New {itemName}
        </Link>
      )}

      <Table striped bordered hover className="mt-2">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            {props.additionalData &&
              props.additionalData.map((item, index) => {
                return (
                  <th key={index}>{_.startCase(item.split(".").pop())}</th>
                );
              })}
            <th>Options</th>
            {props.orderable && <th>Sort</th>}
          </tr>
        </thead>
        <tbody>
          <ItemList
            items={data}
            idName={idName}
            handleClickProp={handleClick}
            itemOrderUpdated={updateListOrder}
            showDeleteButton
            orderable={props.orderable}
            relatedData={props.relatedData}
            additionalData={props.additionalData}
          />
        </tbody>
      </Table>
      <hr />
    </>
  );
}

export default GenericSortedItemList;
