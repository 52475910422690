import React, { useContext, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Nav } from "react-bootstrap";
import UserContext from "../../contexts/userContext";

const TabbedNav = (props) => {
  const userContext = useContext(UserContext);
  const params = useParams();
  const location = useLocation();
  let history = useHistory();

  const { navData } = props;
  // console.log("navData", navData);
  // console.log("params", params, " location", location);
  var navItems = navData.items.map(function (item, i) {
    console.log("userContext.profile.role", userContext.profile.role);

    if (
      userContext.profile.role === "cg.admin" ||
      userContext.profile.role === item.requiredRoleClaim
    ) {
      let activeState = false;
      if (navData.active != null)
        // if defined in props
        activeState =
          item.name.toLowerCase() === navData.active.toLowerCase()
            ? true
            : false;
      else if (props.path != null);

      let href =
        item.href != null
          ? item.href
          : "/" + item.name.replace(" ", "").trim().toLowerCase();

      // using the current path
      activeState = location.pathname === href ? true : false;
      // console.log(
      //   "props.path.includes(href)",
      //   location.pathname,
      //   href,
      //   location.pathname.includes(href)
      // );

      return (
        <Nav.Item key={i}>
          <Nav.Link onClick={() => history.push(href)} active={activeState}>
            {item.name}
          </Nav.Link>
        </Nav.Item>
      );
    }
    // console.log("SKIPPING ", item);
    return false;
  });

  return (
    <Nav variant="tabs" className="mb-2">
      {navItems}
    </Nav>
  );
};

export default TabbedNav;
