import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { set } from "lodash";
import AutoPopulateSelectFieldTemplate from "./AutoPopulateSelectFieldTemplate";
import Processor from "./../../Processor";
import { getPathToJsonObj } from "../../../Utils";

var jp = require("jsonpath");

function AddNewProcesorSelectFieldWithModal(props) {
  console.log("AddNewProcesorSelectFieldWithModal props", props);
  // processor modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    console.log("CLOSE PROCESSOR MODAL");
    setShow(false);
  };
  const handleShow = () => {
    console.log("SHOW PROCESSOR MODAL");
    setShow(true);
  };
  const handleFormDataUpdateFromProcessor = (newProcessorData) => {
    // get the newly added processor data and assign to currentProcessor in formData

    console.log(
      "AddNewProcesorSelectFieldWithModal handleFormDataUpdateFromProcessor",
      newProcessorData,
      "props",
      props
    );
    let updateData = { ...props.formContext.formData };

    // console.log("updateData", updateData);
    // console.log(
    //   "path to currentProcessor",
    //   jp.paths(updateData, "currentProcessor")
    // );

    // let currentProcessorPath = getPathToJsonObj(updateData, "currentProcessor");
    // console.log("currentProcessorPath", currentProcessorPath);
    // console.log("updateData", updateData);

    const processorName =
      newProcessorData.processorType?.name + " (" + newProcessorData.mac + ")";
    console.log("processorName", processorName);
    //set(updateData, currentProcessorPath, processorName);
    jp.value(updateData, "$.currentProcessor", processorName);
    props.formContext.setFormData(updateData);

    // clsoe modal
    handleClose();

    // refresh page
    window.location.reload(false);
  };
  return (
    <>
      {/* <AutoPopulateSelectFieldTemplate {...props} /> */}
      {/* <Button variant="link" onClick={handleShow} className="mt-2">
        <span className="fa fa-plus mr-2"></span>Add New Processor 1
      </Button> */}
      <Processor
        controlSystem={props.formContext.controlSystem}
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        handleFormDataUpdate={handleFormDataUpdateFromProcessor}
      />
    </>
  );
}

export default AddNewProcesorSelectFieldWithModal;
