import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import UserContext from "./../../../contexts/userContext";
import AdminNavData from "./adminNavData.json";

const AdminNav = (props) => {
  const userContext = useContext(UserContext);
  let history = useHistory();
  let temp = [];
  let navItems = [];

  //useEffect(() => {
  // temp = AdminNavData.items.map(function (item, i) {
  //   console.log("adminNav", item, typeof item, item.dropdown);
  // });

  navItems = AdminNavData.items.map(function (item, i) {
    //console.log("adminNav", item, typeof item, Array.isArray(item.dropdown));
    // if(Array.isArray(userContext.profile.role) && userContext.profile.role.includes("")
    console.log("userContext profile", userContext.profile);
    console.log("item scope", item.requiredRoleClaim);
    if (
      userContext.profile.role === "cg.admin" ||
      userContext.profile.role === item.requiredRoleClaim
    ) {
      let activeState = false;
      if (AdminNavData.active != null)
        // if defined in props
        activeState =
          item.name.toLowerCase() === AdminNavData.active.toLowerCase()
            ? true
            : false;
      else if (props.path != null);

      if (item.dropdown === undefined && item.dropdown === undefined) {
        let href =
          item.href != null
            ? item.href
            : "/" + item.name.replace(" ", "").trim().toLowerCase();

        // using the current path
        activeState = props.path.includes(href) ? true : false;
        return (
          <Nav.Item key={i}>
            <Nav.Link href={href} active={activeState}>
              {item.name}
            </Nav.Link>
          </Nav.Item>
        );
      } else if (Array.isArray(item.dropdown.items)) {
        //console.log("item is array", item.dropdown.items);
        return (
          <NavDropdown title={item.dropdown.title} id="nav-dropdown" key={i}>
            {item.dropdown.items.map((dd, d) => {
              let href =
                dd.href != null
                  ? dd.href
                  : "/" + dd.name.replace(" ", "").trim().toLowerCase();
              return (
                <NavDropdown.Item key={d} href={href}>
                  {dd.name}
                </NavDropdown.Item>
              );
            })}
          </NavDropdown>
        );
      }
    }
    // console.log("SKIPPING ", item);
    return false;
  });
  //}, []);

  return <Nav variant="tabs">{navItems}</Nav>;
};

export default AdminNav;
