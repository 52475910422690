import React from "react";

import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  Navbar,
  Jumbotron,
} from "react-bootstrap";

import logo from "../configured.svg";
import TipSupport from "./common/tipSupport";
import Footer from "./common/Footer";

const queryString = require("query-string");

export default function Forbidden(props) {
  const parsed = queryString.parse(props.location.search);
  // console.log(parsed);

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col className="mb-2">
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
              <Navbar.Brand href="/">
                <img
                  src={logo}
                  className="App-logo"
                  alt="ConfiguredWeb"
                  title="ConfiguredWeb"
                />
              </Navbar.Brand>
              <Nav className="mr-auto">
                <Nav.Link
                  href="http://www.controlgadgets.com.au"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  by Control Gadgets
                </Nav.Link>
              </Nav>
            </Navbar>
          </Col>
        </Row>

        <Row>
          <Col sm="6">
            <Jumbotron>
              <div className="display-4">
                <span className="fa fa-ban mr-4"></span>FORBIDDEN
              </div>
              <div>
                You are not authorized to perform this operation ({parsed.claim}
                )
              </div>
              <Button size="sm" href="/">
                Home
              </Button>
            </Jumbotron>
          </Col>
          <Col sm="2">
            <TipSupport />
          </Col>
        </Row>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
