import React from "react";
import ASide from "./aside";

const TipSupport = () => {
  return (
    <ASide
      heading="Tip"
      content={
        <React.Fragment>
          For support, contact
          <br />
          <a
            href={process.env.REACT_APP_SUPPORT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-external-link-square mr-1"></span>Control
            Gadgets
          </a>
        </React.Fragment>
      }
    />
  );
};

export default TipSupport;
