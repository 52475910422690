import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthService } from "../../services/AuthService";
import UserContext from "../../contexts/userContext";
import _ from "lodash";

class ProtectedRoute extends Component {
  constructor() {
    super();
    this.authService = new AuthService();
  }

  authorizeUser = (profile, requiredRoleClaim) => {
    console.log(
      "protectedRoute (",
      this.props.path,
      ") authorize user profile: ",
      profile,
      "requiredRoleClaim",
      requiredRoleClaim
    );

    // no claims required
    if (requiredRoleClaim === undefined) return true;

    // user has no claims
    if (profile === undefined || _.isEmpty(profile)) {
      console.log("USER HAS NO PROFILE");
      return false;
    }

    // cg (god)
    if (profile.role.includes("cg.admin")) return true;

    // authorize other claims
    if (Array.isArray(profile.role)) {
      return profile.role.includes(requiredRoleClaim);
    } else {
      return profile.role === requiredRoleClaim ? true : false;
    }
  };

  render() {
    const {
      path,
      component: Component,
      render,
      requiredRoleClaim,
      ...rest
    } = this.props;

    //console.log("Protected Route props", this.props);

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <Route
            {...rest}
            path={path}
            render={(props) => {
              //console.log("userFromContext ", userContext);
              // console.log("protected route props", props);
              if (!userContext) {
                this.authService.login();
              }

              if (!userContext.profile) {
                return <div>loading...</div>;
              }

              if (!this.authorizeUser(userContext.profile, requiredRoleClaim)) {
                return (
                  <Redirect to={`/forbidden?claim=${requiredRoleClaim}`} />
                );
              }

              // authenticated and authorized
              return Component ? (
                <Component routePath={path} {...rest} {...props} />
              ) : (
                render(props)
              );
            }}
          />
        )}
      </UserContext.Consumer>
    );
  }
}

export default ProtectedRoute;
