import React from "react";

const uiSchema = {
  videoProcessorModeId: {
    "ui:widget": "hidden",
  },
  configurationId: {
    "ui:widget": "hidden",
  },
  videoProcessorModeWindows: {
    "ui:options": {
      orderable: false,
    },
    items: {
      videoProcessorModeWindowId: {
        "ui:widget": "hidden",
      },
    },
  },
};

export default uiSchema;
