import React, { useEffect, useState } from "react";
import { Accordion, Button, Container, Card, Row, Col } from "react-bootstrap";
import JsonEditor from "../../admin/JsonEditor";

import http from "../../../services/APIService";
import AnalyticsJobStatus from "./AnalyticsJobStatus";
function Analytics(props) {
  const [consoleReply, setConsoleReply] = useState(undefined);
  const [jobs, setJobs] = useState(undefined);

  const handleFetch = async (jobName, command) => {
    console.log("handleFetch command", command);
    console.log("Analytics props", props);
    let job = {
      name: jobName,
      consoleCommand: command,
    };
    const result = await http.callApi(
      "post",
      `jobs/${props.controlSystem.controlSystemId}/console`,
      job
      //`"${command}"` // string needs to be in double quotes to get to the backend via application/json
    );
    if (result) {
      console.log(
        "analytics post console job results",
        result !== undefined ? result.data : "NULL"
      );

      //setConfigurations(result.data);
    }
  };

  useEffect(() => {
    console.log("ANALYTICS PROPS CS", props.controlSystem);
    if (props.controlSystem) {
    }
  }, [props.controlSystem]);

  return (
    <>
      <h5>
        {/* <i className="fa fa-chart-pie mr-2"></i> */}
        <i className="fas fa-stream mr-2"></i>
        Live Info
      </h5>
      <Button
        size="sm"
        onClick={() => handleFetch("Fetch Firmware Version", "ver -v")}
      >
        Firmware Version
      </Button>{" "}
      <Button
        size="sm"
        onClick={() => handleFetch("Configured SP Version", "progcomments")}
      >
        Configured Version
      </Button>
      <hr />
      <AnalyticsJobStatus controlSystem={props.controlSystem} />
    </>
  );
}

export default Analytics;
