import React from "react";

const VideoProcessorConfigUi = {
  configurationId: {
    "ui:widget": "hidden",
  },

  inputVideoZones: {
    "ui:options": {
      orderable: false,
    },
    itemName: "Input",
  },
};

export default VideoProcessorConfigUi;
