import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TreeMenu from "react-simple-tree-menu";
import http from "../../services/APIService";
import _ from "lodash";
function ConfigurationMenu(props) {
  let history = useHistory();
  let params = useParams();
  const [groups, setGroups] = useState(undefined);
  const [menuData, setMenuData] = useState(undefined);
  const { controlSystem, configurationId } = props;

  const [activeKey, setActiveKey] = useState(undefined);
  const [openNodes, setOpenNodes] = useState(undefined);
  const [configSection, setConfigSection] = useState(undefined);

  useEffect(() => {
    if (!params.sectionTypeId) {
      setActiveKey(undefined);
      setOpenNodes(undefined);
    } else {
      console.log("params changed", params);
      // get current configSection (and its group)
      getConfigSection();
    }
  }, [params]);

  useEffect(() => {
    if (!configSection) return;

    setActiveKey(
      `${configSection.configSectionGroup?.name}/${configSection.name}`
    );
    setOpenNodes(new Array(configSection.configSectionGroup?.name));

    console.log(
      "group name",
      new Array(configSection.configSectionGroup?.name)
    );

    // setActiveKey("General/System Options");
    // setOpenNodes(["General"]);
  }, [configSection]);

  const getConfigSection = async () => {
    const result = await http.callApi(
      "get",
      `configSectionType/${params.sectionTypeId}`
    );

    if (result) {
      console.log("config groups", result.data);
      setConfigSection(result.data);
    }
  };

  const getGroups = async () => {
    const result = await http.callApi(
      "get",
      `configSectionGroup?controlSystemId=${controlSystem.controlSystemId}`
    );

    if (result) {
      console.log("config groups", result.data);
      setGroups(result.data);
    }
  };

  useEffect(() => {
    if (controlSystem) getGroups();
  }, [controlSystem]);

  useEffect(() => {
    let treeData = {};
    if (groups) {
      console.log("groups", groups);
      groups.map((group, i) => {
        let configSectionNodes = {};
        console.log("group", group);

        let types = group.configSectionTypes.filter((t) => t.endpoint);

        if (_.isEmpty(types) || _.isEmpty(group.configSectionTypes)) return;

        // if (group.configSectionTypes.length > 1) {
        group.configSectionTypes.map((configSectionType, i) => {
          console.log("configSectionType", configSectionType);

          if (!configSectionType.endpoint) return;

          configSectionNodes[configSectionType.name] = {
            label: configSectionType.name,
            index: i,
            url: `/section/${configSectionType.configSectionTypeId}/${configSectionType.name}`,
            nodes: {},
          };

          console.log(
            configSectionType.name,
            "configSectionNodes",
            configSectionNodes
          );
        });

        console.log("controlSystem>:>:", controlSystem);
        let firstConfigSection = group.configSectionTypes[0];
        treeData[group.name] = {
          label: group.name,
          index: i,
          url: `/section/${firstConfigSection.configSectionTypeId}/${firstConfigSection.name}`,
          nodes: configSectionNodes,
        };
        // } else if (group.configSectionTypes.length === 1) {
        //   let configSectionType = group.configSectionTypes[0];
        //   treeData[configSectionType.name] = {
        //     label: configSectionType.name,
        //     index: i,
        //     url: `/section/${configSectionType.configSectionTypeId}/${configSectionType.name}`,
        //     nodes: {},
        //   };
        // }
      });

      console.log("treeData", treeData);

      setMenuData(treeData);
    }
  }, [groups]);

  const handleMenuClick = (e) => {
    console.log("clicked", e);
    if (!e.url) return;
    const currentPath = `/controlSystem/${controlSystem.controlSystemId}/configuration/${configurationId}`;
    // \s is the regex for "whitespace", and g is the "global" flag, meaning match ALL \s (whitespaces).
    //let itemPath = e.key.replace(/\s+/g, "");
    let itemPath = e.url ? e.url : "/" + e.key;
    itemPath = itemPath.replace(/\s+/g, ""); // replace whiteSPace
    console.log("currentPath", currentPath, itemPath);
    history.push(`${currentPath}${itemPath}`);
    //window.location.href = `${currentPath}${itemPath}`;
  };

  return (
    <>
      <TreeMenu
        data={menuData}
        onClickItem={(e) => handleMenuClick(e)}
        activeKey={activeKey}
        focusKey={activeKey}
        openNodes={openNodes}
      />
    </>
  );
}

export default ConfigurationMenu;
