import React, { Component } from "react";
import http from "../services/APIService";
import { AuthService } from "../services/AuthService";

import "bootstrap/dist/css/bootstrap.css";

import UserContext from "./../contexts/userContext";
import { toast } from "react-toastify";
import { Jumbotron, Form, Button } from "react-bootstrap";

class Home extends Component {
  static contextType = UserContext;

  static authService = null;
  static token = "";
  state = { api: [], userInfoEndpoint: "" };

  constructor() {
    super();
    this.authService = new AuthService();
    this.authService.userManager.metadataService
      .getUserInfoEndpoint()
      .then((result) => {
        this.setState({ userInfoEndpoint: result });
        //console.log("user info endpoint", this.state.userInfoEndpoint);
      });
  }

  componentDidMount() {
    //console.log("context", this.context);
  }

  callApi = async () => {
    toast.info("calling Api...");
    // const headers = {
    //   Accept: "application/json",
    //   Authorization: "Bearer " + this.token
    // };

    //http.setJWT(this.state.user.access_token);

    //console.log("access token", this.context.access_token);

    // http.get(
    //   `${process.env.REACT_APP_AUTHORITY_API_BASEADDRESS}organisation/1`
    // );

    // console.log("calling api ", this.context.access_token);
    // console.log("userContext.profile>>> ", this.context.profile);
    // console.log("userContext>>> ", this.context);
    try {
      //http.setBearer(this.context.access_token);
      // const { data } = await http.get(
      //   `${process.env.REACT_APP_AUTHORITY_API_BASEADDRESS}organisation`
      // );

      //"http://localhost:5000/connect/userinfo"
      //const { data } = await http.get("http://localhost:5000/connect/userinfo");
      const { data } = await http.callRemoteApi(
        "get",
        this.state.userInfoEndpoint
      );
      // let apiData;
      // var key;
      // for (key in data) {
      //   if (data.hasOwnProperty(key)) {
      //     console.log(key + " = " + data[key]);
      //     apiData = apiData + <li>${key + " = " + data[key]}</li>;
      //   }
      // }

      var result = Object.keys(data).map(function (key) {
        // Using Number() to convert key to number type
        // Using obj[key] to retrieve key value
        return [key, data[key]];
      });

      this.setState({ api: result });
      // console.log("api data ", data);
    } catch (ex) {
      console.log(ex);
    }
  };

  render() {
    return (
      <UserContext.Consumer>
        {/* consumer component expects a function as its child */}
        {(userContext) => (
          <React.Fragment>
            {userContext ? (
              <Jumbotron>
                <h4>user id token</h4>{" "}
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={userContext.id_token}
                />
                <h4>user access token</h4>{" "}
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={userContext.access_token}
                />
                <h4>token type</h4> {userContext.token_type}
                <h4>scope</h4> {userContext.scope}
                <h4>Profile</h4>
                {userContext.profile && (
                  <ul>
                    <li>amr {userContext.profile.amr[0]}</li>
                    <li>name {userContext.profile.name}</li>
                    <li>email {userContext.profile.email}</li>
                    <li>idp {userContext.profile.idp}</li>
                    <li>
                      email_verified{" "}
                      {userContext.profile.email_verified === true
                        ? "yes"
                        : "no"}
                    </li>
                    <li>
                      sub <b>{userContext.profile.sub}</b>
                    </li>
                    <li>
                      role{" "}
                      {Array.isArray(userContext.profile.role)
                        ? userContext.profile.role.map((role) => {
                            return <span className="fa fa-user">{role}</span>;
                          })
                        : userContext.profile.role}
                    </li>
                    <li>org {userContext.profile.organisation}</li>
                    <li>groups {userContext.profile.groups}</li>
                  </ul>
                )}
              </Jumbotron>
            ) : (
              "not logged in"
            )}

            <div>
              <hr />
              <Jumbotron>
                <h5>user info endpoint</h5>
                <div>
                  {this.state.userInfoEndpoint
                    ? this.state.userInfoEndpoint
                    : "loading"}
                </div>
                {userContext && (
                  <Button
                    onClick={this.callApi}
                    className="btn btn-sm btn-primary ml-2"
                  >
                    call userinfo api enpoint
                  </Button>
                )}
                <ul>
                  {this.state.api.map((item) => {
                    return (
                      <li>
                        <b>{item[0]}</b> {item[1]}
                      </li>
                    );
                  })}
                </ul>
              </Jumbotron>
            </div>

            {/* {console.log("userFromContext>>> ", userContext.access_token)} */}
          </React.Fragment>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Home;
