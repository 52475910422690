import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  ListGroup,
  ListItem,
  Popover,
  OverlayTrigger,
  Card,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import TreeMenu from "react-simple-tree-menu";

import NavTop from "./navtop";
import TipSupport from "./tipSupport.jsx";

import http from "../../services/APIService";

import "./Layout.css";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "./../../contexts/userContext";

import SideBar from "./SideBar";
import Footer from "./Footer";
import LoadingSpinner from "./LoadingSpinner";

import ClientLogo from "./ClientLogo";

function PageLayout(props) {
  const userContext = useContext(UserContext);
  let history = useHistory();
  const [locations, setLocations] = useState(undefined);
  const [menuData, setMenuData] = useState(undefined);
  const [recentControlSystems, setRecentControlSystems] = useState(undefined);
  const [loading, setLoading] = useState("busy");
  let items = [];
  const onClick = (e, item) => {
    if (item.route) {
      history.push(item.route);
    }
  };

  const getRecentControlSystems = async () => {
    const result = await http.callApi("get", `controlSystem/recent`);

    if (result) {
      console.log("recent controlSystems for user", result.data);
      setRecentControlSystems(result.data);
    }
  };

  useEffect(() => {
    getRecentControlSystems();
  }, []);

  const getLocations = async () => {
    const result = await http.callApi("get", `location`);

    if (result) {
      console.log("locations", result.data);
      setLocations(result.data);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    let treeData = {};
    if (locations) {
      console.log("locations", locations);
      locations.map((location, i) => {
        let buildingNodes = {};
        console.log("location", location);

        location.buildings.map((building, i) => {
          let controlSystemNodes = {};
          console.log("building", building);

          building.controlSystems.map((controlSystem, i) => {
            console.log("controlSystem", controlSystem);
            controlSystemNodes[controlSystem.name] = {
              label: controlSystem.name,
              index: i,
              url: `/controlSystem/${controlSystem.controlSystemId}`,
              nodes: {},
            };
          });
          console.log(building.name, "controlSystemNodes", controlSystemNodes);

          buildingNodes[building.name] = {
            label: building.name,
            index: i,
            url: `/location/${location.locationId}/building/${building.buildingId}`,
            nodes: controlSystemNodes,
          };

          console.log(location.name, "buildingNodes", buildingNodes);
        });

        treeData[location.name] = {
          label: location.name,
          index: i,
          url: `/location/${location.locationId}`,
          nodes: buildingNodes,
        };
      });

      console.log("treeData", treeData);

      setMenuData(treeData);
    }
  }, [locations]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Recent</Popover.Title>
      <Popover.Content>
        {recentControlSystems?.map((controlSystem, i) => (
          <Button
            key={controlSystem.controlSystemId}
            variant="link"
            onClick={() =>
              history.push(`/controlSystem/${controlSystem.controlSystemId}`)
            }
          >
            {controlSystem.name}
          </Button>
        ))}
      </Popover.Content>
    </Popover>
  );

  const handleMenuClick = (e) => {
    console.log("clicked", e);
    if (e.url) history.push(e.url);
  };

  return (
    <React.Fragment>
      <Container fluid={true} className="">
        <Row className="bg-light px-0">
          <Col>
            {/* TOP NAV BAR */}
            <NavTop />
          </Col>
        </Row>
      </Container>
      <Container fluid className="content mx-2 mb-1">
        <Row xs="3" className="py-1">
          {userContext && userContext.profile ? (
            <Col sm="2" className="leftnav">
              <ClientLogo
                org={
                  userContext &&
                  userContext.profile &&
                  userContext.profile.organisation
                }
              />
              <Button
                variant="light"
                className="mb-2"
                block
                onClick={() => history.push("/")}
              >
                <span className="fas fa-tachometer-alt mr-2"></span>
                Dashboard
              </Button>
              <Button
                variant="light"
                className="mb-2"
                block
                onClick={() => history.push("/controlSystem/new")}
              >
                <span className="fa fa-plus mr-2"></span>
                New Control System
              </Button>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={popover}
                rootClose
              >
                <Button variant="light" block>
                  <span className="fa fa-clock mr-2"></span>Recent Control
                  Systems
                  <span className="fas fa-chevron-right ml-2"></span>
                </Button>
              </OverlayTrigger>
              <hr />
              <Card variant="secondary">
                <Card.Header>Control Systems</Card.Header>
                <Card.Body>
                  <TreeMenu
                    data={menuData}
                    onClickItem={(e) => handleMenuClick(e)}
                  />
                </Card.Body>
              </Card>
              {/* {!_.isEmpty(menuItems) && loading === "ready" ? (
                <>
                  <TreeMenu data={treeData} />
                </>
              ) : (
                <LoadingSpinner />
              )} */}
              <hr />
            </Col>
          ) : (
            ""
          )}
          <Col sm="8" className="my-2">
            {props.children}
          </Col>
          <Col sm="2">
            {/* HELP TIP */}
            <TipSupport />
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default PageLayout;
