import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer(props) {
  return (
    <React.Fragment>
      {/* FOOTER */}
      <Container fluid className="ml-1">
        <Row className="navbar-light bg-light border-top py-2">
          <Col>
            <div className="float-right">
              {/* <a href="#">Back to top</a> */}
            </div>
            <div>
              {/* <a href={process.env.REACT_APP_WEBSITE}> */}
              Configured
              {/* </a>  */} v{process.env.REACT_APP_VERSION}
              <span className="fa fa-copyright mx-2"></span>
              {process.env.REACT_APP_COPYRIGHT}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Footer;
