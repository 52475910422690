import React from "react";
import { ListGroup } from "react-bootstrap";

const ASide = (props) => {
  return (
    <ListGroup className="pt-4">
      <ListGroup.Item variant="secondary">{props.heading}</ListGroup.Item>
      <ListGroup.Item>{props.content}</ListGroup.Item>
    </ListGroup>
  );
};

export default ASide;
