/* © Copyright Control Gadgets 2020
 *
 * Notice:
 * This work is copyright. Apart from any use permitted under the Copyright Act 1968, no part may be
 * reproduced by any process, nor may any other exclusive right be exercised, without the permission of
 * Control Gadgets, 5/511 Olsen Ave Southport Qld 4215, Australia.
 *
 */

// ------------------------
// imports
// ------------------------
import _ from "lodash";
var jp = require("jsonpath");
var flatten = require("flat");
var unflatten = require("flat").unflatten;

export function toPaddedHexString(num, len) {
  let str = num.toString(16).toUpperCase();
  return "0".repeat(len - str.length) + str;
}

export function getObjectAtPath(json, name) {
  const path = getPathToJsonObj(json, name);
  return _.get(json, path);
}

export function getPathToJsonObj(
  json,
  name,
  removeProperties,
  removeDependencies
) {
  try {
    // get full path to property that changed using jsonpath package
    let pathToName = jp.paths(json, "$.." + name, 1)[0];
    //console.log("getPathToJsonObj()", name, pathToName);
    // console.log("pathToName+++++++++++++++++++", pathToName);
    // if the path (key) was build using jsonpath it will contain $ and "properties" so remove them
    // use lodash _.pullAll()

    // console.log(
    //   "indexOf dependencies",
    //   pathToName.indexOf("dependencies"),
    //   pathToName[0],
    //   pathToName[1]
    // );

    // console.log("indexOfName in pathToName", pathToName.indexOf(name));

    // removing array elements starting at "dependencies" and finishing 1 before at name
    if (removeDependencies && pathToName.indexOf("dependencies") >= 0) {
      pathToName.splice(
        pathToName.indexOf("dependencies"),
        pathToName.indexOf(name) - 1
      );

      //console.log("remove dependencies up to name element", pathToName);
    }
    if (removeProperties) _.pullAll(pathToName, ["properties"]);

    let pathStr = jp.stringify(pathToName); // jsonpath stringify adds the $ back on to represent the root element
    let path = pathStr.replace("$.", "");
    //console.log("path +++++++++++++++++", path);
    return path;
  } catch (ex) {
    return null;
  }
}

export function getParentPathOfJsonObj(json, name) {
  try {
    // get full path to property that changed using jsonpath package
    let pathToName = jp.paths(json, "$.." + name, 1);
    // use lodash _.pullAll()
    _.pullAll(pathToName[0], [name]);
    let pathStr = jp.stringify(pathToName[0]); // jsonpath stringify adds the $ back on to represent the root element
    let path = pathStr.replace("$.", "");
    //console.log("parent path +++++++++++++++++", path);
    return path;
  } catch (ex) {
    return null;
  }
}

export function findAllInJsonFiltered(obj, prop, arr, filterProp) {
  let result = "";
  //console.log("findAllFIltered()", obj);
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      if (typeof obj[p] === "object") {
        if (obj[p] !== null && obj[p].hasOwnProperty(prop)) {
          console.log(
            "obj[p]",
            obj[p],
            "prop",
            prop,
            "haswonproperty",
            obj[p].hasOwnProperty(prop)
          );
          //console.log("obj[p]", obj[p][filterProp]);
          //console.log("fuckk", obj[p][prop]);
          if (_.isEqual(obj[p]["cgdone"], undefined)) {
            //arr.push(p);
            arr.push(obj[p][prop]);

            //arr.push(obj[p]);
          }
        } else {
          result += findAllInJsonFiltered(obj[p], prop, arr);
        }
      }
    }
  }
}

export function deleteAllInJson(obj, propToDelete) {
  let result = "";
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      if (typeof obj[p] === "object") {
        if (obj[p] !== null && obj[p].hasOwnProperty(propToDelete)) {
          if (!_.isEqual(obj[p][propToDelete], undefined)) {
            delete obj[p][propToDelete];
          }
        } else {
          result += deleteAllInJson(obj[p], propToDelete);
        }
      }
    }
  }
}

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    //console.log("IsJsonString() exception ", e);
    return false;
  }
  return true;
}

export function renameJsonKeys(data) {
  let newData = {};
  Object.keys(data).map((key) => {
    let newKey = _.last(key.split("."));

    newData[newKey] = data[key];
    delete data[key];
  });

  // Object.keys(newData).map((key) => {
  //   console.log("key", key, "value", newData[key]);
  // });

  return newData;
}

// this is for the left nav
export function mapServerDataToSchema(data, schema) {
  // console.log("mapServerDataToSchema() data", data);
  // console.log("mapServerDataToSchema() schema", schema);
  let newData = {};
  Object.keys(data).map((key) => {
    //console.log("mapDataToSChema ", key, data[key]);
    // console.log(
    //   "getPathToJsonObj ",
    //   key,
    //   getPathToJsonObj(schema, key, true)
    // );

    let path = getPathToJsonObj(schema, key, true);
    // if (key === "buildingId") {
    //   console.log("PATH shit ", path);
    // }
    if (path) {
      // console.log("index of dep", path.indexOf(".dependencies"));
      let idex = path.indexOf(".dependencies");
      if (idex >= 0) {
        // console.log("substr", path.substring(0, idex) + "." + key);
        let actualPath = path.substring(0, idex) + "." + key;
        newData[actualPath] = data[key];
      } else {
        newData[path] = data[key];
      }
    }
    // maybe the key is part of oneOf/dependencies
    // find it
    else {
      // console.log("find buildingId in schema", schema);
      // const arroutpu = [];
      // findAllInJsonFiltered(schema, "cgrelatedcollectionid", arroutpu);
      // console.log("arroutpu", arroutpu);

      const pathToCollectionIdArray = jp.paths(
        schema,
        "$..cgrelatedcollectionid"
      );
      // console.log("pathToCollectionIdArray ", pathToCollectionIdArray);
      let pathToCollectionId;
      for (let path of pathToCollectionIdArray) {
        // console.log("PATH IN LOOP", path, jp.stringify(path));
        // console.log("value ", jp.query(schema, jp.stringify(path))[0]);
        // console.log("key", key);

        // if (jp.query(schema, jp.stringify(path))[0] === key) {
        //   alert("FOUND IT FUCK YA " + key);
        // }

        path.pop(); // remove cgrelatedcollectionid
        path.pop(); // remove the parent
        path.pop(); // remove properties
        // this is where the key exists
        // console.log("PATH +TO IT", path);
        path = path.slice(path.indexOf("$") + 1);
        path = path.slice(path.indexOf("properties") + 1);
        // console.log("PATH +TO IT2", path[0]);

        // console.log("What the fuck am i doing", data[key]);
        // console.log("NewData", newData);
        const deppath = path[0] + "." + key;
        // console.log("deppath", deppath);
        _.set(newData, deppath, data[key]);
        //  console.log("NewData2", newData);
      }
      // const pathToCollectionIdValue = jp.query(
      //   schema,
      //   jp.stringify(pathToCollectionIdArray[0])
      // );

      //console.log("pathToCollectionIdValue", pathToCollectionIdValue);
    }
  });
  //console.log(">>>>>>>> ", newData);
  return newData;
}

export function flattenAndRenameKeys(data) {
  return renameJsonKeys(flatten(data));
}

export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  // _.slice(items, startIndex)
  // _.take(items, )
  return _(items).slice(startIndex).take(pageSize).value(); // _(items) converts items array to a lodash object so we can chain lodash functions
}
