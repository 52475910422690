import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import DynamicForm from "./DynamicForm";
import http from "../../services/APIService";
import { toast } from "react-toastify";
import _ from "lodash";
import CustomFieldTemplate from "./CustomFieldsAndTemplates/CustomFieldTemplate";
import CustomForm from "./CustomForm";

var jp = require("jsonpath");

function ModalForm(props) {
  const [formData, setFormData] = useState(undefined);
  const [readyToShow, setReadyToShow] = useState(false);
  //   const [schema, setSchema] = useState({});
  //   const [uiSchema, setUiSchema] = useState({});
  const [extraErrors, setExtraErrors] = useState(undefined);

  const [titleIconClass, setTitleIconClass] = useState("");
  const [titleText, setTitleText] = useState("");

  let { id: controlSystemId } = useParams();

  useEffect(() => {
    if (props.show) {
      setReadyToShow(false);
      setExtraErrors(undefined); // clear
      console.log("MODAL FORM PROPS", props);
      if (!_.isEmpty(props.formData)) {
        // if formData is array, get the correct item using the item's primary key id (not the index of the array)
        let data = props.formData;

        if (Array.isArray(props.formData)) {
          let index = _.findIndex(props.formData, function (o) {
            return o[props.idName] == props.id;
          });
          console.log("index", index);

          data = { ...props.formData[index] };
        }
        _.set(data, "controlSystem", props.controlSystem);
        _.set(data, "controlSystemId", props.controlSystem.controlSystemId);

        setFormData(data);
        setTitleIconClass(props.formTitleIcon);
        setTitleText(props.formTitle);

        console.log("MODAL FORM using props.formData", props.id, data);
      } else {
        console.log("LOAD MODAL id", props.id);
        if (props.id === "new") {
          setTitleIconClass("fa fa-plus");
          setTitleText("Add New");
          console.log("add a new", props.name, controlSystemId);
          let formDataTmp = { controlSystemId };
          _.set(formDataTmp, "controlSystem", props.controlSystem);
          setFormData(formDataTmp);
        } else {
          setTitleIconClass(props.formTitleIcon);
          setTitleText(props.formTitle);

          async function getData() {
            console.log(
              "modal form GET FORM DATA",
              `${props.name}/${controlSystemId}/${props.id ?? ""}`
            );
            // get item using controllerName and idName
            const result = await http.callApi(
              "get",
              `${props.name}/${controlSystemId}/${props.id ?? ""}`
            );
            if (result && result.data) {
              console.log(
                "get [",
                props.name,
                "] id ",
                props.id,
                " result",
                result.data
              );

              _.set(result.data, "controlSystem", props.controlSystem);
              setFormData(result.data);
              console.log("MODAL FORM from endpoint", props.id, result.data);
            }
          }
          getData();
        }
      }

      // setTimeout(() => {
      //   setReadyToShow(true);
      // }, 500);
    }
  }, [props.show]);

  const handleSubmit = async (e) => {
    console.log(">> ModalForm handleSubmit()", e, extraErrors);
    setFormData(e.formData);

    if (props.id === "new") {
      let formData = { ...e.formData };

      console.log(
        "handleSubmit() ModalForm ADD NEW ",
        `${props.name}/${controlSystemId}`,
        formData
      );
      try {
        const result = await http.callApi(
          "post",
          `${props.name}/${controlSystemId}`,
          e.formData
        );
        if (result) {
          toast.success(props.name + " added successfully");
          console.log("result data ::: ", result);
          props.handleFormDataUpdate(props.name, result);
          props.close();
        }
      } catch (error) {
        //toast.error("Form Data has validation errors");
        console.log("!!!serverErrors", error);
        let errs = http.handleHttpErrors(error);
        console.log("extraErrors", errs);
        setExtraErrors(errs);
      }
    } else {
      console.log(
        "handleSubmit() ModalForm UPDATE id ",
        props.id,
        `${props.name}/${controlSystemId}`,
        e.formData
      );

      const itemIdIfExists = props.id ? "/" + props.id : "";
      try {
        const result = await http.callApi(
          "put",
          `${props.name}/${controlSystemId}${itemIdIfExists}`,
          e.formData
        );
        if (result) {
          toast.success(props.name + " updated successfully");
          console.log("result data ::: ", result);
          props.handleFormDataUpdate(props.name, result);
          props.close();
        }
      } catch (error) {
        //toast.error("Form Data has validation errors");
        console.log("!!!serverErrors", error);
        let errs = http.handleHttpErrors(error);
        console.log("extraErrors", errs);
        setExtraErrors(errs);
      }
    }
  };

  const handleFieldChange = async (name, fieldFormData, idSchema) => {
    console.log(
      "modelForm handleFieldCHange name",
      name,
      "fieldFormData",
      fieldFormData,
      "idSchema",
      idSchema
    );

    console.log("formData", formData);
    return;

    // eg root_jsonCommsData_ipId
    //idSchema["$id"]

    // convert idSchema to path that aspnet core can use
    // ie jsonCommsData.ipId
    let pathArr = idSchema["$id"].split("_");
    pathArr.shift(); // rmeove first element (root)
    let pathToField = pathArr.join(".");
    console.log("pathArr", pathArr);
    console.log("pathToField", pathToField);

    jp.value(formData, `$..${pathToField}`, fieldFormData);
    console.log("formData2", formData);

    try {
      if (name === "ipId") {
        var result = await http.callApi(
          "post",
          `validation/${props.controlSystem.controlSystemId}/ipId/${fieldFormData}?idSchema=${idSchema["$id"]}`,
          fieldFormData
        );

        console.log("validation result", result);

        if (result.status === 204) {
          let errors = { ...extraErrors };
          jp.value(errors, `$..${pathToField}.__errors`, [
            "there arent any errors",
          ]);
          setExtraErrors(errors);
        }
      }
    } catch (error) {
      console.log("!!!serverErrors", error);
      let errs = http.handleHttpErrors(error, {
        ...extraErrors,
      });
      console.log("extraErrors", errs);
      setExtraErrors(errs);
    }
  };

  const { show, close } = props;
  return (
    <>
      <Modal dialogClassName="modal-standard" show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className={`${titleIconClass} m-2`}></span>
            {titleText}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CustomForm
            controlSystem={props.controlSystem}
            schema={props.schema}
            uiSchema={props.uiSchema}
            formData={formData}
            handleSubmit={props.handleSubmit ?? handleSubmit}
            name={props.name}
            idName={props.idName}
            id={props.id}
            handleCancel={close}
            rules={props.schemaRules}
            extraErrors={extraErrors}
            handleFieldChange={handleFieldChange}
          />
        </Modal.Body>

        <Modal.Footer>{props.footer}</Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalForm;
