import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import http from "../../services/APIService";
import LoadingSpinner from "./../common/LoadingSpinner";
import DynamicForm from "../common/DynamicForm";
import ItemsContainer from "../common/ConfigItemContainer";
import {
  Alert,
  Badge,
  Container,
  Row,
  Col,
  Nav,
  NavDropdown,
  ListGroup,
  Table,
} from "react-bootstrap";

function Configuration(props) {
  const [configurations, setConfigurations] = useState([]);
  const [sections, setSections] = useState();
  const [section, setSection] = useState();
  const [loading, setLoading] = useState("loading");
  const location = useLocation();
  const params = useParams();
  //   console.log("location :>", location);
  //   console.log("params :>", params);
  //   console.log("params.typeId === undefined", params.typeId === undefined);
  const loadingTimeout = setTimeout(() => {
    setLoading("done");
  }, 500);

  const getConfigurations = async () => {
    const result = await http.callApi("get", "configuration/" + params.id);
    if (result && Array.isArray(result.data)) {
      setConfigurations(result.data);
      console.log("setConfigurations:>", result.data);
    }
  };

  const getSections = async () => {
    // const type = params.typeId !== undefined ? `/${params.typeId}` : "";
    // const set =
    //   params.setId !== undefined ? `/configSectionSetType/${params.setId}` : "";
    // const result = await http.callApi("get", "configSectionType" + type + set);
    const result = await http.callApi("get", `configSectionType/${params.id}`);

    if (result) {
      console.log(
        "configuration.jsx :{} result.Data ",
        result !== undefined ? result.data : "NULL"
      );

      if (result.data) {
        setSections(result.data);
        console.log("setting data:>", result.data);
        clearTimeout(loadingTimeout);
      }
    }
  };

  const getCurrentSection = async () => {
    const type = params.typeId !== undefined ? `/${params.typeId}` : "";
    // const set =
    //   params.setId !== undefined ? `/configSectionSetType/${params.setId}` : "";
    // const result = await http.callApi("get", "configSectionType" + type + set);
    const result = await http.callApi("get", "configSectionType" + type);

    if (result) {
      console.log(
        "configuration.jsx :{} result.Data ",
        result !== undefined ? result.data : "NULL"
      );

      if (result.data) {
        setSection(result.data);
        console.log("setting current section:>", result.data);
        clearTimeout(loadingTimeout);
      }
    }
  };

  const handleClick = () => {
    console.log("handleCLick()");
  };

  useEffect(() => {
    console.log("useEffect() ..............................");
    getConfigurations();
    //getSections();
  }, [params.id, params.typeId]);

  return (
    <>
      {/* {Array.isArray(configurations) && (
        <ul>
          {configurations.map((config) => {
            return <li>{config.name}</li>;
          })}
        </ul>
      )} */}
      {/* <h5>Configuration</h5> */}
      {
        // configuration dropdown list
        //   Array.isArray(sections) && (
        //     <Nav variant="tabs">
        //       <Nav.Item>
        //         <Nav.Link>
        //           <Link to={`/controlsystem/${params.id}/configuration/new`}>
        //             <span className="fa fa-plus mr-2"></span>Add New
        //           </Link>
        //         </Nav.Link>
        //       </Nav.Item>
        //       <NavDropdown title="Existing" id="nav-dropdown">
        //         {configurations.map((item, index) => {
        //           return (
        //             <NavDropdown.Item
        //               key={index}
        //               //href={`/controlsystem/${params.id}/configuration/${item.configSectionTypeId}`}
        //             >
        //               <Link
        //                 to={`/controlsystem/${params.id}/configuration/${item.configurationId}`}
        //               >
        //                 {item.name}
        //               </Link>
        //             </NavDropdown.Item>
        //           );
        //         })}
        //       </NavDropdown>
        //     </Nav>
        //   )
        // }
        /* <br />
      <p>Name of Current Config HEre</p>
      <Container fluid>
        <Row>
          <Col s lg="2">
            {
              // sections dropdown list
              Array.isArray(sections) && (
                <ListGroup>
                  {sections.map((item, index) => {
                    return (
                      <ListGroup.Item key={index}>
                        <Link
                          to={`/controlsystem/${params.id}/configuration/${item.configSectionTypeId}`}
                        >
                          {item.name}
                        </Link>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              )
            }
          </Col> */
      }
      {/* {
        // sections dropdown list
        Array.isArray(sections) && (
          <Nav variant="tabs">
            <NavDropdown title="Configuration Sections" id="nav-dropdown">
              {sections.map((item, index) => {
                return (
                  <NavDropdown.Item
                    key={index}
                    //href={`/controlsystem/${params.id}/configuration/${item.configSectionTypeId}`}
                  >
                    <Link
                      to={`/controlsystem/${params.id}/configuration/${item.configSectionTypeId}`}
                    >
                      {item.name}
                    </Link>
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          </Nav>
        )
      } */}
      {/* <Col md="auto">
            <h5>System OPtions</h5>
          </Col>
        </Row>
      </Container>

      <hr /> */}

      <ItemsContainer
        //items={configurations}
        name="Configuration"
        idName="configurationId"
        endpoint={`configuration/${params.id}`}
        showDelete
        // more="generate"
        // moretitle="Generate"
        // moreIcon="fa fa-download"
      />
    </>
  );
}

export default Configuration;
