import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import { Alert, Badge, Button, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import http from "../../services/APIService";
import ItemList from "../admin/common/itemList";
import LoadingSpinner from "./LoadingSpinner";
import UserContext from "../../contexts/userContext";

let pageName = "";
let apiEndpointName = "";
let idName = "";

const ItemsContainer = (props) => {
  const user = useContext(UserContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState("loading");
  const location = useLocation();
  const history = useHistory();

  pageName = props.morename !== undefined ? props.morename : props.name;

  // console.log(
  //   "itemsContainer :>",
  //   location,
  //   props.morename,
  //   props.match.params,
  //   props.match.params[props.morename],
  //   props.match.params.id
  // );
  // console.log("props.routePath", props.returnPath);
  // console.log("history :> ", history);
  // foreignKeyId =
  //   props.foriegnKeyId !== undefined
  //     ? "/" + user.profile[props.foreignKeyId]
  //     : "";
  if (props.endpoint === undefined)
    // remove spaces and first letter lowercase
    apiEndpointName = _.lowerFirst(_.replace(props.name, " ", ""));
  else apiEndpointName = props.endpoint;

  if (
    props.match &&
    props.match.params.id !== undefined &&
    props.morename !== undefined
  )
    apiEndpointName =
      apiEndpointName + "/" + props.match.params.id + "/" + props.morename;

  if (props.idName === undefined) {
    idName =
      props.morename !== undefined
        ? props.morename + "Id"
        : apiEndpointName + "Id";
  } else {
    idName = props.idName;
  }

  console.log("idName :: ", idName);

  const loadingTimeout = setTimeout(() => {
    setLoading("done");
  }, 500);

  // console.log("props.foriegnKeyId", props.foreignKeyId);
  const getData = async () => {
    // console.log("call api, got a user but we need data");

    //alert(apiEndpointName);

    const result = await http.callApi("get", apiEndpointName);
    if (result) {
      // console.log(
      //   "result.Data ",
      //   result !== undefined ? result.data : "NULL"
      // );
      setData(result.data);
      clearTimeout(loadingTimeout);
    }
  };
  useEffect(() => {
    console.log("props", props);
    console.log(
      "+++++++++++++++ useEFfect props.name ",
      props.name,
      " props.items:",
      props.items
    );
    setData({}); // clear data and get again, otherwise when coming back from history.goBack() it adds the cuurent shit to it
    if (props.items === undefined) getData();
    else setData(props.items);
  }, [props.items, user, location]);

  const handleClick = (e) => {
    if (e.edit !== undefined) {
      //console.log("handleClick() edit: path : ", `./${pageName}/${e.edit}`);
      history.push(`${location.pathname}/${e.edit}`);

      //toast(`${location.pathname}/${e.edit}`);
      console.log("location ", location);
    } else if (e.delete !== undefined) {
      console.log("handleClick (delete)", e.name, e.delete);
      handleDelete(e.name, e.delete);
    }
    // else if (e.related !== undefined && e.relatedData !== undefined) {
    //   // console.log(e.Name);

    //   props.history.push(
    //     "/admin/" + e.relatedData + "?" + idName + "=" + e.related
    //   );
    // }
  };

  const handleDelete = (name, id) => {
    // console.log("handleDelete ", id, pageName);

    confirmAlert({
      title: "Please Confirm",
      message:
        "This will permenently delete " +
        name +
        ":" +
        id +
        ". Do you wish to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            console.log("do delete", id, apiEndpointName);
            try {
              await http.callApi("delete", `${apiEndpointName}/${id}`);
              toast.info("Item deleted successfully");
              window.location.reload();
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <UserContext.Consumer>
      {/* consumer component expects a function as its child */}
      {(userContext) => (
        <React.Fragment>
          {/* test with timeout to see spinner */}
          {/* {!_.isEmpty(data) && sim !== "loading" ? ( */}

          <div>
            <Alert variant="primary">
              <Badge variant="primary">{data.length}</Badge> {pageName}
              {`${data.length > 1 || data.length === 0 ? "s" : ""}`} Found
            </Alert>
            <Link to={`./${pageName}/new`}>
              <Badge variant="primary">+</Badge> Add New
            </Link>
            {props.morename !== undefined && (
              <Button
                variant="link"
                onClick={() =>
                  props.returnPath !== undefined
                    ? history.push(props.returnPath)
                    : history.goBack()
                }
                className="ml-2"
              >
                <Badge variant="primary">&lt;</Badge> Back
              </Button>
            )}
          </div>
          {!_.isEmpty(data) ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  {props.additionalData ? (
                    props.additionalData.map((item) => {
                      return <th>{item}</th>;
                    })
                  ) : (
                    <th></th>
                  )}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ItemList
                  items={data}
                  idName={idName}
                  handleClickProp={handleClick}
                  additionalData={props.additionalData}
                  showDeleteButton={props.showDelete}
                  // relatedData={props.relatedData}
                  // relatedDataIcon={props.relatedDataIcon}
                  more={props.more}
                  moretitle={props.moretitle}
                  moreIcon={props.moreIcon}
                />
              </tbody>
            </Table>
          ) : loading === "loading" ? (
            <LoadingSpinner />
          ) : (
            <LoadingSpinner state="no data" />
          )}
        </React.Fragment>
      )}
    </UserContext.Consumer>
  );
};

export default ItemsContainer;
