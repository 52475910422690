import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/theme-tomorrow_night";
import "ace-builds/src-noconflict/theme-tomorrow_night_eighties";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-kuroir";
import { Button, Modal } from "react-bootstrap";
import _ from "lodash";

import { IsJsonString } from "./../../Utils";
import DynamicForm from "../common/DynamicForm";
import CopyToClip from "./../common/CopyToClipboard";
import Form from "@rjsf/core";

function JsonEditor(props) {
  // code to show in editor
  const [code, setCode] = useState(
    typeof props.value === "string" ? props.value : undefined
  );

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    console.log("JsonEditor props.value", props.value, typeof props.value);
    setCode(props.value);

    setShowPreview(
      !props.hidePreview && props.value && IsJsonString(props.value)
    );
    //console.log("jsonEditor *************** props.value ", props.value, props)
    //console.log("IsJsonString(code)", IsJsonString(props.value));
  }, [props.value]);

  return (
    <div>
      <AceEditor
        mode="json"
        //theme="github"
        //theme="solarized_light"
        //theme="solarized_dark"
        theme={props.theme ?? "monokai"}
        //onChange={onChange}
        onChange={(newValue) => props.onChange(newValue)}
        name="jsonAceEditor"
        editorProps={{ $blockScrolling: true }}
        //defaultValue={JSON.stringify(code, "", 3)}
        defaultValue={code}
        fontSize={14}
        width={props.width ?? "100%"}
        height={props.height ?? "400px"}
        highlightActiveLine={true}
      />

      {props.showCopyButtons && (
        <>
          <CopyToClip text={code} label="Copy" />
        </>
      )}

      {showPreview && (
        <>
          <Button
            className="btn btn-sm mt-1 ml-1"
            variant="light"
            onClick={handleShow}
          >
            <span className="fa fa-eye mr-2"></span>
            Preview
          </Button>

          <Modal
            dialogClassName="modal-standard"
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Form Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                className="admin-schemaform-modal"
                schema={JSON.parse(code)}
                liveValidate={true}
                preview={true}
                showErrorList={false}
                showCopyButtons={true}
              >
                {" "}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                <span className="fa fa-times-circle mr-2"></span>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
}

export default JsonEditor;
