import React from "react";

import Form from "@rjsf/core";
import AutoPopulateSelectFieldTemplate from "../common/CustomFieldsAndTemplates/AutoPopulateSelectFieldTemplate";
import CustomSchemaField from "../common/CustomFieldsAndTemplates/CustomSchemaField";

import applyRules from "react-jsonschema-form-conditionals";
import Engine from "json-rules-engine-simplified";
import predicate from "predicate";

import CustomFieldTemplate from "../common/CustomFieldsAndTemplates/CustomFieldTemplate";

function HelpComponentTest(props) {
  const schema = {
    title: "A registration form",
    type: "object",
    required: ["firstName"],
    properties: {
      firstName: {
        type: "string",
        title: "First name",
        description: "Enter your first name here",
      },
      list: {
        type: "number",
      },
    },
  };
  const uiSchema = {
    firstName: {
      "ui:help": "name help text goes here",
      "ui:help-link": "/test/jsonEditor",
      // "ui:help": <Help {...props} />,
    },
    list: {
      "ui:field": "cg:autoPopulateSelectField",
      "ui:options": {
        "cg:endpoint": "configuration.sources",
        "cg:idAccessor": "sourceId",
      },
      "ui:help": "list help text goes here",
      "ui:placeholder": "--select a source--",
    },
  };

  let fields = {
    SchemaField: CustomSchemaField,
    "cg:autoPopulateSelectField": (props) => (
      <AutoPopulateSelectFieldTemplate {...props} />
    ), // "use cg:customField from uiSchema to specify this custom field template for the one field"
  };
  let rules = [];
  let FormWithConditionals = applyRules(schema, uiSchema, rules, Engine)(Form);

  const validate = (formData, errors) => {
    console.log("validate", formData, errors);

    // add an error
    errors.firstName.addError("cant do that");
    errors.list.addError("cant do this");

    return errors;
  };
  let configuration = {
    sources: [
      { name: "source1", sourceId: 1 },
      { name: "source2", sourceId: 2 },
    ],
  };
  return (
    <div>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        validate={validate}
        formContext={{ configuration: configuration }}
        FieldTemplate={CustomFieldTemplate}
      />
    </div>
  );
}

export default HelpComponentTest;
