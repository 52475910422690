import React, { useEffect, useState } from "react";
import http from "../../../services/APIService";
import _ from "lodash";
import Form from "@rjsf/core";

function AutoPopulateSchemaDependencyField(props) {
  const [schema, setSchema] = useState(undefined);

  console.log("AutoPopulateSchemaDependencyField props", props);
  const {
    id,
    name,
    classNames,
    required,
    idSchema,
    //schema,
    uiSchema,
    formData,
    formContext, // holds extra props (ie configuration, controlSystem)
    onChange,
    registry,
    errorSchema,
    idPrefix,
  } = props;

  const tempSchema = {
    title: "A registration form",
    description: "A simple form example.",
    type: "object",
    required: ["lastName"],
    properties: {
      firstName: {
        type: "string",
        title: "First name",
      },
      lastName: {
        type: "string",
        title: "Last name",
      },
      telephone: {
        type: "string",
        title: "Telephone",
        minLength: 10,
      },
    },
  };

  const getData = async (tmpSchema) => {
    let options = props.uiSchema["ui:options"];
    if (!options) return;
    let endpoint = options["cg:endpoint"];

    let results = {};

    if (endpoint) {
      const result = await http.callApi("get", endpoint);

      if (!result || result.status !== 200) return;
      setSchema(result.data);
    }

    console.log("AutoPopulateSchemaDependencyField results", results);
  };
  const validate = (errors) => {
    console.log("autoPopulateSchemaDependencyField Validate() errors", errors);

    return errors;
  };
  useEffect(() => {
    if (schema !== undefined) {
      getData(schema);
    }
  }, []);
  function transformErrors(errors) {
    console.log("transformErrors", errors);

    formContext.appendErrors(errors);
    return errors;
    // return errors.map((error) => {
    //   if (error.name === "pattern") {
    //     error.message = "Only digits are allowed";
    //   }
    //   return error;
    // });
  }
  return (
    <Form
      idPrefix="configuredForm"
      tagName="div"
      schema={tempSchema}
      formData={formData}
      liveValidate
      showErrorList={false}
      //validate={validate}
      //transformErrors={transformErrors}
      onChange={(e) => onChange(e.formData)}
    >
      <span></span>
    </Form>
  );
}

export default AutoPopulateSchemaDependencyField;
