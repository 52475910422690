import React, { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import _ from "lodash";

const Mustache = require("mustache");
var jp = require("jsonpath");

// const removeItem = (element) => {
//   console.log("remove item ", element);
//   element.onDropIndexClick(element.index);
// };

const moveButtonVariant = "light";
const deleteButtonVariant = "light";

function ArrayFieldTemplate(props) {
  console.log("ArrayFieldTemplate() props", props);

  const {
    items,
    className,
    formContext,
    uiSchema,
    schema,
    idSchema,
    registry,
    title,
    canAdd,
    onAddClick,
  } = props;

  let options = uiSchema["ui:options"];
  let property = options && options["cg:itemsProperty"];
  let prefix = options && options["cg:prefix"];
  let propertyData = _.get(formContext, property);
  let maxItems = Array.isArray(propertyData) ? propertyData.length : undefined;
  let titleAccessor = options && options["cg:titleAccessor"];
  let propertyDataTitles = propertyData?.map(
    (i) => i[titleAccessor] && ` (${i[titleAccessor].toUpperCase()})`
  );
  const [showAddButton, setShowAddButton] = useState(false);

  useEffect(() => {
    if (maxItems > 0) {
      setShowAddButton(canAdd && items.length < maxItems);
    } else {
      setShowAddButton(canAdd);
    }
  }, [items]);

  return (
    <div>
      <h5>{title}</h5>

      {items.map((element) => (
        <div className={className}>
          <div key={element.key} className={element.className}>
            <Badge variant="info" className="mb-1">
              {prefix}
              {element.index + 1}.
              {propertyData && propertyDataTitles[element.index]}
              {/* ${element.children.props.schema.title ?? ""} */}
            </Badge>

            {console.log("element", element)}

            <div>{element.children}</div>

            {element.hasMoveDown && (
              <Button
                className="mt-1 mr-1"
                variant={moveButtonVariant}
                size="sm"
                // block
                onClick={element.onReorderClick(
                  element.index,
                  element.index + 1
                )}
                title="Move Down"
              >
                <span className="fa fa-arrow-down"></span>
              </Button>
            )}
            {element.hasMoveUp && (
              <Button
                className="mt-1 mr-1"
                variant={moveButtonVariant}
                size="sm"
                // block
                onClick={element.onReorderClick(
                  element.index,
                  element.index - 1
                )}
                title="Move Up"
              >
                <span className="fa fa-arrow-up"></span>
              </Button>
            )}

            <Button
              className="mt-1"
              variant={deleteButtonVariant}
              size="sm"
              block={element.hasMoveUp == false && element.hasMoveDown == false}
              onClick={element.onDropIndexClick(element.index)}
              title="Remove Item"
            >
              <span className="fa fa-trash"></span>
              {/* {" "}
              {`Delete ${props.uiSchema.itemName ?? ""}`} */}
            </Button>
            {/* <hr /> */}
          </div>
        </div>
      ))}
      {showAddButton && (
        <Button variant="info" size="sm" onClick={onAddClick}>
          <span className="fa fa-plus mr-1"></span>{" "}
          {`Add ${uiSchema.itemName ?? ""}`}
        </Button>
      )}
    </div>
  );
}

export default ArrayFieldTemplate;
