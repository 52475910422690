import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  Accordion,
  Spinner,
  ProgressBar,
  Alert,
} from "react-bootstrap";

import { toast } from "react-toastify";
import http from "../../../services/APIService";
import LoadingSpinner from "../../common/LoadingSpinner";
import JsonEditor from "../../admin/JsonEditor";

let getStatusInterval;

function AnalyticsJobStatus(props) {
  const [loadingState, setLoadingState] = useState(undefined);
  const [jobs, setJobs] = useState(undefined);
  // get deploy jobs for this control system
  const getJobs = async () => {
    setLoadingState("loading");
    setTimeout(() => {
      setLoadingState("done");
    }, 1000);

    const result = await http.callApi(
      "get",
      `jobs/controlSystem/${props.controlSystem.controlSystemId}/console`
    );
    if (result) {
      console.log(
        "analytics getJobs() results",
        result !== undefined ? result.data : "NULL"
      );

      setJobs(undefined);
      setJobs(result.data);
    }
  };
  useEffect(() => {
    if (props.controlSystem) {
      getJobs();
    }
  }, [props.controlSystem]);

  useEffect(() => {
    if (jobs) {
      if (getStatusInterval) clearInterval(getStatusInterval);
      getStatusInterval = setInterval(() => {
        getJobs();
      }, 2000);
    }

    return () => {
      console.log(
        "Analytics JobStatus useEffect() UNMOUNTING >> clearInterval()"
      );
      if (getStatusInterval) clearInterval(getStatusInterval);
    };
  }, [jobs]);

  const handleDelete = async (job) => {
    console.log("handleDelete ", job);
    try {
      await http.callApi("delete", `jobs/${job.jobId}`);
      toast.info("Item deleted successfully");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  if (jobs === undefined) {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }
  if (Array.isArray(jobs) && jobs.length === 0) {
    return <></>;
  }
  return (
    <>
      {jobs.map((job) => (
        <>
          <Container key={job.jobId}>
            <Row>
              <Col sm="2">Job Name:</Col>
              <Col>{job.name}</Col>
            </Row>
            <Row>
              <Col sm="2">Command:</Col>
              <Col>{job.consoleCommand}</Col>
            </Row>
            <Row>
              <Col>
                <i className="fas fa-terminal mr-2"></i>Console Reply:{" "}
              </Col>
            </Row>
            <Row>
              <Col>
                {job.jobStatus?.consoleReply ? (
                  <JsonEditor
                    height="300px"
                    width="670px"
                    value={job.jobStatus?.consoleReply}
                    onChange={() => console.log("jsonEditor onChange")}
                  ></JsonEditor>
                ) : (
                  <>
                    <i className="fas fa-spinner fa-spin mr-2 mt-4 mb-4"></i>
                    Waiting for gateway poll interval, please wait..
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => handleDelete(job)}
                  className="mt-1"
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Container>

          <hr />
        </>
      ))}
    </>
  );
}

export default AnalyticsJobStatus;
