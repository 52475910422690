import React from "react";
import http from "../../../services/APIService";
import _ from "lodash";
export default function DefaultFormDataWidget(props) {
  console.log("DefaultFormDataWidget props", props);

  let endpoint = props.options["cg:endpoint"];
  const handleChange = async (value) => {
    console.log("handleChange ", value);
    props.onChange(value);

    if (endpoint) {
      const result = await http.callApi("get", endpoint + "/" + value);

      if (!result || result.status !== 200) return;

      console.log("defaultFormData results", result.data?.defaultFormData);
      let defaultFormData = result.data?.defaultFormData;
      let formData = { ...props.formContext.formData };
      console.log("formData to add to ", props.formContext.formData);
      if (!defaultFormData) return;
      console.log("object.keys", Object.keys(defaultFormData));
      Object.keys(defaultFormData).map((key) => {
        console.log("key", key, defaultFormData[key]);
        console.log("value in formData", formData[key]);
        _.set(formData, key, defaultFormData[key]);
        console.log("formData after set", formData);
      });
      // call this function in customForm, which will find the defaultFormData object in eg formData.ControlSystem.portDeviceType.defaultFormData and
      props.formContext.setFormData(formData);
    }
  };
  return (
    <>
      <select
        name="portDeviceTypeId"
        id="portDeviceTypeId"
        value={props.value}
        onChange={(e) => handleChange(e.target.value)}
        className="form-control"
        required
      >
        <option value>--select--</option>
        {props.options.enumOptions.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    </>
  );
}
