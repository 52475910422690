import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Card, Button, Table } from "react-bootstrap";
import _ from "lodash";
import { toast } from "react-toastify";

import http from "../../../services/APIService";
import UserContext from "../../../contexts/userContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import ModalForm from "../../common/ModalForm";
import { confirmAlert } from "react-confirm-alert";

import ControlSystemContext from "../../../contexts/ControlSystemContext";

const Mustache = require("mustache");

function SwitcherCard(props) {
  let { id: controlSystemId } = useParams();
  const controlSystem = useContext(ControlSystemContext);
  const params = useParams();
  const [modalShow, setModalShow] = useState({});
  const user = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState("loading");
  const [schema, setSchema] = useState({});

  console.log("SWITCHERCARD CONTROLSYSTEM", controlSystem);

  const [endpoint, setEndpoint] = useState(
    props.endpoint ? Mustache.render(props.endpoint, params) : ""
  );

  const handleClose = () => {
    setModalShow({ state: false });
  };

  const handleShow = (cardId, name, slot) => {
    console.log(
      "SWITCHER CARD SHOW ::::: ",
      " props.name",
      props.name,
      " cardId",
      cardId,
      " name",
      name,
      " slot ",
      slot
    );

    //getCard(cardId);

    setModalShow({
      state: true,
      id: cardId,
      name: name,
      slot: slot,
    });
  };

  // const handleSubmit = async (e) => {
  //   console.log(
  //     "SwitcherCard HandleSubmit ",
  //     e,
  //     "endpoint",
  //     endpoint,
  //     modalShow.id,
  //     modalShow.slot
  //   );
  //   if (modalShow.id === "new") {
  //     let formData = { ...e.formData };

  //     console.log(
  //       "SwitcherCard handleSubmit() ModalForm ADD NEW- endpoint: ",
  //       endpoint,
  //       formData
  //     );
  //     const result = await http.callApi(
  //       "post",
  //       `${endpoint}/${modalShow.slot}`,
  //       e.formData
  //     );
  //     if (result) {
  //       toast.success(modalShow.name + " added successfully");
  //       console.log("result data ::: ", result);
  //       handleFormDataUpdateFromModal(modalShow.name, result);
  //       handleClose();
  //     }
  //   } else {
  //     console.log(
  //       "handleSubmit() ModalForm UPDATE id ",
  //       modalShow.id,
  //       "name",
  //       modalShow.name,
  //       `${endpoint}/${modalShow.slot}`,
  //       e.formData
  //     );

  //     const itemIdIfExists = modalShow.id ? "/" + modalShow.id : "";

  //     const result = await http.callApi(
  //       "put",
  //       //`${modalShow.name}/${controlSystemId}${itemIdIfExists}`,
  //       `${endpoint}/${modalShow.slot}${itemIdIfExists}`,
  //       e.formData
  //     );
  //     if (result) {
  //       toast.success(modalShow.name + " updated successfully");
  //       console.log("result data ::: ", result);
  //       handleFormDataUpdateFromModal(modalShow.name, result);
  //       handleClose();
  //     }
  //   }
  // };

  const handleDelete = async (itemId) => {
    confirmAlert({
      title: "Please Confirm",
      message:
        "This will permenently delete the selected item. Do you wish to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const result = await http.callApi(
                "delete",
                props.name + "/" + controlSystemId + "/" + itemId
              );
              if (result) getItems();

              toast.info("Item deleted successfully");
              //window.location.reload();
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  async function getItems() {
    const loadingTimeout = setTimeout(() => {
      setLoading("done");
    }, 500);

    console.log("switcherCard getItems() endpoint", endpoint);
    const result = await http.callApi("get", endpoint);
    if (result && Array.isArray(result.data)) {
      setItems(result.data);
      clearTimeout(loadingTimeout);
      setLoading("done");
      console.log(props.name + "  <><>< getItems() result", result);
    }
  }

  // async function getCard(cardId, slot) {
  //   if (cardId !== "new") {
  //     const endpoint = props.name + "/" + controlSystemId + "/" + cardId;
  //     console.log("getCard endpoint", endpoint);
  //     const result = await http.callApi("get", endpoint);
  //     if (result && Array.isArray(result.data)) {
  //       setItems(result.data);
  //       setLoading("done");
  //       console.log(props.name + " getCards() result", result);
  //     }
  //   }
  // }

  async function getSchema(controller, type) {
    //console.log("getting schema from server");
    const loadingTimeout = setTimeout(() => {
      setLoading("done");
    }, 500);
    const result = await http.callApi(
      "get",
      Mustache.render(controller, params)
    );

    if (result) {
      setSchema(result.data);

      clearTimeout(loadingTimeout);
      setLoading("done");
      console.log(props.name + " getSChema() result", result);
    }
  }

  useEffect(() => {
    if (props.schema === undefined) getSchema(props.schemaController);
    if (endpoint) getItems();
  }, [user, controlSystemId, endpoint]);

  const handleFormDataUpdateFromModal = (result) => {
    console.log(
      "!!!!!!!!!!! handleFormDataUpdateFromModal() switcherCard",
      props.name,
      result
    );
    if (typeof props.performUpdate === "function") {
      // console.log("props.performUpdate", typeof props.performUpdate);
      // console.log("************* performUpdate", props);
      props.performUpdate(); // gets the current control system again (parent)
    }

    // local
    getItems();
  };

  return (
    <Card>
      <Card.Header>
        <span className={`fa ${props.icon} mr-2`}></span>
        {props.header}
      </Card.Header>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>

        {Array.isArray(items) && items.length ? (
          <Table striped bordered hover size="sm">
            <tbody>
              {items.map((item, index) => {
                //console.log("item >>>>>>> ", item);
                return (
                  <tr key={index}>
                    <td>
                      {item.card != undefined && item.card != "" ? (
                        <>
                          <h5>{item.name} </h5>
                          Card: {item.card}
                          <br />
                          {Array.isArray(item.endpoints) &&
                            item.endpoints.length > 0 && (
                              <>
                                {item.title}
                                <ol>
                                  {item.endpoints.map((endpoint, epindex) => {
                                    return <li key={epindex}>{endpoint}</li>;
                                  })}
                                </ol>
                              </>
                            )}
                        </>
                      ) : (
                        <>
                          <h5>{item.name} </h5>[empty]
                        </>
                      )}
                    </td>
                    <td>
                      <Button
                        variant="link"
                        className="btn btn-sm"
                        onClick={() =>
                          item.card === ""
                            ? handleShow("new", props.name, item.slot)
                            : handleShow(
                                item[props.idName],
                                props.name,
                                item.slot
                              )
                        }
                      >
                        {/* <span className="fa fa-pencil"></span> */}
                        <span
                          className={
                            modalShow.state &&
                            modalShow.name === props.name &&
                            modalShow.id === item[props.idName]
                              ? "fa fa-spinner fa-spin"
                              : item.card !== undefined && item.card === ""
                              ? "fa fa-plus"
                              : "fa fa-pencil"
                          }
                        ></span>
                        {/* {console.log("modalShow", modalShow)} */}
                      </Button>
                    </td>
                    {props.showDelete && (
                      <td>
                        {item.card && (
                          <Button
                            variant="link"
                            className="btn btn-sm"
                            onClick={() => handleDelete(item[props.idName])}
                          >
                            <span className="fa fa-trash"></span>
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : loading === "loading" ? (
          <LoadingSpinner />
        ) : (
          <LoadingSpinner state="no data" />
        )}

        <ModalForm
          schema={props.schema ? props.schema : schema}
          uiSchema={props.uiSchema}
          name={`${props.name}/${modalShow.slot}/${params.switcherId}`}
          idName={props.idName}
          modalFooter={props.footer}
          id={
            modalShow.state && modalShow.name === props.name ? modalShow.id : 0
          }
          show={modalShow.state && modalShow.name === props.name}
          close={() => handleClose()}
          handleFormDataUpdate={handleFormDataUpdateFromModal}
          controlSystemId={controlSystemId}
          controlSystem={controlSystem}
          showCopyButtons={props.showCopyButtons}
          formTitle={props.formTitle}
          formTitleIcon={props.formTitleIcon}
          liveValidate={props.liveValidate}
          // handleSubmit={handleSubmit}
        />
        {/* {props.secondarySchemaController && (
          <>
            <ModalForm
              schema={secondarySchema}
              //uiSchema={props.uiSchema}
              name="configuration"
              idName={props.idName}
              //modalFooter={props.footer}
              id={
                modalShow.state && modalShow.name === "secondary"
                  ? modalShow.id
                  : 0
              }
              show={modalShow.state && modalShow.name === "secondary"}
              close={() => handleClose()}
              handleFormDataUpdate={handleFormDataUpdateFromModal}
              controlSystemId={controlSystemId}
              showCopyButtons={props.showCopyButtons}
              formTitle="Hardware Configuration"
              formTitleIcon="fa fa-server"
            />
            <Button
              variant="link"
              className="btn btn-sm"
              onClick={() => handleShow(1, "secondary")}
            >
              Inputs
            </Button>
          </>
        )} */}
      </Card.Body>
      {props.cardFooter && (
        <Card.Footer>
          <small className="text-muted">{props.cardFooter}</small>
        </Card.Footer>
      )}
    </Card>
  );
}

export default SwitcherCard;
