//import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
//import { SentryError } from "@sentry/utils";
//import config from "../config.json";

function init() {
  // Sentry.init({
  //   dsn: config.sentryUrl
  // });
}

function log(message, showDialog, showToast) {
  //Sentry.captureException(message);
  if (showToast) toast.error("An unexpected error occurred"); // white with colours along bottom of toast
  //if (showDialog) Sentry.showReportDialog();
}

export default { init, log };
