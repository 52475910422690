import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { Alert, Badge, Button, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import UserContext from "../../../contexts/userContext";
import http from "../../../services/APIService";

import AdminNav from "./adminNav";
import ItemList from "./itemList";

import LoadingSpinner from "../../common/LoadingSpinner";

let pageName = "";
let apiEndpointName = "";
let idName = "";
let nameField = "name";

const ItemsContainer = (props) => {
  const user = useContext(UserContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState("loading");
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  pageName = props.morename !== undefined ? props.morename : props.name;
  nameField = props.nameField !== undefined ? props.nameField : "name";
  console.log("params paramsparams", params);

  console.log("pageName", pageName);

  if (props.endpoint === undefined) {
    apiEndpointName = _.lowerFirst(_.replace(props.name, " ", "")); // remove spaces and first letter lowercase

    if (params.id !== undefined && props.morename !== undefined)
      apiEndpointName =
        apiEndpointName + "/" + params.id + "/" + props.morename;
  } else apiEndpointName = props.endpoint;

  if (props.idName === undefined) {
    idName =
      props.morename !== undefined
        ? props.morename + "Id"
        : apiEndpointName + "Id";
  } else {
    idName = props.idName;
  }
  console.log("idName :: ", idName);
  console.log("apiEndpointName :: ", apiEndpointName);
  // console.log("props.foriegnKeyId", props.foreignKeyId);

  const loadingTimeout = setTimeout(() => {
    setLoading("done");
  }, 500);
  useEffect(() => {
    console.log("+++++++++++++++ useEFfect");
    setData({}); // clear data and get again, otherwise when coming back from history.goBack() it adds the cuurent shit to it
    // console.log(
    //   "user role::: ",
    //   user.profile
    //     ? user.profile.role
    //       ? user.profile.role
    //       : "loading"
    //     : "loading"
    // );
    async function getData() {
      // console.log("call api, got a user but we need data");

      //alert(apiEndpointName);

      const result = await http.callApi("get", apiEndpointName);
      if (result) {
        // console.log(
        //   "result.Data ",
        //   result !== undefined ? result.data : "NULL"
        // );
        // const currentIndex = _.findIndex(result.data, [
        //   "templateId",
        //   parseInt(params.templateId),
        // ]);
        // console.log(
        //   "params.collection",
        //   result.data,
        //   params.collection,
        //   params.templateId,
        //   currentIndex,
        //   result.data[currentIndex][params.collection]
        // );
        // if (params.collection !== undefined)
        //   setData(result.data[currentIndex][params.collection]);
        // else

        setData(result.data);
        clearTimeout(loadingTimeout);
      }
    }
    getData();
  }, [user, location]);

  const handleClick = (e) => {
    if (e.edit !== undefined) {
      // console.log("handleClick", `${pageName}/${e.edit}`);
      props.history.push(`${pageName}/${e.edit}`);
    } else if (e.delete !== undefined) {
      console.log("handleClick (delete)", e.name, e.delete, apiEndpointName);
      handleDelete(e.name, e.delete);
    }
    // else if (e.related !== undefined && e.relatedData !== undefined) {
    //   // console.log(e.Name);

    //   props.history.push(
    //     "/admin/" + e.relatedData + "?" + idName + "=" + e.related
    //   );
    // }
  };

  const handleDelete = (name, id) => {
    console.log("handleDelete ", id, pageName);

    confirmAlert({
      title: "Please Confirm",
      message:
        "This will permenently delete " +
        name +
        ":" +
        id +
        ". Do you wish to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            console.log("do delete", id, pageName);
            try {
              const result = await http.callApi(
                "delete",
                `${apiEndpointName}/${id}`
              );
              //alert(result.status);
              if (result.status === 200) {
                toast.info("Item deleted successfully");
                window.location.reload();
              } else toast.error("Error deleting item");
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <UserContext.Consumer>
      {/* consumer component expects a function as its child */}
      {(userContext) => (
        <React.Fragment>
          <div className="display-4 my-0 py-0">
            <span className="fas fa-user-cog mr-2"></span>Administration
          </div>
          <div>
            <AdminNav path={props.match.path} />
          </div>

          {/* test with timeout to see spinner */}
          {/* {!_.isEmpty(data) && sim !== "loading" ? ( */}

          <div>
            <Alert variant="primary">
              <Badge variant="primary">{data.length}</Badge> {pageName}
              {`${data.length > 1 || data.length === 0 ? "s" : ""}`} Found
            </Alert>
            <Link to={`./${pageName}/new`}>
              <Badge variant="primary">
                <span className="fa fa-plus"></span>
              </Badge>{" "}
              Add New
            </Link>
            {props.morename !== undefined && (
              <Link
                onClick={() =>
                  props.returnPath !== undefined
                    ? history.push(props.returnPath)
                    : history.goBack()
                }
                className="ml-2"
              >
                <Badge variant="primary">
                  <span className="fa fa-arrow-left"></span>
                </Badge>{" "}
                Back
              </Link>
            )}
            <br />
            <br />
          </div>
          {!_.isEmpty(data) ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  {props.additionalData ? (
                    props.additionalData.map((item) => {
                      return <th>{item}</th>;
                    })
                  ) : (
                    <th></th>
                  )}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ItemList
                  items={data}
                  idName={idName}
                  nameField={nameField}
                  handleClickProp={handleClick}
                  additionalData={props.additionalData}
                  showDeleteButton={props.showDelete}
                  // relatedData={props.relatedData}
                  // relatedDataIcon={props.relatedDataIcon}
                  more={props.more}
                  moretitle={props.moretitle}
                />
              </tbody>
            </Table>
          ) : loading === "loading" ? (
            <LoadingSpinner />
          ) : (
            <LoadingSpinner state="no data" />
          )}
        </React.Fragment>
      )}
    </UserContext.Consumer>
  );
};

export default ItemsContainer;
