import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { CardColumns } from "react-bootstrap";
import _ from "lodash";

import http from "../../services/APIService";
import SwitcherCard from "./Equipment/SwitcherCard";
import LoadingSpinner from "../common/LoadingSpinner";

import ControlSystemContext from "../../contexts/ControlSystemContext";

function ControlSystemSwitcher(props) {
  let { id: controlSystemId } = useParams();
  const controlSystem = useContext(ControlSystemContext);
  const params = useParams();
  const [switcher, setSwitcher] = useState({});
  const [schema, setSchema] = useState({});
  const [inputs, setInputs] = useState([]);
  const [loading, setLoading] = useState("loading");
  const [dmpsDMInputList, setDMPSDMInputList] = useState([]);

  async function getCurrent() {
    const loadingTimeout = setTimeout(() => {
      setLoading("done");
    }, 500);

    const endpoint = `switcher/${controlSystemId}/${params.switcherId}`;

    const result = await http.callApi("get", endpoint);
    if (result) {
      setSwitcher(result.data);
      clearTimeout(loadingTimeout);
      setLoading("done");
      console.log(" getSwitcher() result", result);
    }
  }

  useEffect(() => {
    getCurrent();
  }, [controlSystemId]);

  useEffect(() => {
    setDMPSDMInputList([{ name: "Input 6" }, { name: "Input 7" }]);
  }, [switcher]);

  const dmTransmitterSchema = {
    title: "Transmitter Configuration",
    type: "object",
    properties: {
      card: {
        type: "object",
        title: "DM Input",
        properties: {
          switcherId: {
            type: "number",
          },
          dmInputCardTypeId: {
            type: "number",
          },
          dmInputCardId: {
            type: "number",
          },
          inputSlotNumber: {
            type: "number",
          },
          // controlSystemId: { type: "string" },
          dmTransmitters: {
            type: "array",
            title: "Transmitter",
            maxItems: 1,
            items: {
              type: "object",
              required: ["name"],
              properties: {
                //controlSystemId: { type: "string" },
                dmTransmitterId: {
                  type: "number",
                },
                dmTransmitterTypeId: {
                  type: "number",
                  title: "Type",
                },
                identifier: {
                  type: "string",
                },
                name: {
                  type: "string",
                  title: "Name",
                },
                ipId: {
                  type: "number",
                  title: "IP ID",
                },
              },
            },
          },
        },
      },
    },
  };

  const dmTransmitterUi = {
    card: {
      switcherId: {
        "ui:widget": "hidden",
      },
      dmInputCardTypeId: {
        "ui:widget": "hidden",
      },
      dmInputCardId: {
        "ui:widget": "hidden",
      },
      inputSlotNumber: {
        "ui:widget": "hidden",
      },
      dmTransmitters: {
        "ui:placeholder": "--select--",
        "ui:field": "cg:autoPopulateOneOfField",
        "ui:options": {
          "cg:endpoint": `dmTransmitterType`,
          "cg:titleAccessor": "name",
          "cg:idAccessor": "dmTransmitterTypeId",
        },
        items: {
          dmTransmitterId: {
            "ui:widget": "hidden",
          },
          identifier: {
            "ui:widget": "hidden",
          },
          ipId: {
            "ui:field": "cg:autoPopulateSelectField",
            "ui:options": {
              "cg:endpoint": "controlSystem.occupiedIpIds",
              "cg:titleAccessor": "description",
              "cg:idAccessor": "itemId",
              "cg:pad": {
                min: 3,
                max: 254,
                prefix: "",
              },
              "cg:displayHex": true,
            },
            "ui:placeholder": "--select--",
            "ui:help": "Values are in HEX",
          },
        },
      },
    },
  };

  const dmReceiverSchema = {
    title: "Receiver Configuration",
    type: "object",
    properties: {
      card: {
        type: "object",
        title: "DM Output",
        properties: {
          switcherId: {
            type: "number",
          },
          dmOutputCardTypeId: {
            type: "number",
          },
          dmOutputCardId: {
            type: "number",
          },
          outputSlotNumber: {
            type: "number",
          },

          dmReceivers: {
            type: "array",
            title: "Receiver",
            maxItems: 1,
            items: {
              type: "object",
              required: ["name"],
              properties: {
                dmReceiverId: {
                  type: "number",
                },
                dmReceiverTypeId: {
                  type: "number",
                  title: "Type",
                },
                identifier: {
                  type: "string",
                },
                name: {
                  type: "string",
                  title: "Name",
                },
                ipId: {
                  type: "number",
                  title: "IP ID",
                },
              },
            },
          },
        },
      },
    },
  };

  const dmReceiverUi = {
    card: {
      switcherId: {
        "ui:widget": "hidden",
      },
      dmOutputCardTypeId: {
        "ui:widget": "hidden",
      },
      dmOutputCardId: {
        "ui:widget": "hidden",
      },
      outputSlotNumber: {
        "ui:widget": "hidden",
      },
      dmReceivers: {
        "ui:placeholder": "--select--",
        "ui:field": "cg:autoPopulateOneOfField",
        "ui:options": {
          "cg:endpoint": `dmReceiverType`,
          "cg:titleAccessor": "name",
          "cg:idAccessor": "dmReceiverTypeId",
        },
        items: {
          dmReceiverId: {
            "ui:widget": "hidden",
          },
          identifier: {
            "ui:widget": "hidden",
          },
          ipId: {
            "ui:field": "cg:autoPopulateSelectField",
            "ui:options": {
              "cg:endpoint": "controlSystem.occupiedIpIds",
              "cg:titleAccessor": "description",
              "cg:idAccessor": "itemId",
              "cg:pad": {
                min: 3,
                max: 254,
                prefix: "",
              },
              "cg:displayHex": true,
            },
            "ui:placeholder": "--select--",
            "ui:help": "Values are in HEX",
          },
        },
      },
    },
  };

  return (
    <>
      <h5>DMPS Endpoint Configuration</h5>
      <CardColumns>
        {!_.isEmpty(switcher) ? (
          <>
            <SwitcherCard
              icon="fa-server"
              header="Transmitters"
              title="DM Inputs"
              endpoint={`dmInputCard/${controlSystemId}/${switcher.switcherId}`}
              name="dmInputCard"
              currentId={switcher.switcherId}
              idName="dmInputCardId"
              schema={dmTransmitterSchema} // if no schema defined, it get one constructed on the server using schemaController prop
              //schemaController="dmInputCard/schema/{{id}}/{{switcherId}}"
              uiSchema={dmTransmitterUi}
              controlSystemId={controlSystemId}
              showCopyButtons={true}
              showDelete={false}
              singleInstance={true}
              liveValidate={false}
              formTitle="DMPS"
              formTitleIcon="fa fa-pencil"
              performUpdate={() => {
                props.getControlSystem();
              }}
            />

            <SwitcherCard
              icon="fa-server"
              header="Receiver"
              title="DM Outputs"
              endpoint="dmOutputCard/{{id}}/{{switcherId}}"
              name="dmOutputCard"
              currentId={switcher.switcherId}
              idName="dmOutputCardId"
              schema={dmReceiverSchema} // if no schema defined, it get one constructed on the server using schemaController prop
              //schemaController="dmOutputCard/schema/{{id}}/{{switcherId}}"
              uiSchema={dmReceiverUi}
              controlSystemId={controlSystemId}
              showCopyButtons={true}
              showDelete={false}
              singleInstance={true}
              liveValidate={false}
              formTitle="DMPS"
              formTitleIcon="fa fa-pencil"
              performUpdate={() => {
                props.getControlSystem();
              }}
            />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </CardColumns>
    </>
  );
}

export default ControlSystemSwitcher;
