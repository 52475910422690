import React from "react";
import Form from "react-jsonschema-form";
import { Modal, Button } from "react-bootstrap";
import { AuthService } from "../services/AuthService";

import logo from "../configured.svg";
import CustomFieldTemplate from "./common/CustomFieldsAndTemplates/CustomFieldTemplate";

function Login(props) {
  const authService = new AuthService();

  const schema = {
    title: props.name,
    description: "Please enter your email address to continue",
    type: "object",
    required: ["email"],
    properties: {
      email: {
        type: "string",
        title: "Email",
        format: "email",
      },
    },
  };

  const uiSchema = {
    email: {
      classNames: "form-input-fixed",
      "ui:placeholder": "name@domain",
      "ui:options": {
        inputType: "email",
      },
    },
  };

  const handleSubmit = async ({ formData }) => {
    //console.log("handleSubmit", formData.email);

    //console.log(formData.email.split("@").pop());

    // pop returns last item of array
    const mailbox = formData.email.split("@").shift();
    const domain = formData.email.split("@").pop(); // removes the local mailbox name, ie up to and including the @
    // console.log("mailbox", mailbox);
    // console.log("domain", domain);

    // authService.login({
    //   acr_values: "idp:Google tenant:Brad Allen",
    //   login_hint: "brussallen@gmail.com"
    // });

    //authService.login(params);

    // just send the email and on the server work out which external provider to use
    authService.login({ login_hint: formData.email });
  };
  const { show, close } = props;
  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          <img
            src={logo}
            className="App-logo mr-3"
            alt="ConfiguredWeb"
            title="ConfiguredWeb"
          />
          {/* <span className="fa fa-user m-2"></span> */}[ Login ]
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          onSubmit={handleSubmit}
          liveValidate
          showErrorList={false}
          FieldTemplate={CustomFieldTemplate}
          noHtml5Validate
        >
          <Button variant="primary" type="submit">
            Submit
          </Button>
          <Button variant="secondary" className="mx-1" onClick={close}>
            Cancel
          </Button>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        If you require assistance, contact Control Gadgets
      </Modal.Footer>
    </Modal>

    // <Container>
    //   <Row>
    //     <Col></Col>
    //     <Col>
    //       <Jumbotron>
    //         <Navbar dark>
    //           <NavbarBrand href="/">
    //             <img
    //               src={logo}
    //               className="App-logo"
    //               alt="ConfiguredWeb"
    //               title="ConfiguredWeb"
    //             />
    //             {/* <span className="App-header-text">by Control Gadgets</span> */}
    //             <NavbarText className="text-muted small">
    //               by Control Gadgets
    //             </NavbarText>
    //           </NavbarBrand>
    //         </Navbar>

    //         <Form
    //           schema={schema}
    //           onSubmit={handleSubmit}
    //           liveValidate
    //           showErrorList={false}
    //         />
    //       </Jumbotron>
    //     </Col>
    //     <Col></Col>
    //   </Row>
    // </Container>
  );
}

export default Login;
