import React, { useEffect, useState } from "react";
import { Container, Row, Col, Badge, Alert, Card } from "react-bootstrap";
// Range Slider
import Slider from "react-rangeslider";
// include the default styles
// import "react-rangeslider/lib/index.css";
// my custom style
import "../../../../src/rangeslider.css";

function CustomRangeWidget(props) {
  console.log("CustomRangeWidget props", props);

  const {
    onChange,
    schema,
    registry,
    formContext,
    id,
    label,
    options,
    required,
    value,
    placeholder,
  } = props;

  const { minimum, maximum, title } = schema;

  const [current, setCurrent] = useState(value);

  const handleOnChange = (i) => {
    setCurrent(i);
  };

  return (
    <Row>
      <Col sm={3}>
        <Card body bg="light" text="dark" className="text-center">
          <h6>{`${current}${
            options?.valueSuffix ? options?.valueSuffix : ""
          }`}</h6>
        </Card>
      </Col>
      <Col>
        <Slider
          value={current}
          orientation="horizontal"
          tooltip={true}
          min={minimum}
          max={maximum}
          onChange={(i) => handleOnChange(i)} // continuous
          onChangeComplete={() => onChange(current)}
        />
      </Col>
    </Row>
  );
}

export default CustomRangeWidget;
