import React from "react";
import { Pagination as BootstrapPagination } from "react-bootstrap";
import _ from "lodash";

function Pagination(props) {
  const { itemsCount, pageSize, onPageChange } = props;
  const pagesCount = Math.ceil(itemsCount / pageSize); // use Math.ceil to convert floating point number to nearest whole

  // create array using lodash
  const pages = _.range(1, pagesCount + 1);

  if (pagesCount === 1) return <>total: {props.itemsCount}</>;

  const { currentPage } = props;

  return (
    <>
      <BootstrapPagination>
        {pagesCount > 3 && (
          <>
            <BootstrapPagination.First onClick={() => onPageChange(1)} />
            <BootstrapPagination.Prev
              onClick={() =>
                onPageChange(currentPage > 1 ? currentPage - 1 : 1)
              }
            />
          </>
        )}
        {pages.map((page) => (
          <BootstrapPagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => onPageChange(page)}
          >
            {page}
          </BootstrapPagination.Item>
        ))}
        {pagesCount > 3 && (
          <>
            <BootstrapPagination.Next
              onClick={() =>
                onPageChange(
                  currentPage < pagesCount ? currentPage + 1 : pagesCount
                )
              }
            />
            <BootstrapPagination.Last
              onClick={() => onPageChange(pagesCount)}
            />
          </>
        )}
      </BootstrapPagination>
      total: {props.itemsCount}
    </>
  );
}

export default Pagination;
