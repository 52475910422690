import Form from "@rjsf/core";
import React from "react";
import CustomFieldTemplate from "../common/CustomFieldsAndTemplates/CustomFieldTemplate";

const schema = {
  type: "object",
  required: ["SOMESHIT"],
  properties: {
    SOMESHIT: {
      type: "number",
    },
    autoObject: {
      type: "object",
    },
  },
};
const uiSchema = {
  autoObject: {
    "ui:field": "cg:autoPopulateObjectField",
  },
};

function AutoPopulateFieldTemplate(props) {
  console.log("autoPop props", props);
  const autoSchema = {
    type: "object",
    required: ["auto"],
    properties: {
      auto: {
        type: "string",
        default: "asd",
      },
    },
  };
  const onError = (errors) => {
    console.log("auto pop has ", errors.length, "errors to fix");
  };
  const onChange = (e) => {
    console.log("auto pop change", e);

    console.log("props", props);

    props.onChange(e.formData);
  };
  let { "ui:field": field, ...uiSchema } = props.uiSchema;
  return (
    <Form
      tagName="div"
      schema={autoSchema}
      uiSchema={uiSchema}
      formData={props.formData}
      onChange={onChange}
      liveValidate
      showErrorList={false}
      onError={onError}
      noHtml5Validate={false}
    >
      <span></span>
    </Form>
  );
}

let fields = {
  "cg:autoPopulateObjectField": (props) => (
    <AutoPopulateFieldTemplate {...props} />
  ),
};
const onSubmit = ({ formData }, e) => console.log("Data submitted: ", formData);
const onError = (errors) => {
  console.log("I have", errors.length, "errors to fix");
};
function autoPopulateTest(props) {
  return (
    <div>
      <Form
        FieldTemplate={CustomFieldTemplate}
        formData={props.formData}
        showErrorList={true}
        noHtml5Validate={false}
        schema={schema}
        uiSchema={uiSchema}
        fields={fields}
        liveValidate
        onSubmit={onSubmit}
        onError={onError}
      />
    </div>
  );
}

export default autoPopulateTest;
