import React, { useEffect, useState } from "react";
import Form from "react-jsonschema-form";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

import _ from "lodash";

import AdminNav from "./adminNav";
import http from "../../../services/APIService";
import JsonEditor from "./../JsonEditor";

import { getObjectAtPath, getPathToJsonObj } from "../../../Utils";
import RelatedList from "./RelatedList";
import CopyToClip from "../../common/CopyToClipboard";

var jp = require("jsonpath");

function FormContainer(props) {
  const id = props.match.params.id;
  const foreignKeyId =
    props.match.params.foreignKeyId !== undefined
      ? props.match.params.foreignKeyId
      : "";
  const log = (type) => console.log.bind(console, type);
  const endpoint = props.name.toLowerCase();

  const [formData, setFormData] = useState({});
  //const jsonSchemaStr = JSON.stringify(props.value);
  // Custom Widget
  // const JsonSchemaWidget = (props) => {
  //   return (
  //     <div className="form-group field field-string">
  //       <input
  //         type="text"
  //         className="form-control"
  //         label="JSON Schema"
  //         value={jsonSchemaStr}
  //         required={props.required}
  //         onChange={(event) => props.onChange(event.target.value)}
  //       />
  //     </div>
  //   );
  // };

  const widgets = {
    jsonEditor: (props) => <JsonEditor height="600px" {...props} />,
    jsonEditorShort: (props) => <JsonEditor height="300px" {...props} />,
  };

  useEffect(() => {
    if (id === "new") {
      // console.log("guess its time to add a new one then?");
      return;
    }

    // get the item from db
    //console.log(user, formData);
    // console.log("get item from backend using id in route - id ", id);

    const fetchData = async () => {
      // console.log("getData ", id);
      try {
        const result = await http.callApi(
          "get",
          `${endpoint}/${foreignKeyId !== "" ? foreignKeyId + "/" : ""}${id}`
        );
        console.log("Item returned successfully ", result.data);

        if (result && result.data) {
          let json = result.data;

          let jsonSchemaObj = getObjectAtPath(json, "jsonSchema");
          let jsonFormDataObj = getObjectAtPath(json, "jsonFormData");
          let jsonUiSchemaObj = getObjectAtPath(json, "jsonUiSchema");

          // show empty string when obj returned is null
          let jsonSchemaStr = "";
          let jsonFormDataStr = "";
          let jsonUiSchemaStr = "";
          // convert to string to be displayed on form
          // schema
          if (
            jsonSchemaObj &&
            typeof jsonSchemaObj === "object" &&
            jsonSchemaObj !== undefined
          ) {
            jsonSchemaStr = JSON.stringify(jsonSchemaObj, undefined, 2);
          }
          _.set(json, getPathToJsonObj(json, "jsonSchema"), jsonSchemaStr);

          // formData
          if (
            jsonFormDataObj &&
            typeof jsonFormDataObj === "object" &&
            jsonFormDataObj !== undefined
          ) {
            jsonFormDataStr = JSON.stringify(jsonFormDataObj, undefined, 2);
          }
          _.set(json, getPathToJsonObj(json, "jsonFormData"), jsonFormDataStr);

          // uiSchema
          if (
            jsonUiSchemaObj &&
            typeof jsonUiSchemaObj === "object" &&
            jsonUiSchemaObj !== undefined
          ) {
            jsonUiSchemaStr = JSON.stringify(jsonUiSchemaObj, undefined, 2);
          }
          _.set(json, getPathToJsonObj(json, "jsonUiSchema"), jsonUiSchemaStr);

          // update the form
          setFormData(json);
        }
      } catch (error) {
        console.log(error);
        //toast.error("errrrr");
      }
    };

    fetchData();
  }, [id, endpoint, foreignKeyId]);

  // const schema = {
  //   title: props.name,
  //   description: "Update the selected item below and then submit",
  //   type: "object",
  //   required: ["name"],
  //   properties: {
  //     name: {
  //       type: "string",
  //       title: "Name",
  //     },
  //   },
  // };
  const handleSubmit = async ({ formData }) => {
    console.log("handleSubmit", formData);
    // conversion
    let json = formData;

    let jsonSchemaStr = getObjectAtPath(json, "jsonSchema");
    let jsonFormDataStr = getObjectAtPath(json, "jsonFormData");
    let jsonUiSchemaStr = getObjectAtPath(json, "jsonUiSchema");

    // convert to string to be displayed on form
    // schema
    if (typeof jsonSchemaStr === "string") {
      const jsonSchemaObj =
        jsonSchemaStr === "" ? {} : JSON.parse(jsonSchemaStr);
      //  console.log("put jsonSchemaObj", jsonSchemaObj);
      let pathToProp = getPathToJsonObj(json, "jsonSchema");
      //console.log("pathToProp", pathToProp);
      _.set(json, pathToProp, jsonSchemaObj);
      console.log("json", json);
    }
    // formData
    if (typeof jsonFormDataStr === "string") {
      const jsonFormDataObj =
        jsonFormDataStr === "" ? {} : JSON.parse(jsonFormDataStr);
      //  console.log("put jsonFormDataObj", jsonFormDataObj);
      let pathToProp = getPathToJsonObj(json, "jsonFormData");
      //console.log("pathToProp", pathToProp);
      _.set(json, pathToProp, jsonFormDataObj);
      //  console.log("json", json);
    }
    // uiSchema
    if (typeof jsonUiSchemaStr === "string") {
      const jsonUiSchemaObj =
        jsonUiSchemaStr === "" ? {} : JSON.parse(jsonUiSchemaStr);
      // console.log("put jsonUiSchemaObj", jsonUiSchemaObj);
      let pathToProp = getPathToJsonObj(json, "jsonUiSchema");
      //console.log("pathToProp", pathToProp);
      _.set(json, pathToProp, jsonUiSchemaObj);
      //  console.log("json", json);
    }

    try {
      if (id === "new") {
        const result = await http.callApi("post", `${endpoint}`, json);
        toast.success("Item added successfully");
        // console.log("Item added successfully " + result);
        //setFormData(result ? result.data : {});
        props.history.push("/admin/" + endpoint);
      } else {
        // console.log(
        //   "putting formData>>>>>>>>>>> ",
        //   JSON.parse(formData.jsonSchema)
        // );
        // console.log(
        //   "putting str formData>>>>>>>>>>> ",
        //   typeof formData.jsonSchema === "string"
        // );

        //formData.jsonSchema = JSON.parse(formData.jsonSchema);
        const result = await http.callApi("put", `${endpoint}/${id}`, json);
        toast.success("Item updated successfully");
        //  console.log("Item updated successfully " + result);
        // //setFormData(result ? result.data : {});
        props.history.push("/admin/" + endpoint);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="display-4 my-0 py-0">
        <span className="fas fa-user-cog mr-2"></span>Administration
      </div>
      <div>
        <AdminNav path={props.match.path} />
      </div>
      <Form
        schema={props.schema}
        uiSchema={props.uiSchema}
        formData={formData}
        onChange={log("changed")}
        onSubmit={handleSubmit}
        onError={log("errors")}
        liveValidate
        showErrorList={false}
        widgets={widgets}
        omitExtraData
      >
        <hr />
        <Button variant="primary" type="submit">
          <span className="fa fa-check-circle mr-2"></span>
          Submit
        </Button>{" "}
        <Button
          variant="secondary"
          onClick={() => props.history.push("/admin/" + endpoint)}
        >
          <span className="fa fa-times-circle mr-2"></span>
          Cancel
        </Button>
      </Form>
      <hr />
      Copy to clipboard <br />
      <CopyToClip
        text={JSON.stringify(props.schema, null, 2)}
        label="Schema"
      />{" "}
      <CopyToClip text={JSON.stringify(formData, null, 2)} label="Form Data" />{" "}
      <hr />
      <RelatedList name={props.relatedListField} data={formData}></RelatedList>
    </div>
  );
}

export default FormContainer;
