import React, { useState, useEffect } from "react";
import Building from "../../Dashboard/Building";
import Pagination from "./Pagination";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  Alert,
  Table,
  Form,
  Button,
  CardColumns,
  Card,
  Breadcrumb,
} from "react-bootstrap";

import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";

import { paginate } from "../../../Utils";

function SmartItemList(props) {
  const [currentPage, setCurrentPage] = useState(undefined);
  const [filteredData, setFilteredData] = useState(undefined);
  const [filterBy, setFilterBy] = useState(undefined);
  const [items, setItems] = useState(undefined);
  const [sortColumn, setSortColumn] = useState({
    path: props.fields[0].fieldAccessor,
    order: "asc",
  });

  const handlePageChange = (page) => {
    console.log("page:", page);
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    setItems(paginate(props.data, currentPage, props.pageSize));
  }, []);

  useEffect(() => {
    if (filterBy) {
      console.log("filterBy", filterBy, props.data);

      if (filterBy === "all") {
        setFilteredData(props.data);
      } else if (filterBy === "none") {
        setFilteredData(props.data.filter((d) => !d.processor));
      } else {
        setFilteredData(
          props.data.filter(
            (d) =>
              d.processor?.processorType.name.toLowerCase() ==
              filterBy.toLowerCase()
          )
        );
      }
    } else {
      setFilteredData(props.data);
    }
  }, [filterBy]);

  useEffect(() => {
    const sorted = _.orderBy(
      filteredData,
      [sortColumn.path],
      [sortColumn.order]
    );
    setItems(paginate(sorted, currentPage, props.pageSize));
  }, [filteredData, currentPage, sortColumn]);

  const handleSort = (path) => {
    console.log("handleSort path", path);

    const col = { ...sortColumn };

    if (col.path === path) col.order = col.order === "asc" ? "desc" : "asc";
    else {
      col.path = path;
      col.order = "asc";
    }

    setSortColumn(col);
  };

  const handleFilterChange = (by) => {
    setFilterBy(by);
  };

  const renderSortIcon = (column) => {
    if (column.toLowerCase() !== sortColumn.path.toLowerCase()) return null;

    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc"></i>;
    if (sortColumn.order === "desc") return <i className="fa fa-sort-desc"></i>;
  };

  const confirmRemoveItem = (item) => {
    console.log("confirm remove item", item);
    confirmAlert({
      title: "Please Confirm",
      message:
        "This will permenently delete the selected " +
        props.listName +
        " (" +
        item[props.nameAccessor] +
        ").\n Do you want to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (typeof props.onRemoveItem === "function") {
              props.onRemoveItem(item);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <>
      <Form>
        <Form.Group controlId="filterBy">
          <Form.Label>Filter By {props.filterByType}</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => handleFilterChange(e.target.value)}
          >
            <option value="all">Show All</option>
            {props.filterByAllowNull && <option value="none">None</option>}
            {props.filterByList?.map((filter, i) => (
              <option value={filter.name}>{filter.name}</option>
            ))}
            {/* 
            <option value="dmps3-300-c">DMPS3-300-C</option> */}
          </Form.Control>
        </Form.Group>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            {props.showId && <th>Id</th>}
            {props.fields.map((field) => {
              return (
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort(field.fieldAccessor)}
                >
                  {field.title} {renderSortIcon(field.fieldAccessor)}
                </th>
              );
            })}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item, i) => {
            return (
              <tr key={i}>
                {props.showId && <td>{item[props.idAccessor]}</td>}
                {props.fields.map((field) => {
                  return (
                    <td>
                      {field.fieldAccessor === props.nameAccessor ? (
                        <Button
                          variant="link"
                          onClick={() =>
                            props.onSelectItem(item[props.idAccessor])
                          }
                        >
                          {_.get(item, field.fieldAccessor, "-")}
                        </Button>
                      ) : (
                        _.get(item, field.fieldAccessor, "-")
                      )}
                    </td>
                  );
                })}

                <td>
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => props.onSelectItem(item[props.idAccessor])}
                  >
                    View
                  </Button>{" "}
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => confirmRemoveItem(item)}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination
        itemsCount={filteredData?.length}
        pageSize={props.pageSize}
        onPageChange={handlePageChange}
        currentPage={currentPage}
      />
    </>
  );
}

export default SmartItemList;
