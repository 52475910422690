import React, { useContext, useState, useEffect } from "react";
import { Button, Navbar, Nav } from "react-bootstrap";
import { toast } from "react-toastify";

import { AuthService } from "../../services/AuthService";

import "./Layout.css";
import logo from "../../configured.svg";

import UserContext from "./../../contexts/userContext";
import Login from "./../login";
import ClientIcon from "./ClientIcon";
function NavMenu() {
  const authService = new AuthService();

  const logout = () => {
    toast.info("Redirecting to Identity Server to LOGOUT");
    authService.logout();
  };

  // login modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [org, setOrg] = useState("");
  const userContext = useContext(UserContext);
  useEffect(() => {
    if (userContext && userContext.profile) {
      setOrg(userContext.profile.organisation);
      //console.log("setorg ", userContext.profile.organisation);
    }
  }, [userContext]);

  return (
    <UserContext.Consumer>
      {/* consumer component expects a function as its child */}
      {(userContext) => (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Navbar.Brand href="/">
            <img
              src={logo}
              className="App-logo"
              alt="ConfiguredWeb"
              title="ConfiguredWeb"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link
                href="http://www.controlgadgets.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                by Control Gadgets
              </Nav.Link>
              <Nav.Link
                href="https://controlgadgets.com.au/enterprise-av-configured.html"
                target="_blank"
              >
                Features
              </Nav.Link>
            </Nav>
            <Nav>
              {!userContext && (
                // <Nav.Link href="/login" className="text-dark">
                //   Login
                // </Nav.Link>
                <React.Fragment>
                  <Button variant="link" onClick={handleShow}>
                    Login
                  </Button>
                  <Login show={show} close={handleClose} />
                </React.Fragment>
              )}
              {userContext && userContext.profile && (
                <Nav.Link
                  href={`${process.env.REACT_APP_AUTHORITY_URL}Identity/Account/Manage`}
                  className="text-dark"
                  //target="_blank"
                  rel="noopener noreferrer"
                >
                  <ClientIcon org={org} />
                  {userContext.profile.name}
                </Nav.Link>
              )}
              {userContext && <Nav.Link onClick={logout}>Logout</Nav.Link>}
              <Nav.Link href="/admin">Admin</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </UserContext.Consumer>
  );
}

export default NavMenu;
