import React from "react";

import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  Navbar,
  Jumbotron,
} from "react-bootstrap";

import logo from "../configured.svg";
import TipSupport from "./common/tipSupport";
import Footer from "./common/Footer";
let pageTitle = "Page Not Found";

export const NotFound = (props) => {
  const displayName = NotFound.name;

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col className="mb-2">
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
              <Navbar.Brand href="/">
                <img
                  src={logo}
                  className="App-logo"
                  alt="ConfiguredWeb"
                  title="ConfiguredWeb"
                />
              </Navbar.Brand>
              <Nav className="mr-auto">
                <Nav.Link
                  href="http://www.controlgadgets.com.au"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  by Control Gadgets
                </Nav.Link>
              </Nav>
            </Navbar>
          </Col>
        </Row>

        <Row>
          <Col sm="6">
            <Jumbotron>
              <div className="display-4">
                <span className="fa fa-compass mr-4"></span>
                {pageTitle}
              </div>
              <div>The requested page could not be found</div>
              <Button size="sm" onClick={() => window.history.back()}>
                Go Back
              </Button>
            </Jumbotron>
          </Col>
          <Col sm="2">
            <TipSupport />
          </Col>
        </Row>
      </Container>

      <Footer />
    </React.Fragment>
  );
};
