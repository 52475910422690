import React, { useEffect, useState } from "react";

// Context

// packages
import { withRouter, Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer, toast, Flip } from "react-toastify";

// Services
import { AuthService } from "./services/AuthService";

// components
import ProtectedRoute from "./components/common/protectedRoute";
import PageLayout from "./components/common/PageLayout";
// route components
import Home from "./components/Home";
import ControlSystem from "./components/ControlSystem/ControlSystem";

// styles
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

function ConfiguredApp() {
  const [user, setUser] = useState(undefined);
  const [controlSystem, setControlSystem] = useState(undefined);

  return (
    <PageLayout>
      <ToastContainer
        position="top-right"
        autoClose={1200}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        transition={Flip}
      />
      <Switch>
        <ProtectedRoute
          path="/controlsystem/:id"
          component={ControlSystem}
          //requiredRoleClaim="av.manager"
        />
        <Route path="/" exact component={Home} />
        <Redirect to="/not-found" />
      </Switch>
    </PageLayout>
  );
}
export default withRouter(ConfiguredApp);
