import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";

import _ from "lodash";

import http from "../../services/APIService";

import DynamicForm from "../common/DynamicForm";

import { toast } from "react-toastify";

import LoadingSpinner from "../common/LoadingSpinner";
import ErrorBoundary from "../common/ErrorBoundary";
import { First } from "react-bootstrap/esm/PageItem";
import FirstLoadAlert from "./FirstLoadAlert";
import CustomForm from "../common/CustomForm";

const queryString = require("query-string");
const Mustache = require("mustache");

function ConfigItemsSection(props) {
  let history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [endpoint, setEndpoint] = useState(undefined);
  const [section, setSection] = useState({});

  const [schemas, setSchemas] = useState(props.schemas);
  const [schema, setSchema] = useState(undefined);
  const [uiSchema, setUISchema] = useState(undefined);
  const [formData, setFormData] = useState(undefined);
  const [schemaRules, setSchemaRules] = useState(undefined);

  const [result, setResult] = useState(null);

  let defaultFormData = {};

  const [controlSystem, setControlSystem] = useState(props.controlSystem);
  const [configuration, setConfiguration] = useState(undefined);

  const [firstLoad, setFirstLoad] = useState(false);

  const [extraErrors, setExtraErrors] = useState(undefined);

  let submitted = false;

  console.log("ConfigItemsSection props", props);

  const getRemoteSchemas = async () => {
    // get current section
    if (params.sectionTypeId !== undefined) {
      console.log("get schemas");

      console.log("params :> ", params);
      setSection(undefined);
      const sectionResult = await http.callApi(
        "get",
        `configSectionType/${params.id}/${params.sectionTypeId}`
      );

      if (sectionResult.data) {
        console.log("sectionResult.data :> ", sectionResult);
        setSection(sectionResult.data);

        let sectionName = sectionResult.data.name;
        let sectionSchemaName = sectionName?.split(" ").join(""); // remove spaces

        // when used as a modal for adding new item, using a different schema
        if (props.addNew) sectionSchemaName += "AddNew";

        // endpoint
        setEndpoint(sectionResult.data.endpoint);

        console.log("schema", schema);
        // only using local schema json files
        setSchema(schemas[sectionSchemaName]);

        const combinedUiSchema = {
          ...schemas[sectionSchemaName + "Ui"], // local
          ...sectionResult.data.jsonDefaultUISchema, // remote specific to current organisation
        };

        setUISchema(combinedUiSchema);

        // defaultFormData = !_.isEmpty(sectionResult.data.jsonDefaultFormData)
        //   ? sectionResult.data.jsonDefaultFormData // remote default form data specific to organisation
        //   : props.defaultFormData ?? // or use local
        //     {};

        // var propFormData = { ...schemas[sectionSchemaName + "Defaults"] } ?? {};
        // var remoteFormData =
        //   { ...sectionResult.data.jsonDefaultFormData } ?? {};
        // console.log("propFOrmData defaults:", propFormData);
        // console.log("remoteFormData default:", remoteFormData);

        // merge the local with remote form data json (remote overrides any matching objects)
        // NOTE: nested objects will completely be overridden so if declared then all the properties within it need a value assigned (ie it wont use the local value if not defined, will be blank)
        let schemaDefaults = { ...schemas[sectionSchemaName + "Defaults"] };
        defaultFormData = Object.assign(
          schemaDefaults ?? {}, // target
          sectionResult.data.jsonDefaultFormData ?? {} // source (will merge with target and override any matching keys)
        );

        console.log("defaultFormData MERGED", defaultFormData);

        getFormData(sectionResult.data.endpoint, sectionSchemaName);

        // initialise with local rules
        const rulesArray = schemas[sectionSchemaName + "Rules"] ?? [];

        console.log(
          "ConfigItemsSection.jsx - props rules:",
          rulesArray,
          "remote rules:",
          sectionResult.data.schemaRules
        );
        // add remote rules
        if (sectionResult.data.schemaRules) {
          sectionResult.data.schemaRules.map((rule, index) => {
            console.log("remote rule", index, rule);

            rulesArray.push(rule);
          });
        }

        setSchemaRules(rulesArray ?? []);
      }
    } else {
      setSection(null);
    }
  };

  useEffect(() => {
    console.log("props.schemas changed", props.schemas);
    setSchemas(props.schemas);
  }, [props.schemas]);

  useEffect(() => {
    console.log("schemas changed", schemas);
  }, [schemas]);

  const getFormData = async (endpoint, sectionSchemaName) => {
    if (endpoint === undefined) {
      console.log("endpoint", endpoint);
      return;
    }
    console.log("getFormData()", section, "endpoint>", endpoint);

    console.log("ConfigItemsSection controlSystem", controlSystem);
    console.log("ConfigItemsSection configuration", configuration);

    setFormData(undefined);

    let controllerPath = `${endpoint}/${params.id}/${params.configurationId}`;

    if (params.itemId === "new" || props.addNew) {
      setFirstLoad(true);
      defaultFormData.controlSystem = controlSystem;
      defaultFormData.configuration = configuration;
      defaultFormData.configurationId = configuration.configurationId;
      console.log("props.schemas", props.schemas);
      setFormData(defaultFormData);
    } else {
      if (params.itemId !== undefined) controllerPath += `/${params.itemId}`;

      // go get it from backend
      try {
        let result = await http.callApi(
          "get",
          controllerPath + "?configSectionTypeId=" + params.sectionTypeId
        );

        console.log("result :>:>:>:>", result);

        setResult(result);

        if (result) {
          console.log(
            "getFormData() result.Data ",
            result,
            result !== undefined ? result.data : "NULL"
          );
          let formData = { ...result.data };

          // no content 204 returned - no data added yet
          if (result.status == 204) {
            console.log("no form data found for this item");
            formData = defaultFormData;
            setFirstLoad(true);
          } else setFirstLoad(false);

          formData.controlSystem = controlSystem;
          formData.configuration = configuration;
          formData.configurationId = configuration.configurationId;

          // merge defaultFormData (which is already a merge of remote defaults and local defaults)
          // with formData (which could be empty - a 204 NoContent is return if this is the case)
          // actual formData will override defaultFormData if there are any matching keys (with values)
          // fields with null values are not included in the http get response (set as ignoreNullValues in API backend)
          let mergedFormData = Object.assign(
            defaultFormData ?? {}, // target
            formData ?? {} // source (will merge with target and override any matching keys)
          );

          console.log("merged form data with defaults", mergedFormData);
          //setFormData(mergedFormData);

          // note/warning:: formData that is merged with defaults may be deceiving because the form will be populated for defaults even though
          // some fields may not be saved in the database - may be better to load one or the other - actual form data from db or the default form data if no entry in db?

          console.log("configItem formData", formData);

          setFormData(formData); // yeh going to load one or the other
        } else {
          console.log(">>> error");
        }
      } catch (error) {
        console.log("returned error", error);
      }
    }
  };

  // get configuration from props
  useEffect(() => {
    console.log("props.configuration", props.configuration);
    if (props.configuration) {
      setConfiguration(props.configuration);
    }
  }, [props.configuration, submitted]);

  // get schemas, rules from server (organisation dependent)
  useEffect(() => {
    console.log(
      "SECTION CHANGED and awaiting configuration",
      params.sectionTypeId,
      configuration
    );
    if (configuration && schemas) getRemoteSchemas();
  }, [configuration, schemas]);

  // handlers
  const handleSubmit = async (e) => {
    if (typeof props.handleSubmit === "function") {
      props.handleSubmit(e);
      return;
    }

    console.log("handleSubmit()  formData (extra data omitted)", e.formData);

    if (params.itemId === "new" || props.addNew) {
      // add
      let result = await http.callApi(
        "post",
        `${endpoint}/${params.id}/${params.configurationId}`,
        e.formData
      );
      console.log("result", result);
      if (result) {
        toast.success("Item added successfully");
      }
      if (props.returnPath) {
        history.push(props.returnPath);
      }
      // close modal
      if (props.addNew && typeof props.onSuccess === "function") {
        props.onSuccess();
      }
    } else {
      // update
      let controllerPath = `${endpoint}/${params.id}/${params.configurationId}`;
      if (params.itemId !== undefined) controllerPath += `/${params.itemId}`;
      var result;
      try {
        result = await http.callApi("put", controllerPath, e.formData);
      } catch (exceptionResponse) {
        console.log("http call rejected", exceptionResponse);
        exceptionResponse.data &&
          setExtraErrors({
            [exceptionResponse.data?.field]: {
              __errors: [[exceptionResponse.data.error]],
            },
          });
      }
      console.log("result", result);
      if (result) {
        switch (result.status) {
          case 200: // ok
          case 204: // no content
            console.log("204 NO CONTENT()");
            toast.success(section.name + " updated successfully");

            break;
          case 201:
            toast.success(section.name + " created successfully");

            break;

          default:
            toast(section.name + " response code: " + result.status);
            break;
        }

        // RJSF omits extra formData when submitting ie controlsystem and configuration, therefore rules throw exception
        // i have an ErrorBoundary around FormWithConditionals in DynamicForm
        // so this allows the app to continue, then i reload FormData
        // save reloading the window and is much nicer
        getFormData(endpoint);
        //window.location.reload();
      }
    }

    if (typeof props.performUpdate === "function") {
      props.performUpdate();
    }
  };

  const handleCancel = () => {
    if (typeof props.onCancel === "function") {
      props.onCancel();
    } else {
      props.history.push(props.returnPath);
    }
  };

  // const handleFormChange = async (e) => {
  //   console.log("form changed", e);
  //   setFormData(e.formData);
  // };

  // const handleFieldChange = async (name, fieldFormData) => {
  //   console.log(
  //     "configItemsSection.jsx >>>>>>> field changed : ",
  //     name,
  //     fieldFormData
  //   );
  // };
  const reloadFormData = () => {
    getFormData();
  };
  return (
    <div>
      {/* {console.log(
        "<>ConfigItemsSection<>",
        schema,
        uiSchema,
        formData,
        schemaRules
      )} */}
      {props.links && (
        <>
          {props.links.map((link, index) => {
            return (
              <Link key={index} to={link.path} className="mr-4">
                <span className="fa fa-edit mr-2"></span>
                {link.title}
              </Link>
            );
          })}
          <hr />
        </>
      )}

      {!props.addNew && <FirstLoadAlert state={firstLoad} />}
      {!_.isEmpty(schema) && uiSchema && formData && props.controlSystem ? (
        <>
          <CustomForm
            schema={schema}
            uiSchema={uiSchema}
            formData={formData}
            controlSystem={props.controlSystem}
            configuration={props.configuration}
            handleSubmit={handleSubmit}
            liveValidate
            handleCancel={handleCancel}
            showCopyButtons
            rules={schemaRules}
            handleReloadFormData={reloadFormData}
            extraErrors={extraErrors}
            handleFormChange={props.handleFormChange}
          />
        </>
      ) : (
        <>
          {/* {console.log("schema", schema)}
          {console.log("uiSchema", uiSchema)}
          {console.log("formData", formData)}
          {console.log("schemaRules", schemaRules)} */}
          <LoadingSpinner
            title="Loading Form Data"
            timeout={500}
            result={result}
            data={formData}
          />
        </>
      )}
    </div>
  );
}

export default ConfigItemsSection;
