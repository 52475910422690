import React, { useState, useEffect } from "react";

import applyRules from "react-jsonschema-form-conditionals";
import Engine from "json-rules-engine-simplified";
import Form from "react-jsonschema-form";

import { Button } from "react-bootstrap";

let schema1 = {
  title: "A registration form 1",
  description: "A simple form example.",
  type: "object",
  required: ["firstName"],
  properties: {
    firstName: {
      type: "string",
      title: "First name",
    },
    lastName: {
      type: "string",
      title: "Last name",
    },
    password: {
      type: "string",
      title: "Password",
      minLength: 3,
    },
  },
};

let schema2 = {
  title: "Conditionals Test",
  description: "Enter a first name to reveal last name and password.",
  type: "object",
  required: ["firstName"],
  properties: {
    firstName: {
      type: "string",
      title: "First name",
    },
    lastName: {
      type: "string",
      title: "Last name",
    },
    password: {
      type: "string",
      title: "Password",
      minLength: 3,
    },
  },
};

let rules = [
  {
    conditions: {
      firstName: "empty",
    },
    event: {
      type: "remove",
      params: {
        field: ["password", "lastName"],
      },
    },
  },
];

export default function ConditionalsTestAdvanced(props) {
  let uiSchema = {};
  const [schema, setSchema] = useState(schema1);

  //let FormWithConditionals = Form;
  let rulesEngine = new Engine();
  let FormWithConditionals = applyRules(
    schema2,
    uiSchema,
    [],
    rulesEngine
  )(Form);

  rules.map((rule, i) => {
    // console.log("adding rule ", i, rule);
    rulesEngine.addRule(rule);
  });
  //let FormWithConditionals = Form;

  return (
    <div>
      <Button onClick={() => setSchema(schema2)}>Update</Button>
      <FormWithConditionals schema={schema} uiSchema={uiSchema} />
    </div>
  );
}
