import { Log, UserManager } from "oidc-client";

export class AuthService {
  static userManager = null;

  constructor() {
    const settings = {
      authority: process.env.REACT_APP_AUTHORITY_URL,
      client_id: process.env.REACT_APP_AUTHORITY_CLIENT_ID,
      redirect_uri: `${process.env.REACT_APP_AUTHORITY_CLIENT_BASEADDRESS}signin-callback.html`,
      silent_redirect_uri: `${process.env.REACT_APP_AUTHORITY_CLIENT_BASEADDRESS}silent-renew.html`,
      // tslint:disable-next-line:object-literal-sort-keys
      post_logout_redirect_uri:
        process.env.REACT_APP_AUTHORITY_CLIENT_BASEADDRESS,
      response_type: "code",
      scope: process.env.REACT_APP_AUTHORITY_CLIENT_SCOPE,
    };
    this.userManager = new UserManager(settings);
    Log.logger = console;
    Log.level = Log.INFO;
  }

  async getUser() {
    return await this.userManager.getUser();
  }

  login(optional_params) {
    //console.log("authService login", this.userManager);
    //console.log("authService login with optional_params: ", optional_params);
    return this.userManager.signinRedirect(optional_params);
  }

  renewToken() {
    return this.userManager.signinSilent();
  }

  logout() {
    return this.userManager.signoutRedirect();
  }

  removeCurrentUser() {
    return this.userManager.removeUser();
  }
}
