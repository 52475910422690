import React, { useState } from "react";
import { Alert, Container, Row, Col } from "react-bootstrap";

function ErrorListTemplate(props) {
  console.log("ErrorListTemplate props", props);
  const { errors } = props;
  const [show, setShow] = useState(false);
  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
          <span className="fa fa-exclamation-triangle mr-2"></span>Errors
        </Alert.Heading>
        <ul>
          {errors.map((error, index) => (
            <li key={error.property + "_" + index.toString()}>{error.stack}</li>
          ))}
        </ul>
        {/* <hr />
          <i>Errors must be resolved before the form can be submitted</i> */}
      </Alert>
    );
  }
  return (
    <Alert variant="danger">
      <Container fluid>
        <Row>
          <Col>
            <span className="fa fa-exclamation-triangle mr-2"></span>Form has
            Errors
          </Col>
          <Col md="auto">
            <Alert.Link variant="danger" onClick={() => setShow(true)}>
              Show
            </Alert.Link>
          </Col>
        </Row>
      </Container>
    </Alert>
  );
}

export default ErrorListTemplate;
