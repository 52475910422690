import React, { useEffect, useState } from "react";
import {
  Route,
  Link,
  useLocation,
  useParams,
  useHistory,
  useRouteMatch,
  Switch,
} from "react-router-dom";
import { Alert, Badge, Nav } from "react-bootstrap";
import _ from "lodash";
import http from "../../services/APIService";
import ConfigItemsSection from "./ConfigItemsSection";
import { toast } from "react-toastify";

function ConfigItemsSection_VideoZone(props) {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  console.log("ConfigItemsSection_VideoZone props", props);

  const [videoZone, setVideoZone] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [tab, setTab] = useState("general");
  const [schemas, setSchemas] = useState(undefined);

  async function getVideoZone() {
    const result = await http.callApi(
      "get",
      `videoZone/${params.id}/${params.configurationId}/${params.itemId}`
    );
    console.log("VideoZone DATA", result.data);
    if (result) {
      setVideoZone(result.data);
    }
  }

  useEffect(() => {
    getVideoZone();
  }, [params.itemId]);

  async function getData() {
    const result = await http.callApi(
      "get",
      `videoZoneType/${videoZone.videoZoneTypeId}`
    );
    console.log("VideoZoneType DATA", result.data);
    if (result) {
      setData(result.data);
    }
  }

  useEffect(() => {
    if (videoZone) {
      getData();
    }
  }, [videoZone]);

  const getVariant = () => {
    let variant = "";
    switch (data.name.toLowerCase()) {
      default:
      case "standard":
        variant = "info";
        break;
      case "fill":
        variant = "warning";
        break;
      case "collaboration pod":
        variant = "success";
        break;
      case "video zone with source":
      case "current source preview":
      case "video processor":
        variant = "secondary";
        break;
      case "fixed route":
      case "standalone":
        variant = "dark";
        break;
    }
    return variant;
  };

  let baseAddress = `/controlSystem/${params.id}/configuration/${params.configurationId}/section/${params.sectionTypeId}/videoZone/${params.itemId}`;

  useEffect(() => {
    let newSchema = {};
    setSchemas(undefined);

    if (data && !data.jsonSchema) return;

    if (tab === "type") {
      let VideoZones = data.jsonSchema;
      let VideoZonesUi = data.uiSchema;

      const typeSchemas = { VideoZones, VideoZonesUi };

      newSchema = _.cloneDeep(typeSchemas);
    } else {
      newSchema = props.schemas && _.cloneDeep(props.schemas);
    }
    if (newSchema) setSchemas(newSchema);
  }, [data, tab, props.schemas]);

  const handleSubmit = async (e) => {
    console.log("ConfigItemsSection_VideoZone handleSubmit", e.formData);

    for (let prop in e.formData) {
      console.log(prop, e.formData[prop]);
      if (prop === "videoZoneId") continue;

      let value = e.formData[prop];
      let field = prop;
      let patchData = [
        {
          value: value,
          path: `/${field}`,
          op: "replace",
        },
      ];

      const result = await http.callApi(
        "patch",
        `videoZone/${params.itemId}`,
        patchData
      );
      if (result) {
        toast.success(props.name + " updated successfully");
        console.log("result data ::: ", result);
      }
    }
  };

  return (
    <div>
      {data && (
        <>
          <h5>
            <Badge variant={getVariant()}>Type: {data.name}</Badge>
          </h5>

          {/* <Alert variant={getVariant()} size="sm">
            Video Zone Type: <b>{data.name}</b>
          </Alert> */}
        </>
      )}
      {!schemas ? (
        <>
          <Nav variant="tabs" defaultActiveKey="general">
            <Nav.Item>
              <Nav.Link disabled eventKey="general">
                General
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <ConfigItemsSection {...props} performUpdate={getVideoZone} />
        </>
      ) : (
        <>
          <Nav variant="tabs" defaultActiveKey={tab}>
            <Nav.Item>
              <Nav.Link eventKey="general" onClick={() => setTab("general")}>
                General
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="type" onClick={() => setTab("type")}>
                {data?.name} Options
              </Nav.Link>
            </Nav.Item>
          </Nav>

          {tab && schemas ? (
            tab !== "general" ? (
              <ConfigItemsSection
                {...props}
                schemas={schemas}
                handleSubmit={handleSubmit}
              />
            ) : (
              <ConfigItemsSection {...props} performUpdate={getVideoZone} />
            )
          ) : (
            <>loading section</>
          )}
        </>
      )}
    </div>
  );
}

export default ConfigItemsSection_VideoZone;
