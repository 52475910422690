import React, { useEffect, useState } from "react";
import Form from "@rjsf/core";
import DynamicSchema from "./dynamicSchema";

function TestDynamicSchema(props) {
  const [schema, setSchema] = useState(undefined);

  const handleUpdate = (newSchema) => {
    console.log("schema", newSchema);
    setSchema(newSchema);
  };

  return (
    <div>
      Code
      {schema && <Form schema={schema} />}
      <DynamicSchema onUpdate={handleUpdate} />
    </div>
  );
}

export default TestDynamicSchema;
