import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import AdminNav from "./common/adminNav";

export class Admin extends Component {
  static displayName = Admin.name;

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="display-4 my-0 py-0">
          <span className="fas fa-user-cog mr-2"></span>Administration
        </div>
        <div>
          <AdminNav path={this.props.match.path} />
        </div>

        <Alert variant="primary">
          <span className="fa fa-info-circle mr-2"></span>Choose an option from
          the menu above to continue
        </Alert>
      </React.Fragment>
    );
  }
}
