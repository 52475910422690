import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";

import applyRules from "react-jsonschema-form-conditionals";
import Engine from "json-rules-engine-simplified";
import predicate from "predicate";
import Form from "react-jsonschema-form";

import _ from "lodash";
import { toast } from "react-toastify";

import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import SchemaField from "react-jsonschema-form/lib/components/fields/SchemaField";

import copy from "copy-to-clipboard";
import CopyToClip from "./CopyToClipboard";
import LoadingSpinner from "./LoadingSpinner";

import ErrorBoundary from "./ErrorBoundary";
import isDev from "./helpers/DevDetect";

import CustomFieldTemplate from "./CustomFieldsAndTemplates/CustomFieldTemplate";
import ArrayFieldTemplate from "./CustomFieldsAndTemplates/ArrayFieldTemplate";

import AutoPopulateObjectFieldTemplate from "./CustomFieldsAndTemplates/AutoPopulateObjectFieldTemplate";
import AutoPopulateSelectFieldTemplate from "./CustomFieldsAndTemplates/AutoPopulateSelectFieldTemplate";
import AutoPopulateArrayFieldTemplate from "./CustomFieldsAndTemplates/AutoPopulateArrayFieldTemplate";
//import CustomSchemaField from "./CustomFieldsAndTemplates/CustomSchemaField";

import JsonEditor from "../admin/JsonEditor";
import ErrorListTemplate from "./CustomFieldsAndTemplates/ErrorListTemplate";
import AddNewProcesorSelectFieldWithModal from "./CustomFieldsAndTemplates/AddNewProcesorSelectFieldWithModal";

import DefaultFormDataWidget from "./CustomFieldsAndTemplates/DefaultFormDataWidget";
import AutoPopulateSchemaDependencyField from "./CustomFieldsAndTemplates/AutoPopulateSchemaDependencyField";
import AutoPopulateOneOfFieldTemplate from "./CustomFieldsAndTemplates/AutoPopulateOneOfFieldTemplate";

import CustomRangeWidget from "./CustomFieldsAndTemplates/CustomRangeWidget";

var jp = require("jsonpath");
var flatten = require("flat");
const Mustache = require("mustache");

var FormWithConditionals = null;
predicate._isEmpty = predicate.curry((val, flag) => {
  return _.isEmpty(val) === flag;
});

function CustomForm(props) {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const [rules, setRules] = useState(undefined);

  let propsAndParams = { ...props };
  propsAndParams.params = params;
  // let rules = props.rules
  //   ? JSON.parse(
  //       Mustache.render(JSON.stringify(props.rules), propsAndParams),
  //       undefined,
  //       2
  //     )
  //   : [];

  let mustachedSchema = JSON.parse(
    Mustache.render(JSON.stringify(_.cloneDeep(props.schema)), propsAndParams),
    undefined,
    2
  );

  console.log(
    "CustomForm props.schema",
    props.schema,
    "MUSTACHED SCHEMA",
    mustachedSchema,
    "CustomForm props.formData",
    props.formData
  );

  console.log("customForm props changed", props);

  let mustachedUISchema = _.cloneDeep(props.uiSchema);
  // !!! something going on here if i mustache the ui schema the second time i open port device modal form isnt loaded fully
  // do i need to mustache uiSchema, does it need dyanmic vars imbedded? probably yes
  // try {
  //   mustachedUISchema = JSON.parse(
  //     Mustache.render(JSON.stringify(props.uiSchema), propsAndParams),
  //     undefined,
  //     2
  //   );
  // } catch (ex) {
  //   console.log("mustache failed on uiSchema", props.uiSchema);
  //   console.error("Mustache failed on uiSchema", ex);
  //   mustachedUISchema = props.uiSchema;
  // }
  console.log("mustachedUISchema", mustachedUISchema);

  const [schema, setSchema] = useState(mustachedSchema);
  const [uiSchema, setUISchema] = useState(mustachedUISchema);
  // formData is only set when form is submitted (or copy button pressed)
  const [formData, setFormData] = useState(props.formData);
  // liveformData stores the changed formData object and sets formData when copy button pressed.
  // cant set formData when formChanges - the form looses focus etc when it reloads
  let liveformData = props.formData !== undefined ? props.formData : {};
  // when formData is set/ changed update the copytoClip text
  let rootIdSchema = {};
  const [extraErrors, setExtraErrors] = useState(props.extraErrors); // set as prop value first then serverError can overwrite it
  const [loading, setLoading] = useState("busy");

  // fieldErrors from autopopulate field templates that have a embeded form, any errors passed up to parent form
  const [fieldErrors, setFieldErrors] = useState([]);

  // useEffect(() => {
  //   console.log("props.schema", props.schema);
  //   let mustachedSchema = JSON.parse(
  //     Mustache.render(JSON.stringify(props.schema), propsAndParams),
  //     undefined,
  //     2
  //   );
  //   console.log("mustachedSchema", mustachedSchema);
  //   console.log("propsAndParams", propsAndParams);

  //   if (_.isEmpty(mustachedUISchema)) mustachedUISchema = props.uiSchema;

  //   setSchema(_.isEmpty(rules) ? mustachedSchema : props.schema);
  //   setUISchema(mustachedUISchema ?? props.uiSchema);
  // }, []); // load once on first render

  useEffect(() => {
    //if (!_.isEmpty(props.formData))
    setFormData(props.formData); // do want to set if empty, ie controlSystem/new so the form is cleared ready to add a new one
  }, [props.formData]);

  useEffect(() => {
    console.log("CustomForm ExtraErrors changed!", props.extraErrors);
    setExtraErrors(props.extraErrors);
  }, [props.extraErrors]);

  useEffect(() => {
    if (props.rules) {
      setRules(
        JSON.parse(
          Mustache.render(JSON.stringify(props.rules), propsAndParams),
          undefined,
          2
        )
      );
    }
  }, [props.rules]);

  useEffect(() => {
    console.log("schema updated", schema);
    if (!_.isEmpty(rules) && schema) {
      let rulesEngine = new Engine();
      FormWithConditionals = applyRules(
        schema,
        uiSchema,
        [],
        rulesEngine
      )(Form);

      rules.map((rule, i) => {
        console.log("CustomForm adding rule ", i, rule);
        if (rule !== null && !_.isEmpty(rule)) rulesEngine.addRule(rule);
      });
    } else {
      FormWithConditionals = Form; // pass props below
    }
  }, [rules]);

  // -----------------------------------------------------
  // EVENT HANDLERS
  // -----------------------------------------------------

  // ******************************
  // Function: handleFormChange()
  // called when anything on the form changes
  // if a function passed in via props this will be called and passed the formData
  // also sets formData state object
  // ********************************
  const handleFormChange = (e) => {
    console.log("+++++++++++++++ FORM CHANGED ::>", e);
    // pass back to parent
    if (
      props.handleFormChange !== undefined &&
      typeof props.handleFormChange === "function"
    ) {
      props.handleFormChange(e.formData);
    }
    // if  you set formData on every formChange then the form will loose focus when it reloads
    // now done only on submit
    //setFormData(e.formData); // cant do this
    liveformData = e.formData; // update this local var here and when copy button is pressed, then setFormData to this value
  };

  // ******************************
  // Function: handleFieldChange()
  // called when any field changes
  // if a function passed in via props this will be called
  // and passed the name of the field that changed and its current value
  // this function can then perform some function based on the specific field that changed
  // ********************************
  const handleFieldChange = async (name, fieldFormData, idSchema) => {
    console.log("CustomForm.handleFieldChange()", name, idSchema.$id);

    // if (typeof fieldFormData === "object") {
    //   return;
    // }

    // if (
    //   name.toLowerCase().includes("json") ||
    //   name.toLowerCase().includes("schema")
    // )
    //   return;

    // pass up to the parent for local handling
    if (
      props.handleFieldChange !== undefined &&
      typeof props.handleFieldChange === "function"
    ) {
      props.handleFieldChange(name, fieldFormData, idSchema);
    }
  };
  function transformErrors(errors) {
    console.log("CUSTOM FORM >>> TRANSFORM ERRORS", errors);
    // console.log(
    //   "CUSTOM FORM >>> TRANSFORM ERRORS --> current fieldErrors",
    //   fieldErrors
    // );

    let newError = {
      name: "required",
      property: ".dmInputCardType.lastName",
      message: "LAST NAME is a required property",
      params: {
        missingProperty: "dmInputCardType.lastName",
      },
      stack: ".dmInputCardType.lastName is a required property",
    };
    //errors.push(newError);

    // fieldErrors.map((err) => {
    //   errors.push(err);
    // });

    //errors.push(fieldErrors);
    //console.log("CUSTOM FORM >>> TRANSFORM ERRORS 2 ", errors);
    return errors;

    // return errors.map((error) => {
    //   console.log("transformErrors() error", error);
    //   if (error.name === "pattern") {
    //     error.message = "Only digits are allowed";
    //   }
    //   return error;
    // });
  }
  const validateAlt = (formData, errors) => {
    console.log("CUSTOM FORM >>> VALIDATE", errors);
    // console.log("extraErrors", extraErrors);
    //errors.asd.addError("FUCK off");
    //errors.__errors.push(fieldErrors);
    //console.log("validate ", errors.__errors);
    // cant do this
    //errors.dmInputCardType.addError("FUCK off");

    return errors;
  };
  // -----------------------------
  // validation
  const findPropAndValidate = (
    errors,
    formData,
    flatFormData,
    prop,
    occupiedList
  ) => {
    console.log("findPropAndValidate()", prop, formData);
    return errors;
    // get all props with [prop] in its name (by flattening the json)
    let ipidProps = Object.keys(flatFormData).filter((key) => key === prop);
    // using this list iterate through the prop names and pass to the validate function

    ipidProps.map((i) => {
      //console.log("item", i);
      let propName = i.split(".").pop();
      console.log("validating propName", propName);

      validateOccupiedItem(formData, errors, propName, occupiedList);
    });
  };

  const validate = (formData, errors) => {
    console.log("validate() errors ", errors);

    console.log("validate() ", formData, errors, props.controlSystem);

    //console.log("props", props);
    // add custom errors
    // errors.name.addError("custom error");
    // !cant do async calls from within this validate() method!
    // validate against data in controlsystem locally
    // if we dont have it passed in props, return
    if (_.isEmpty(props.controlSystem)) return errors;
    //if (_.isEmpty(props.name)) return errors; //

    // remove controlSystem object from json formData
    let trimmedFormData = { ...formData };
    _.unset(trimmedFormData, "controlSystem", null);
    _.unset(trimmedFormData, "configuration", null);
    // console.log("trimmed formData", trimmedFormData);
    // console.log("flat formData", flatten(trimmedFormData));

    const flatFormData = flatten(trimmedFormData);
    // console.log("flatFormData keys", Object.keys(trimmedFormData));

    // ipId
    findPropAndValidate(
      errors,
      formData,
      flatFormData,
      "ipId",
      props.controlSystem.occupiedIpIds
    );

    return errors;

    // cresnetId
    findPropAndValidate(
      errors,
      formData,
      flatFormData,
      "cresnetId",
      props.controlSystem.occupiedCresnetIds
    );
    // processorComPort
    findPropAndValidate(
      errors,
      formData,
      flatFormData,
      "processorComPort",
      props.controlSystem.occupiedProcessorComPorts
    );
    // processorIRSerialPort
    findPropAndValidate(
      errors,
      formData,
      flatFormData,
      "processorIRSerialPort",
      props.controlSystem.occupiedProcessorIRSerialPorts
    );
    // processorRelayPort
    findPropAndValidate(
      errors,
      formData,
      flatFormData,
      "processorRelayPort",
      props.controlSystem.occupiedProcessorRelayPorts
    );
    // processorIOPort
    findPropAndValidate(
      errors,
      formData,
      flatFormData,
      "processorIOPort",
      props.controlSystem.occupiedProcessorIOPorts
    );
    // --------------------
    // expansion device ports
    // --------------------
    // expansionComPort
    findPropAndValidate(
      errors,
      formData,
      flatFormData,
      "expansionComPort",
      props.controlSystem.occupiedExpansionComPorts
    );
    // expansionIRPort
    findPropAndValidate(
      errors,
      formData,
      flatFormData,
      "expansionIRPort",
      props.controlSystem.occupiedExpansionIRPorts
    );
    // expansionRelayPort
    findPropAndValidate(
      errors,
      formData,
      flatFormData,
      "expansionRelayPort",
      props.controlSystem.occupiedExpansionRelayPorts
    );
    // expansionIOPort
    findPropAndValidate(
      errors,
      formData,
      flatFormData,
      "expansionIOPort",
      props.controlSystem.occupiedExpansionIOPorts
    );

    // then must return errors
    return errors;
  };

  const validateOccupiedItem = (formData, errors, field, occupiedItems) => {
    console.log("validateOccupiedItem", field, formData);
    if (_.isEmpty(occupiedItems)) return;

    let nodes = jp.nodes(formData, "$.." + field);

    for (let node of nodes) {
      console.log("node", node);

      let result = _.find(occupiedItems, function (o) {
        console.log("occupiedItem: ", o.occupiedByDeviceId);
        console.log("formData", formData);
        if (_.isEmpty(o.entityName)) return;
        let deviceIdName = _.camelCase(o.entityName + "Id");
        console.log("deviceIdName", deviceIdName, formData[deviceIdName]);
        console.log("o", o);
        console.log("props", props);
        return (
          (o.itemId === node.value &&
            (_.isEmpty(o.portDeviceId) || o.portDeviceId === 0) &&
            props.name?.toLowerCase() !== o.entityName?.toLowerCase()) ||
          (o.itemId === node.value &&
            (_.isEmpty(o.portDeviceId) || o.portDeviceId === 0) &&
            props.name.toLowerCase() === o.entityName.toLowerCase() &&
            o.occupiedByDeviceId !== _.get(formData, deviceIdName)) ||
          // o.occupiedBy.toLowerCase() !== formData?.name?.toLowerCase()) ||
          // expansion portDevice ports
          (o.itemId === node.value &&
            o.portDeviceId > 0 &&
            props.name.toLowerCase() === o.entityName.toLowerCase() &&
            o.occupiedBy.toLowerCase() !== formData?.name?.toLowerCase() &&
            o.portDeviceId === formData.jsonCommsData?.portDeviceId) // same port device
        );
      });
      if (result !== undefined) {
        console.log("THERES ERRORS", result);
        let error = `Value entered is assigned to ${result.entityName}: "${result.occupiedBy}"`;

        let pathStr = jp.stringify(node.path);
        let tmp = jp.apply(errors, pathStr, function (value) {
          value.addError(error);
          return value;
        });
      }
    }
    return errors;
  };
  // end validation
  // -----------------------------

  const copyFormData = () => {
    copy(JSON.stringify(liveformData, undefined, 2));
    toast("Copied");
  };
  const copyUiSchema = () => {
    copy(JSON.stringify(uiSchema, undefined, 2));
    toast("Copied");
  };

  let fields = {
    //SchemaField: CustomSchemaField,
    "cg:autoPopulateObjectField": (props) => (
      <AutoPopulateObjectFieldTemplate {...props} />
    ),
    "cg:autoPopulateSelectField": (props) => (
      <AutoPopulateSelectFieldTemplate {...props} />
    ), // "use cg:customField from uiSchema to specify this custom field template for the one field"
    "cg:autoPopulateArrayField": (props) => (
      <AutoPopulateArrayFieldTemplate {...props} />
    ),
    "cg:addNewProcessorSelectField": (props) => (
      <AddNewProcesorSelectFieldWithModal {...props} />
    ),
    // not used / incomplete, errors need to be passed to parent form
    "cg:autoPopulateSchemaDependencyField": (
      props // this would be good if i could update the parent form's errors with the contained form's errors ETF
    ) => <AutoPopulateSchemaDependencyField {...props} />,
    "cg:autoPopulateOneOfField": (props) => (
      <AutoPopulateOneOfFieldTemplate {...props} />
    ),
  };

  const widgets = {
    jsonEditorSchema: (props) => (
      <JsonEditor height="550px" showCopyButtons {...props} />
    ),
    jsonEditorSchemaShort: (props) => (
      <JsonEditor height="300px" showCopyButtons {...props} />
    ),
    jsonEditor: (props) => (
      <JsonEditor
        theme="tomorrow_night"
        height="550px"
        showCopyButtons
        hidePreview
        {...props}
      />
    ),
    jsonEditorShort: (props) => (
      <JsonEditor
        theme="tomorrow_night"
        height="300px"
        showCopyButtons
        hidePreview
        {...props}
      />
    ),
    jsonEditorXShort: (props) => (
      <JsonEditor
        theme="tomorrow_night"
        height="175px"
        showCopyButtons
        hidePreview
        {...props}
      />
    ),
    "cg:defaultFormDataField": (props) => <DefaultFormDataWidget {...props} />,
    "cg:range": (props) => <CustomRangeWidget {...props} />,
  };

  // *************************
  // Util Function
  // name: convertToQuotedSubscriptPath
  // creates a jsonpath using the quoted subscript path notation eg: $['field1']['field2']
  // which is required when an element has illegal characters eg: ui:field in uiSchema
  // *************************
  const convertToQuotedSubscriptPath = (json, field, uiField) => {
    let pathArr = jp.paths(json, `$..${field}`)[0];
    if (uiField) pathArr.push(uiField);
    let quotedSubscriptPath = pathArr.shift(); // shift $ (remove 1st element)
    pathArr.map((el) => {
      quotedSubscriptPath += `['${el}']`;
    });

    return quotedSubscriptPath;
  };
  // **************************
  // Function handleSetFieldSchema()
  // is made available to customFieldTemplates via formContext to allow dynamic creation of schema for the field
  // the schema for the field is passed to parent form, added to the schema and state is set to update the form
  // uiSchema requires the ui:field option to be cleared in order to render the new schema properly
  // --
  // this function is required to get around using a nested RJSForm in the custom field template, as the errors etc are not
  // propigated to the parent form automatically
  // **************************
  const handleSetFieldSchema = (field, fieldSchema) => {
    console.log("handleSetFieldSChema() Called", field, fieldSchema, schema);
    let newSchema = { ...schema };
    let newUiSchema = { ...uiSchema };
    let schemaPathArr = jp.paths(newSchema, `$..${field}`)[0];
    let uiSchemaPathArr = jp.paths(uiSchema, `$..${field}`)[0];

    // console.log("schemaPathArr", schemaPathArr);
    // console.log("uiSchemaPathArr", uiSchemaPathArr);

    // use JsonPath $[''] notation because "ui:field" in uiSchema has a colon :
    let quotedSubscriptPath = convertToQuotedSubscriptPath(newSchema, field);
    // console.log("quotedSubscriptPath", quotedSubscriptPath);

    // UISCHEMA - remove ui:field option
    let quotedSubscriptPathUiSchema = convertToQuotedSubscriptPath(
      newUiSchema,
      field,
      "ui:field"
    );

    // console.log("quotedSubscriptPathUiSchema", quotedSubscriptPathUiSchema);
    let resultSetUiFieldEMpty = jp.value(
      newUiSchema,
      quotedSubscriptPathUiSchema,
      {}
    );
    // console.log("resultSetUiFieldEMpty", resultSetUiFieldEMpty);
    console.log("newUiSchema", newUiSchema);
    setUISchema(newUiSchema);

    // SCHEMA ASSIGN FIELDSCHEMA TO object
    // console.log("jp.stringify(schemaPathArr)", jp.stringify(schemaPathArr));
    let setSchemaResult = jp.value(
      newSchema,
      jp.stringify(schemaPathArr),
      fieldSchema
    );
    console.log("setSchemaResult", setSchemaResult);
    console.log("newSchema", newSchema);

    setSchema(undefined);
    setSchema({ ...newSchema });
  };
  const handleSetError = (errorSchema) => {
    console.log("CustomForm.handleSetError()", errorSchema);
    setExtraErrors(errorSchema);
  };

  const handleSetDefaultFormData = (location) => {
    console.log("handleSetDefaultFormData()", location, formData);
  };

  const handleSetFormData = (data) => {
    console.log("handleSetFOrmData()", data);
    setFormData(data);
  };

  const handleAppendErrors = (errors) => {
    console.log("handleAppendErrors", errors);
    //setFieldErrors(errors);
  };

  const handleSetDefinitionAndUpdateSchema = (definitions) => {
    console.log(
      "handleSetDefinitionAndUpdateSchema() definitions",
      definitions
    );
    let newSchema = _.cloneDeep(schema);
    // newSchema = {
    //   type: "object",
    //   properties: {
    //     telephone: {
    //       type: "string",
    //       title: "Telephone",
    //     },
    //   },
    // };
    newSchema.definitions = definitions;
    setSchema(newSchema);
  };

  const handleSetFieldFormData = (idSchema, data) => {
    console.log("handleSetFieldFormData()", idSchema, data);

    let idSchemaPath = idSchema.split("_");
    idSchemaPath.shift();

    let newFormData = _.cloneDeep(formData);
    _.set(newFormData, idSchemaPath, data);
    setFormData(newFormData);
  };

  return (
    <>
      {FormWithConditionals && schema && (
        <>
          {/* FormWithConditionals */}
          <ErrorBoundary>
            <FormWithConditionals
              //idPrefix="configuredForm"
              // --
              // schema and uiSchema used when FormWithConditionals set to plain Form component (when no rules )
              schema={schema}
              uiSchema={uiSchema}
              // --
              formData={formData}
              //liveValidate={props.liveValidate ?? true}
              showErrorList //={props.showErrorList ?? isDev()} // this needs to be true in development, eg if a field gets renamed
              noHtml5Validate={props.noHtml5Validate ?? true}
              //onSubmit={e => handleSubmit(e)} // this is how you would pass event with parameter directly to the parent component
              //onSubmit={validateOnSubmit} // i want to receive it here first to validate the form on the server side, then pass to the parent component if valid
              onSubmit={(e) => props.handleSubmit(e)}
              //onSubmit={(e) => console.log("CustomForm HANDLE SUBMIT", e)}
              onChange={handleFormChange}
              formContext={{
                onFieldChange: handleFieldChange,
                configuration: props.configuration,
                controlSystem: props.controlSystem,
                formData: props.formData,
                setFieldSchema: handleSetFieldSchema,
                handleSetError: handleSetError,
                setDefaultFormData: handleSetDefaultFormData,
                setFormData: handleSetFormData,
                appendErrors: handleAppendErrors,
                setDefinitionAndUpdateSchema:
                  handleSetDefinitionAndUpdateSchema,
                setFieldFormData: handleSetFieldFormData,
              }}
              widgets={widgets}
              fields={fields}
              extraErrors={extraErrors}
              omitExtraData // clears extra data onSubmit only
              liveOmit={false} // clears extra data onFormChange
              FieldTemplate={CustomFieldTemplate}
              ArrayFieldTemplate={ArrayFieldTemplate} // globally assigns arrayFieldTemplate to every array field in schema
              //validate={validate} // validate (called on form Submit or if live validate is enabled, just before onSubmit function) but cant use this for async calls to server FMD!
              //validate={validateAlt}
              transformErrors={transformErrors}
              ErrorList={ErrorListTemplate}
              onError={(err) => console.log("DynamicForm onERROR ", err)}
              //highlightFirstError={true}
            >
              {" "}
              {!props.readOnly && (
                <>
                  {/* this space needs to be here otherwise the default green submit button will show */}{" "}
                  {props.preview ? (
                    <>
                      <hr />
                      <Button
                        variant="primary"
                        onClick={() => toast("Form is for Preview Only")}
                      >
                        <span className="fa fa-save mr-1"></span>Save
                      </Button>
                    </>
                  ) : props.hideSubmitAndCancel === true ? (
                    <>
                      <span className="fa fa-info mr-2"></span>
                      <i>form data will be automatically updated on a change</i>
                    </>
                  ) : (
                    <>
                      <hr />
                      <Button variant="primary" type="submit" className="mr-1">
                        <>
                          {props.submitButton ? (
                            <>{props.submitButton}</>
                          ) : (
                            <>
                              <span className="fa fa-save mr-1"></span>Save
                            </>
                          )}
                        </>
                      </Button>
                      {typeof props.handleCancel === "function" && (
                        <Button
                          variant="secondary"
                          onClick={props.handleCancel}
                          className="mr-1"
                        >
                          <span className="fa fa-times-circle mr-1"></span>
                          Cancel
                        </Button>
                      )}
                      {props.showReset &&
                        typeof props.handleReloadFormData === "function" && (
                          <Button
                            variant="secondary"
                            onClick={props.handleReloadFormData}
                          >
                            <span className="fa fa-undo mr-1"></span>
                            Reset
                          </Button>
                        )}
                      {props.showDelete && (
                        <>
                          <Button
                            variant="danger"
                            className="ml-2"
                            onClick={props.handleDelete}
                          >
                            <span className="fa fa-trash mr-1"></span>
                            Delete
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </FormWithConditionals>
          </ErrorBoundary>
        </>
      )}
      {!props.readOnly && (
        <>
          {formData && formData.createdBy && !formData.lastModifiedBy && (
            <div>
              created by{" "}
              {`${formData?.createdBy}  ${new Date(formData?.created)}`}
            </div>
          )}
          {formData && formData.lastModifiedBy && (
            <div>
              last modified by{" "}
              {`${formData?.lastModifiedBy}  ${new Date(
                formData?.lastModified
              )}`}
            </div>
          )}
          {isDev() && (
            <>
              <hr />
              Copy to clipboard <br />
              <CopyToClip
                text={JSON.stringify(schema, null, 2)}
                label="Schema"
              />{" "}
              <Button
                variant="light"
                className="btn btn-sm mt-1"
                onClick={copyFormData}
              >
                <i className="fas fa-copy mr-2"></i>Form Data
              </Button>{" "}
              <Button
                variant="light"
                className="btn btn-sm mt-1"
                onClick={copyUiSchema}
              >
                <i className="fas fa-copy mr-2"></i>Ui Schema
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default CustomForm;
