import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

//import "react-bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./index.css";

import App from "./App";
import ConfiguredApp from "./ConfiguredApp";
import Forbidden from "./components/Forbidden";
import Error from "./components/Error";
import { NotFound } from "./components/NotFound";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter basename={baseUrl}>
    {/* <Switch>
        <Route exact path="/login" component={Login} />
        <Route component={App} />        
      </Switch> */}
    {/* <App /> */}
    <Switch>
      <Route path="/forbidden" component={Forbidden} />
      <Route path="/error" component={Error} />
      <Route exact path="/not-found" component={NotFound} />
      <Route component={App} />
      {/* <Route component={ConfiguredApp} /> */}
    </Switch>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
