import React, { useEffect, useState } from "react";
import _ from "lodash";

import ConfigItemsSection from "./ConfigItemsSection";
import http from "../../services/APIService";

var jp = require("jsonpath");

function ConfigItemsSection_Display(props) {
  const [schema, setSchema] = useState(undefined);
  const [controlSystem, setControlSystem] = useState(undefined);
  const [configuration, setConfiguration] = useState(undefined);

  const [data, setData] = useState(undefined);

  const getData = async () => {
    const endpoint = `displayDevice/${controlSystem.controlSystemId}`;

    const result = await http.callApi("get", endpoint);
    if (result) {
      console.log(" result", result);
      setData(result.data);

      if (schema) {
        let nodes = jp.nodes(schema, `$..displayDeviceId`)[0];
        console.log("schema", schema, nodes);
        nodes.value.enum = result.data.map(
          ({ displayDeviceId }) => displayDeviceId
        );
        nodes.value.enumNames = result.data.map(({ name }) => name);
        setSchema(schema);
      }
    }
  };

  useEffect(() => {
    if (props.controlSystem) {
      setControlSystem(props.controlSystem);
    }
  }, [props.controlSystem]);

  useEffect(() => {
    if (props.configuration) setConfiguration(props.configuration);
  }, [props.configuration]);

  useEffect(() => {
    // update display schema with actual data
    if (props.schemas) {
      setSchema(props.schemas["Displays"]);
      console.log("schema", schema);
    }
  }, [props.schemas]);

  useEffect(() => {
    if (controlSystem) getData();
  }, [controlSystem]);

  useEffect(() => {
    console.log("schema changed", schema);
  }, [schema]);

  if (!props.controlSystem || !props.configuration)
    return (
      <>
        <i className="fas fa-spinner mr-2"></i>Loading Control System and
        Configuration
      </>
    ); // dont want to know about it until controlSystem and configuration has been passed in

  console.log("ConfigItemsSection_Display props", props);
  const handleFieldChange = (name, fieldFormData, idSchema) => {
    console.log(
      "ConfigItemsSection_Display handleFieldChange() ",
      name,
      fieldFormData,
      idSchema
    );
    // if (name === "displayDeviceId" && fieldFormData) {
    //   // populate related fields
    //   let nodes = jp.nodes(schema, `$..displayDeviceId`)[0];
    //   console.log("schema", schema, nodes);
    //   nodes.value.enum = result.data.map(
    //     ({ displayDeviceId }) => displayDeviceId
    //   );
    //   nodes.value.enumNames = result.data.map(({ name }) => name);
    //   setSchema(schema);
    // }
  };
  const handleFormChange = (formData) => {
    console.log(
      "ConfigItemsSection_Display handleFormChange() formData",
      formData
    );

    let newSchema = { ...schema };

    delete formData.controlSystem;
    // find displayDeviceId value and update related fields
    let displayDeviceIdNodes = jp.nodes(formData, "$..displayDeviceId")[0];
    console.log("displayDeviceId nodes", displayDeviceIdNodes.value, newSchema);
    // get display device
    let displayDevice = controlSystem.displayDevices.find(
      (d) => d.displayDeviceId === displayDeviceIdNodes.value
    );

    // find all related props in schema
    let displayInputProps = jp.nodes(newSchema, "$..displayInput");
    displayInputProps.map((prop) => {
      console.log("prop", prop);
      prop.path.shift(); // remove $

      let field = _.get(newSchema, prop.path);

      // delete it or it wont update until a change is made
      // _.unset(schema, prop.path);

      // // update the field
      // _.set(
      //   field,
      //   "enum",
      //   displayDevice.displayBrandType.displayBrandTypeInputs.map(
      //     ({ input }) => input
      //   )
      // );

      // _.set(
      //   field,
      //   "enumNames",
      //   displayDevice.displayBrandType.displayBrandTypeInputs.map(
      //     ({ name }) => name
      //   )
      // );

      // _.set(schema, prop.path, field);

      _.set(newSchema, prop.path, {
        enum: displayDevice.displayBrandType.displayBrandTypeInputs.map(
          ({ input }) => input
        ),
        enumNames: displayDevice.displayBrandType.displayBrandTypeInputs.map(
          ({ name }) => name
        ),
      });

      // _.set(
      //   schema,
      //   [...path, "enum"],
      //   displayDevice.displayBrandType.displayBrandTypeInputs.map(
      //     ({ input }) => input
      //   )
      // );

      // _.set(
      //   schema,
      //   [...path, "enumNames"],
      //   displayDevice.displayBrandType.displayBrandTypeInputs.map(
      //     ({ name }) => name
      //   )
      // );

      // _.set(schema, [...path, "enum"], [5, 4]);

      // _.set(schema, [...path, "enumNames"], ["yeh", "man"]);
    });
    // _.set(schema, "definitions.displayInputs", {
    //   enum: displayDevice.displayBrandType.displayBrandTypeInputs.map(
    //     ({ input }) => input
    //   ),
    //   enumNames: displayDevice.displayBrandType.displayBrandTypeInputs.map(
    //     ({ name }) => name
    //   ),
    // });

    // _.set(schema, "definitions.displayInputs", {
    //   enum: [5, 4],
    //   enumNames: ["yeh", "man"],
    // });

    console.log("schema", newSchema);
    setSchema(newSchema);
  };

  return (
    <div>
      {/* <RelatedDataHeader {...props} /> */}
      <h5>
        <i className="fas fa-tv mr-2"></i>ConfigItemsSection_Display
      </h5>
      <hr />
      {schema && (
        <ConfigItemsSection
          // handleFieldChange={handleFieldChange}
          handleFormChange={handleFormChange}
          schema={schema}
          {...props}
        />
      )}
    </div>
  );
}

export default ConfigItemsSection_Display;
