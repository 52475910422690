// AutoPopulateObjectFieldTemplate
// this is used to load a field's schema directly to an object type element
// ie mainly used for loading oneOf schemas built on the backend

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import http from "../../../services/APIService";
import _ from "lodash";
const Mustache = require("mustache");

var jp = require("jsonpath");

function AutoPopulateOneOfFieldTemplate(props) {
  const params = useParams();
  const {
    id,
    name,
    classNames,
    required,
    idSchema,
    schema,
    //uiSchema,
    formData,
    formContext, // holds extra props (ie configuration, controlSystem)
    onChange,
    registry,
    errorSchema,
    idPrefix,
  } = props;

  console.log("AutoPopulateOneOfFieldTemplate props", props);

  const { TitleField, DescriptionField, ObjectField, OneOfField, SchemaField } =
    registry.fields;

  const getSchemaFromResults = (results, schema, titleAccessor, idAccessor) => {
    let res = {};
    let options = [];
    for (let result of results) {
      let option = {};
      if (schema.type === "object") {
        for (let key in schema.properties) {
          //console.log("key", key, schema.properties[key]);

          let pathToKeyInUiSchema = jp.paths(props.uiSchema, `$..${key}`)[0];
          let cgOptions;
          if (pathToKeyInUiSchema) {
            pathToKeyInUiSchema.push("ui:options");
            cgOptions = jp.value(props.uiSchema, pathToKeyInUiSchema);
          }

          let resultFieldNameAccessor =
            cgOptions && cgOptions["cg:resultFieldNameAccessor"];
          let formContextFieldNameAccessor =
            cgOptions && cgOptions["cg:formContextFieldNameAccessor"];

          let items = resultFieldNameAccessor
            ? jp.value(result, `$..${resultFieldNameAccessor}`)
            : jp.value(formContext, `$..${formContextFieldNameAccessor}`);

          if (schema.properties[key].type === "object") {
            if (Array.isArray(items)) {
              res = getSchemaFromResults(
                items,
                schema.properties[key],
                cgOptions ? cgOptions["cg:titleAccessor"] : undefined,
                cgOptions ? cgOptions["cg:idAccessor"] : undefined
              );

              _.set(option, ["properties", key], {
                ...res,
              });
            } else {
              if (items) {
                for (var obj in items) {
                  console.log("obj", obj, items[obj]);
                  _.set(option, ["properties", obj], {
                    ...items[obj],
                  });
                }
              } else if (result[key]) {
                if (schema.properties[key].properties) {
                  for (let k in schema.properties[key].properties) {
                    let aaa = { ...result[key][k] };

                    _.set(option, ["properties", key, "properties", k], {
                      ...result[key][k],
                    });
                    _.set(
                      option,
                      ["properties", key, "type"],
                      schema.properties[key].type
                    );
                    _.set(
                      option,
                      ["properties", key, "title"],
                      schema.properties[key].title || ""
                    );
                    console.log("k", k, result[key][k]);
                  }
                  let items = new Array();

                  // res = getSchemaFromResults(
                  //   items,
                  //   schema.properties[key],
                  //   cgOptions ? cgOptions["cg:titleAccessor"] : undefined,
                  //   cgOptions ? cgOptions["cg:idAccessor"] : undefined
                  // );

                  // _.set(option, ["properties", key], {
                  //   ...res,
                  // });
                } else {
                  _.set(option, ["properties", key], {
                    ...result[key],
                  });
                  let aaa = { ...result[key] };
                  console.log("option", option, result[key]);
                }
              }
            }
          } else if (schema.properties[key].type === "array") {
            if (Array.isArray(items)) {
              if (cgOptions && cgOptions["cg:maxItemsFieldAccessor"]) {
                if (result[cgOptions["cg:maxItemsFieldAccessor"]] > 0) {
                  _.set(
                    schema.properties[key],
                    "maxItems",
                    result[cgOptions["cg:maxItemsFieldAccessor"]]
                  );
                } else if (
                  // if an object
                  typeof cgOptions["cg:maxItemsFieldAccessor"] === "object" &&
                  cgOptions["cg:maxItemsFieldAccessor"] !== null
                ) {
                  let formContextFieldNameAccessor =
                    cgOptions["cg:maxItemsFieldAccessor"][
                      "cg:formContextFieldNameAccessor"
                    ];

                  let arrayItems = jp.value(
                    formContext,
                    `$..${formContextFieldNameAccessor}`
                  );
                  // get the value here by using length of a named result property
                  console.log(
                    ">>>1::>>",
                    formContextFieldNameAccessor,
                    arrayItems
                  );
                  _.set(schema.properties[key], "maxItems", arrayItems.length);
                }
              }

              res = getSchemaFromResults(
                items,
                schema.properties[key],
                cgOptions ? cgOptions["cg:titleAccessor"] : undefined,
                cgOptions ? cgOptions["cg:idAccessor"] : undefined
              );

              _.set(option, ["properties", key], {
                ...res,
              });
            } else {
              // no result
              _.set(option, ["properties", key], {
                ...schema.properties[key],
              });
            }
          } else {
            if (idAccessor === key) {
              _.set(option, ["properties", idAccessor], {
                const: _.get(result, idAccessor),
                enumName: _.get(result, titleAccessor),
              });
            } else {
              _.set(option, ["properties", key], {
                ...schema.properties[key],
              });
            }
          }
        }
      } else if (schema.type === "array") {
        //console.log("schema.type is array");
        for (let key in schema.items.properties) {
          // if (Array.isArray(result[key])) {
          //   console.log("IS ARRAY result: ", result[key]);

          //   //set the result of this to a field
          //   let cgOptions = schema.properties[key]["cg:options"];
          //   console.log("cgOptions", cgOptions);

          //   res = getSchemaFromResults(
          //     result[key],
          //     schema.properties[key],
          //     cgOptions.titleAccessor,
          //     cgOptions.idAccessor
          //   );

          //   _.set(option, ["properties", key], { ...res });

          if (idAccessor === key) {
            _.set(option, ["items", "properties", idAccessor], {
              const: _.get(result, idAccessor),
              enumName: _.get(result, titleAccessor),
            });
          } else {
            _.set(option, ["items", "properties", key], {
              ...schema.items.properties[key],
            });
          }
          //}
        }
      }
      // TODO: work with other types.
      else {
        option = result;
      }
      options.push(option);
    }

    let pathToIdAccessor = jp
      .paths(schema, `$..${idAccessor}`)
      .filter((path) => !path.includes("dependencies"))[0];
    console.log("schema", schema);
    if (pathToIdAccessor) pathToIdAccessor.shift(); // drop the $

    console.log("pathToIdAccessor", pathToIdAccessor);

    let propertiesObj = {
      properties: {
        [idAccessor]: {
          ..._.get(schema, pathToIdAccessor),
          enum: options.map((option) => {
            return _.get(option, pathToIdAccessor)?.const; //option["properties"][idAccessor].const),
          }),
          enumNames: options.map(
            (option) => _.get(option, pathToIdAccessor)?.enumName //option["properties"][idAccessor].enumName
          ),
        },
      },
    };

    let depsObj = {
      dependencies: {
        [idAccessor]: {
          oneOf: options,
        },
      },
    };

    // remove empty enum & enumNames from object
    if (_.isEmpty(propertiesObj.properties[idAccessor].enum)) {
      console.log(
        "deleting enum",
        propertiesObj.properties[idAccessor],
        propertiesObj.properties[idAccessor].enum
      );
      delete propertiesObj.properties[idAccessor].enum;
      delete propertiesObj.properties[idAccessor].enumNames;
    }
    // remove dependencies[idAccessor]
    if (_.isEmpty(depsObj.dependencies[idAccessor]?.oneOf)) {
      console.log(
        "deleting dep oneOf",
        depsObj.dependencies[idAccessor],
        depsObj.dependencies[idAccessor]?.oneOf
      );
      delete depsObj.dependencies[idAccessor].oneOf;
    }

    let propertySchema = propertiesObj;

    if (pathToIdAccessor?.find((el) => el === "items")) {
      propertySchema = {
        items: {
          ...propertiesObj,
          //...depsObj,
        },
      };
    } else {
      propertySchema = {
        ...propertiesObj,
        ...depsObj,
      };
    }

    console.log("propertySchema", propertySchema);

    let newSchema = {
      ...schema,
      ...propertySchema,
    };

    // // use lodash to set
    // // set properties
    // // console.log("newSchema>>", newSchema);
    // // if path contains "items" then we are dealing with an array in the schema
    // // so need to get path to idAccessor
    // let pathToIdAccessor = jp
    //   .paths(schema, `$..${idAccessor}`)
    //   .filter((path) => !path.includes("dependencies"))[0];
    // //console.log("pathToId1", pathToIdAccessor);
    // pathToIdAccessor.shift(); // drop the $

    // console.log("pathToIdAccessor", pathToIdAccessor);

    // //options.map((option) => console.log("option:", option));
    // console.log("idAccessor", idAccessor);
    // _.set(newSchema, pathToIdAccessor, {
    //   //...schema.properties[idAccessor],
    //   enum: options.map((option) =>
    //     option.items !== undefined
    //       ? option.items.properties[idAccessor].const
    //       : option.properties[idAccessor].const
    //   ),
    //   // enumNames: options.map((option) =>
    //   //   option.items
    //   //     ? option.items.properties[idAccessor].enumName
    //   //     : option.properties[idAccessor].enumName
    //   // ),
    // });

    console.log("newSchema::", newSchema);

    return newSchema;
  };

  const getData = async (tmpSchema) => {
    console.log("AutoPopulateOneOfFieldTemplate props", props);

    let mustachedUISchema = props.uiSchema;
    try {
      mustachedUISchema = JSON.parse(
        Mustache.render(JSON.stringify(props.uiSchema), props.formContext),
        undefined,
        2
      );
    } catch (ex) {
      console.error("Mustache failed on uiSchema", ex);
      mustachedUISchema = props.uiSchema;
    }
    console.log(
      "AutoPopulateOneOfFieldTemplate mustachedUISchema",
      mustachedUISchema
    );

    let uiOptions = mustachedUISchema["ui:options"];
    if (!uiOptions) return;
    let endpoint = uiOptions["cg:endpoint"];
    let titleAccessor = uiOptions["cg:titleAccessor"];
    let idAccessor = uiOptions["cg:idAccessor"];
    let filter = uiOptions["cg:filter"];

    // try get data from local configuration (eg props.FormContext.configuration or props.FormContext.controlSystem) first
    let results = _.get(formContext, endpoint);

    if (endpoint && !results) {
      const result = await http.callApi("get", endpoint);

      if (!result || result.status !== 200) return;
      results = result.data;
    }
    if (filter && results) {
      let funcStr = "return data.filter(" + filter + ")";
      let filterFunc = new Function("data", funcStr);
      results = filterFunc(results);
      console.log("filter func", funcStr, results);
    }
    console.log("AutoPopulateOneOfFieldTemplate schema", schema);
    console.log("AutoPopulateOneOfFieldTemplate results", results);

    let resultArray = [];
    if (!Array.isArray(results)) {
      resultArray.push(results);
    } else resultArray = results;

    // recursively read results and build oneOf schema dependencies in schema
    let wipSchema = getSchemaFromResults(
      resultArray,
      schema,
      titleAccessor,
      idAccessor
    );

    if (wipSchema) {
      console.log("SET NEW wipSchema", { ...wipSchema });
      formContext.setFieldSchema(name, wipSchema);
    }
  };

  useEffect(() => {
    if (schema !== undefined) {
      console.log(
        "AutoPopulateOneOfFieldTemplate GETDATA USEEFFECT CALLED - schema",
        schema
      );
      getData(schema);
    }
  }, []);

  useEffect(() => {
    console.log("autoPopulateOneOfFieldTemplate props changed", props);
  }, [props]);

  useEffect(() => {
    console.log("autoPopulateOneOfFieldTemplate LOADED");
  }, []);

  return <></>;
}

export default AutoPopulateOneOfFieldTemplate;
