import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const REQUIRED_FIELD_SYMBOL = "REQ";

function CustomTitleField(props) {
  const { id, title, required } = props;
  //console.log("CustomTItleField props", props);
  return (
    <label htmlFor={id} className="control-label">
      <h5>{title}</h5>
      {required && (
        <OverlayTrigger
          key={`${id}-required-overlay`}
          placement="top"
          overlay={
            <Tooltip id={`${id}-required-tooltip`}>
              This is a required field!
            </Tooltip>
          }
        >
          <i className="fa fa-asterisk fa-1 asterisk"></i>
        </OverlayTrigger>
      )}
    </label>
  );
}

if (process.env.NODE_ENV !== "production") {
  CustomTitleField.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    required: PropTypes.bool,
  };
}

export default CustomTitleField;
