import React, { useState, useEffect } from "react";
import http from "../../../services/APIService";
import _ from "lodash";
import { Alert } from "react-bootstrap";

const Mustache = require("mustache");
var jp = require("jsonpath");

function AutoPopulateSelectFieldTemplate(props) {
  const {
    id,
    name,
    classNames,
    required,
    idSchema,
    schema,
    formData,
    formContext, // holds extra props (ie configuration, controlSystem)
    onChange,
    registry,
    disabled,
    readonly,
    onBlur,
    onFocus,
    autofocus,
  } = props;

  console.log("AutoPopulateSelectFieldTemplate props >>>>>>>>>>>>>", props);

  const [newSchema, setNewSchema] = useState(undefined);
  const [newFormData, setNewFormData] = useState(undefined);
  const [linkName, setLinkName] = useState(undefined);
  const [extraErrors, setExtraErrors] = useState(undefined);

  const [remoteData, setRemoteData] = useState(undefined);

  const { TitleField, DescriptionField } = registry.fields;
  const { SelectWidget } = registry.widgets;

  const [selectListOptions, setSelectListOptions] = useState(undefined);

  const [validate, setValidate] = useState(false);

  function Label(props) {
    const { label, required, id } = props;
    if (!label) {
      return null;
    }
    return (
      <label className="control-label" htmlFor={id}>
        {label}
        {required && <span className="required">*</span>}
      </label>
    );
  }

  useEffect(() => {
    if (remoteData) {
      console.log("loading AutoPopulateSelectFieldTemplate", props);
      updateDependentRef(props.formData);
    }
  }, [remoteData]);

  const getData = async () => {
    let tmpSchema = { ...schema };
    let options = props.uiSchema["ui:options"];
    if (!options) return;
    //let endpoint = options["cg:endpoint"];
    let endpoint = Mustache.render(options["cg:endpoint"], formContext);
    console.log("ENDPOINT ", endpoint);
    let queryObj = options["cg:query"];
    setLinkName(options["cg:linkName"]);
    //if (!endpoint) return; // allow building of list from pad values
    let pad = options["cg:pad"];
    let displayHex = options["cg:displayHex"];
    let titleAccessor = options["cg:titleAccessor"];
    let idAccessor = options["cg:idAccessor"];
    let filter = options["cg:filter"];
    let sortBy = options["cg:sortBy"]; // lodash uses an array of sorting items, always asc

    let enumVals = [];
    let enumNames = [];
    //console.log(uiSchema);
    console.log("AutoPopulateSelectFieldTemplate endpoint", endpoint);
    console.log("AutoPopulateSelectFieldTemplate formContext >> ", formContext);

    let results = _.get(formContext, endpoint);
    // try get data from local configuration (eg props.FormContext.configuration or props.FormContext.controlSystem) first

    console.log("AutoPopulateSelectFieldTemplate getData()", props, results);

    if (endpoint && !results) {
      var result = undefined;
      if (queryObj && endpoint.toLowerCase().includes("query"))
        result = await http.callApi("post", endpoint, queryObj);
      else result = await http.callApi("get", endpoint);
      console.log(
        "AutoPopulateSelectFieldTemplate http get request result: ",
        result
      );
      if (!result || result.status !== 200) return;
      results = result.data;
    }

    console.log("AutoPopulateSelectFieldTemplate results", results);
    setRemoteData(results);

    //if (results)
    {
      if (filter && results) {
        let funcStr = "return data.filter(" + filter + ")";
        let filterFunc = new Function("data", funcStr);
        results = filterFunc(results);

        // console.log("filter func", funcStr, results);
      }

      // sorting
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
      // if (sort && sort.by && results) {
      //   let funcStr = `return data.sort((a,b) => a.${sort.by}- b.${sort.by})`;

      //   if (sort.direction?.toLowerCase().includes("desc")) {
      //     funcStr = `return data.sort((a,b) => b.${sort.by}- a.${sort.by})`;
      //   }

      //   let func = new Function("data", funcStr);
      //   results = func(results);
      // }

      // use lodash sortBy
      if (sortBy && results) {
        results = _.sortBy(results, sortBy);
      }

      // make pad work when there is no endpoint defined/ and no results
      if (!_.isEmpty(pad)) {
        for (var i = pad.min; i <= pad.max; i++) {
          var index = -1;
          if (results) {
            index = _.findIndex(results, function (o) {
              return o.itemId === i;
            });
          }
          let enumName = pad.prefix ?? "";
          let hexVal =
            i.toString(16).toUpperCase().padStart(2, "0") + `h (${i}d)`;

          enumName += displayHex ? hexVal : i;
          if (index < 0) {
            enumVals.push(i);
            enumNames.push(enumName); // toString(16) is hex conversion
          } else {
            // console.log("results", i, results
            enumName += " " + results[index][titleAccessor];

            console.log("formContext", formContext);

            enumVals.push(results[index][idAccessor]);
            enumNames.push(enumName);
          }
        }
      } else {
        if (Array.isArray(results)) {
          if (idAccessor) {
            console.log(
              ">:>:>>:",
              results.map((d) => d[idAccessor])
            );

            enumVals = results.map((d) => d[idAccessor]);
            enumNames = results.map((d) => d[titleAccessor]);
            // console.log("no pad tmpSchema1", tmpSchema);
          } // its just an array of strings
          else {
            enumVals = results;
          }
        }
      }
      console.log("enumVals", enumVals);
      console.log("enumNames", enumNames);
      _.unset(tmpSchema, "enum");
      _.unset(tmpSchema, "enumNames");
      _.set(tmpSchema, "enum", enumVals);
      if (enumNames) _.set(tmpSchema, "enumNames", enumNames);

      console.log("tmpSchema", schema);
      // _.set(tmpSchema, "enum", enumVals);
      // _.set(tmpSchema, "enumNames", enumNames);
      // tmpSchema.enum = enumVals;
      // tmpSchema.enumNames = enumNames;
      // tmpSchema.enum = results.map((d) => d[idAccessor]);
      // tmpSchema.enumNames = results.map((d) => d[titleAccessor]);
      //setNewSchema(tmpSchema);

      mapDataToEnumOptions(enumVals, enumNames);
    }

    return results;
  };

  useEffect(() => {
    console.log(
      "AUTOPOPULATESELECTFIELD GETDATA USEEFFECT CALLED",
      props.schema,
      props.formData
    );

    let options = props.uiSchema["ui:options"];
    options && setValidate(options["cg:validate"]);

    if (schema !== undefined) {
      getData(schema);
    }

    //mapDataToEnumOptions(enumVals, enumNames);
  }, []);

  // useEffect(() => {
  //   //if (!_.isEmpty(props.formData)) setNewFormData(props.formData);
  // }, [props.formData]);

  useEffect(() => {
    if (!_.isEmpty(selectListOptions))
      console.log("selectListOptions updated", selectListOptions);
  }, [selectListOptions]);

  const mapDataToEnumOptions = (vals, names) => {
    let options = [];

    console.log("vals:::>>>", vals);

    vals.map((val, index) => {
      options.push({
        value: val,
        label: names[index] ?? val,
      });
    });

    setSelectListOptions(options);
  };

  // const handleOnChange = async (fieldFormData) => {
  //   console.log("autoPop handleOnChange", props);

  //   //props.errors.jsonCommsData.ipId.addError("FMD");

  //   return;

  //   if (validate && fieldFormData) {
  //     console.log(
  //       "autoPopulateSelectField.handleOnChange() fieldFormData",
  //       fieldFormData,
  //       props
  //     );

  //     // convert idSchema to path that aspnet core can use
  //     // ie jsonCommsData.ipId
  //     let pathArr = idSchema["$id"].split("_");
  //     pathArr.shift(); // remove first element (root)
  //     let pathToField = pathArr.join(".");
  //     console.log("pathArr", pathArr);
  //     console.log("pathToField", pathToField);

  //     // jp.value(formData, `$..${pathToField}`, fieldFormData);
  //     // console.log("formData2", formData);
  //     // const errSchema = {
  //     //   jsonCommsData: {
  //     //     ipId: {
  //     //       __errors: ["fmd does this work?"],
  //     //     },
  //     //   },
  //     // };
  //     // _.set(props.errorSchema, { ...errSchema });
  //     //formContext.handleSetError(errSchema);
  //     try {
  //       if (name === "ipId") {
  //         var result = await http.callApi(
  //           "post",
  //           `validation/${formContext.controlSystem.controlSystemId}/ipId/${fieldFormData}?idSchema=${idSchema["$id"]}`,
  //           fieldFormData
  //         );

  //         console.log("validation result", result);

  //         if (result.status === 204) {
  //           let errors = { ...extraErrors };
  //           jp.value(errors, `$..${pathToField}.__errors`, []);
  //           //setExtraErrors(errors);
  //           formContext.handleSetError(errors);
  //         }
  //       }
  //     } catch (error) {
  //       console.log("!!!serverErrors", error);
  //       let errs = http.handleHttpErrors(error, {
  //         ...extraErrors,
  //       });
  //       console.log("extraErrors", errs);
  //       //setExtraErrors(errs);
  //       formContext.handleSetError(errs);
  //     }
  //   }

  //   onChange(fieldFormData);
  // };

  const checkForDependent = () => {
    let options = props.uiSchema["ui:options"];
    if (!options) return false;
    // -------------
    // dependent refs
    let dependent = options["cg:dependent"];
    // then update schema
    if (!dependent) return false;

    return true;
  };

  const handleOnChange = async (fieldFormData) => {
    console.log(
      "autoPop handleOnChange() fieldFormData",
      name,
      fieldFormData,
      formContext,
      props,
      formContext.controlSystem,
      formContext.configuration
    );

    onChange(fieldFormData);

    if (!checkForDependent()) return;

    formContext.onFieldChange(name, fieldFormData, props.idSchema);

    // update formData
    formContext.setFieldFormData(props.idSchema["$id"], fieldFormData);

    updateDependentRef(fieldFormData);
  };

  const updateDependentRef = async (fieldFormData) => {
    let options = props.uiSchema["ui:options"];
    if (!options) return;
    let endpoint = options["cg:endpoint"];

    // -------------
    // dependent refs
    let dependent = options["cg:dependent"];
    // then update schema
    if (!dependent) return;

    let definitions = _.cloneDeep(props.registry.definitions);
    if (!definitions) return;

    // load dependent ref list and update schema and formdata
    let idAccessor = options["cg:idAccessor"];
    let titleAccessor = options["cg:titleAccessor"];
    let dependentIdAccessor = dependent["cg:idAccessor"];
    let dependentTitleAccessor = dependent["cg:titleAccessor"];

    let ref = dependent.ref;
    let dataPath = dependent.dataPath;

    if (!definitions[ref]) return;

    if (
      definitions[ref].enum &&
      Array.isArray(definitions[ref]) &&
      definitions[ref].length > 0
    ) {
      return; // already has data, this function updateDependentRef() gets called on load, and if this it runs regardless if it already has data it will cause a loop
    }

    let results = _.get(formContext, endpoint);
    if (!results) {
      if (!remoteData) results = await getData(schema);
      else results = _.cloneDeep(remoteData);
    }
    if (!results) return;

    let item = results.find((i) => i[idAccessor] === fieldFormData);

    let refData = item && _.get(item, dataPath);
    if (Array.isArray(refData) && refData.length > 0) {
      _.set(
        definitions[ref],
        "enum",
        refData.map((i) => i[dependentIdAccessor])
      );
      _.set(
        definitions[ref],
        "enumNames",
        refData.map((i) => i[dependentTitleAccessor])
      );

      _.set(definitions[ref], "type", "number");
      _.set(definitions[ref], "description", "");
    } else {
      _.unset(definitions[ref], "enum");
      _.unset(definitions[ref], "enumNames");
      // _.set(definitions[ref], "enum", []); // this will throw an error when submitting the form- enum has no entries
      // _.set(definitions[ref], "enumNames", []);
      // also disable or hide the field
      _.set(definitions[ref], "type", "null");
      _.set(
        definitions[ref],
        "description",
        "There are no items to display for this type"
      );
      // extraSchema
    }

    formContext.setDefinitionAndUpdateSchema(definitions);
  };

  // remove the custom ui:field element
  let { "ui:field": field, ...uiSchema } = props.uiSchema;

  const singleFieldSchema = {
    type: "number",
  };

  const arraySchema = {
    type: "array",
    items: {
      type: "number",
    },
  };
  return (
    <>
      {selectListOptions ? (
        <>
          <SelectWidget
            className="form-control"
            readonly={readonly}
            disabled={disabled}
            id={idSchema.$id}
            autofocus={autofocus}
            onBlur={onBlur}
            onFocus={onFocus}
            // onChange={(value) =>
            //   validate ? handleOnChange(value) : onChange(value)
            // }
            onChange={(value) => handleOnChange(value)}
            //onChange={(event) => console.log("changed", event)}
            options={{
              enumOptions: selectListOptions,
            }}
            placeholder={uiSchema["ui:placeholder"]}
            //schema={{ type: "number" }}
            schema={schema.uniqueItems ? arraySchema : singleFieldSchema}
            value={formData}
            multiple={schema.uniqueItems}
          />
        </>
      ) : (
        <Alert variant="secondary">
          <span className="fa fa-info mr-2"></span>Nothing returned
        </Alert>
      )}

      {/* {console.log("updated uiSchema", uiSchema)}
      {newSchema ? (
        <Form
          //FieldTemplate={CustomFieldTemplate}
          noHtml5Validate={false} // must have this option enabled otherwise form is submitted even though this custom field has errors
          showErrorList={false}
          tagName={"div"}
          schema={newSchema}
          uiSchema={uiSchema}
          formData={formData}
          onChange={(e) => onChange(e.formData)}
          liveValidate
          //onError={(errors) => console.log("errors", errors)}
        >
          <span></span>
        </Form>
      ) : (
        <h1>loading</h1>
      )} */}
    </>
  );
}

export default AutoPopulateSelectFieldTemplate;
