import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import CustomForm from "../common/CustomForm";
import http from "../../services/APIService";
import { toast } from "react-toastify";

const schema = {
  type: "object",
  title: "System",
  required: ["name"],
  properties: {
    controlSystemId: {
      type: "string",
    },
    name: {
      type: "string",
      title: "Name",
    },
    systemType: {
      type: "object",
      title: "",
      properties: {
        name: {
          type: "string",
          title: "Type",
        },
      },
    },
    locationObject: {
      title: "Location",
      type: "object",
    },
    processorTitle: {
      type: "object",
      title: "Processsor",
    },
    currentProcessor: {
      title: "Current Processor",
      type: "string",
      readOnly: true,
    },
    newProcessorId: {
      // title: "Or select an existing available processor",
      title: " ",
      type: "number",
    },
  },
};

const uiSchema = {
  controlSystemId: { "ui:widget": "hidden" },
  systemType: {
    name: {
      "ui:disabled": true,
    },
  },
  locationObject: {
    "ui:field": "cg:autoPopulateObjectField",
    "ui:options": {
      "cg:endpoint": "location/schema",
      "cg:titleAccessor": "name",
      "cg:idAccessor": "locationId",
    },
    locationId: {
      "ui:placeholder": "--select--",
    },
    buildingId: {
      "ui:placeholder": "--select--",
    },
  },
  currentProcessor: {
    "ui:placeholder": "None",
    // "ui:help-link": "/processor/edit",
    // "ui:help-linkName": "Edit Processor",
  },

  newProcessorId: {
    "ui:field": "cg:addNewProcessorSelectField",
    "ui:options": {
      "cg:endpoint": "processor",
      "cg:filter": "p => p.controlSystemId == null",
      "cg:titleAccessor": "name",
      "cg:idAccessor": "processorId",
      "cg:linkName": "Add new processor",
    },
  },
};

const addNewSchema = {
  title: "Add New",
  type: "object",
  required: ["name"],
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    systemTypeId: {
      type: "number",
      title: "Type",
    },
    locationObject: {
      title: "Location",
      type: "object",
    },
  },
};

const addNewUiSchema = {
  name: {
    "ui:placeholder": "Enter Control System Name",
  },
  systemTypeId: {
    "ui:field": "cg:autoPopulateSelectField",
    "ui:options": {
      "cg:endpoint": "systemType",
      "cg:titleAccessor": "name",
      "cg:idAccessor": "systemTypeId",
    },
    "ui:placeholder": "--select--",
  },
  locationObject: {
    "ui:field": "cg:autoPopulateObjectField",
    "ui:options": {
      "cg:endpoint": "location/schema",
      "cg:titleAccessor": "name",
      "cg:idAccessor": "locationId",
    },
    locationId: {
      "ui:placeholder": "--select--",
      "ui:help-link": "/admin/location/new",
    },
    buildingId: {
      "ui:placeholder": "--select--",
    },
  },
};

function ControlSystemForm(props) {
  const params = useParams();
  const history = useHistory();

  const [formData, setFormData] = useState(undefined);

  const handleSubmit = async (e) => {
    if (params.id === "new") {
      console.log("Posting new Control System, formData: ", e.formData);
      const result = await http.callApi("post", "controlsystem", e.formData);
      if (result.status === 200) {
        toast.success("Item added successfully");
        //history.push("/controlsystem/" + result.data.controlSystemId);
        window.location = "/controlsystem/" + result.data.controlSystemId;
      } else {
        toast.error("Error occured adding new control system");
      }
    } else {
      try {
        const data = e.formData;

        console.log("control system put data", data);

        const result = await http.callApi(
          "put",
          `controlsystem/${params.id}`,
          data
        );
        toast.success("Item updated successfully");
        console.log("Item updated successfully " + result);

        window.location = "/controlsystem/" + params.id;
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    console.log(
      "params.id changed or controlsystem changed",
      params,
      props.controlSystem
    );

    if (!props.controlSystem || params.id === "new") {
      if (params.locationId && params.buildingId) {
        setFormData({
          locationObject: {
            locationId: parseInt(params.locationId),
            buildingId: parseInt(params.buildingId),
          },
        });
      } else {
        setFormData({});
      }
    } else {
      setFormData(props.controlSystem);
    }
  }, [params.id, props.controlSystem]);

  return (
    <>
      {console.log("ControlSystemForm Props", props, "params", params)}

      <CustomForm
        schema={params.id === "new" ? addNewSchema : schema}
        uiSchema={params.id === "new" ? addNewUiSchema : uiSchema}
        formData={formData}
        controlSystem={props.controlSystem}
        handleSubmit={(e) => handleSubmit(e)}
      />
    </>
  );
}

export default ControlSystemForm;
