import React from "react";

import { Alert, Badge, Button } from "react-bootstrap";

function FirstLoadAlert(props) {
  return (
    props.state && (
      <Alert variant="warning">
        <Badge variant="warning" className="mr-2">
          <span className="fa fa-info-circle"></span>
        </Badge>
        <b>Form not yet submitted</b>
        <br />
        The form is populated with default data
      </Alert>
    )
  );
}

export default FirstLoadAlert;
