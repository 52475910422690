import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Alert, Button, Container, Row, Col } from "react-bootstrap";

import _ from "lodash";

function ItemList(props) {
  const {
    handleClickProp,
    idName,
    additionalData,
    showDeleteButton,
    relatedData,
    relatedDataIcon,
    more,
    moretitle,
    moreIcon,
    orderable,
    nameField,
  } = props;

  const [items, setItems] = useState(props.items);
  console.log("ItemList items :> ", items);
  const params = useParams();

  useEffect(() => {
    console.log("itemList.props[items] changed", props.items);
    setItems(props.items);
  }, [props.items]);

  const changeValuePosition = (arr, init, target) => {
    [arr[init], arr[target]] = [arr[target], arr[init]];
    return arr;
  };
  const handleSort = async (index, direction) => {
    console.log("itemList.jsx handleSort()", index, direction);

    // plan is to alter the array in javascript then send the new array to the server to update the ids in the database
    let sortedItems = [];
    let element = {};

    switch (direction) {
      // move up or down one (swap the position)
      case "up":
      case "down":
        let target;
        switch (direction) {
          case "down":
            target = index + 1;
            break;
          case "up":
            target = index - 1;
            // check if negative, if so set to 0
            target = target < 0 ? 0 : target;
            break;
          case "start":
            target = 0;
            break;
          case "end":
            target = items.length;
            break;
        }
        sortedItems = changeValuePosition(items, index, target);
        console.log("reposition target", target);
        break;

      // move to start
      case "start":
        // lodash _.pullAt() removes the element at index from array
        // then unshift() adds element to start of array
        sortedItems = items;
        element = _.pullAt(sortedItems, index)[0];
        sortedItems.unshift(element);
        break;

      // move to end
      case "end":
        // lodash _.pullAt(arr, index) removes element at index
        // push() adds element to end of array
        sortedItems = items;
        element = _.pullAt(sortedItems, index)[0];
        sortedItems.push(element);
        break;
    }
    console.log("sortedItems result:", sortedItems);

    let sortedIdArray = [];
    for (const value of sortedItems.values()) {
      console.log("sortedItems.values()", value["name"], idName);
      sortedIdArray.push(value[idName]);
    }
    console.log("sortedIdArray", sortedIdArray);

    // const result = await http.callApi(
    //   "post",
    //   `configSectionSetItem/sort/${params.id}/${params.configurationId}/${params.setTypeId}`,
    //   sortedIdArray
    // );
    //console.log("handleSort() result :>", result);
    props.itemOrderUpdated(sortedIdArray);
  };

  console.log("additionalData", additionalData);

  if (!_.isEmpty(items) && items && items.length && Array.isArray(items)) {
    return items.map((item, index) => (
      <>
        <tr key={item[idName]}>
          <th scope="row">{orderable ? index + 1 : item[idName]}</th>

          <td>
            {nameField === undefined ? item.name : item[nameField]}
            {props.commsInTitle &&
              item.commsSummary &&
              `: ${item.commsSummary}`}
          </td>

          {additionalData?.map((field, index) => {
            return <td key={index}>{_.get(item, field)}</td>;
          })}

          <td>
            <Button
              variant={props.minimise && "link"}
              className={`btn btn-sm ${!props.minimise && "btn-secondary"}`}
              onClick={(id) =>
                handleClickProp({ name: idName, edit: item[idName] })
              }
              title="Edit"
            >
              <span className="fa fa-pencil mr-1"></span>
              {!props.minimise && "Edit"}
            </Button>{" "}
            {more !== undefined && (
              <Button
                className="btn btn-secondary btn-sm"
                onClick={(id) =>
                  handleClickProp({
                    name: idName,
                    edit: item[idName] + "/" + more,
                  })
                }
              >
                <span className={`${moreIcon ?? "fa fa-list"} mr-1`}></span>
                {moretitle}
              </Button>
            )}
            {relatedData && (
              <Button
                className="btn btn-secondary btn-sm"
                onClick={(id) =>
                  handleClickProp({
                    name: idName,
                    itemId: item[idName],
                    relatedData: relatedData,
                  })
                }
              >
                <span className={`fa ${relatedDataIcon} mr-1`}></span>
                {relatedData}
              </Button>
            )}{" "}
            {showDeleteButton && (
              <Button
                variant={props.minimise ? "link" : "danger"}
                className={`btn btn-sm ${!props.minimise && "btn-secondary"}`}
                onClick={(id) =>
                  handleClickProp({ name: idName, delete: item[idName] })
                }
                title="Delete"
              >
                <span className="fa fa-trash mr-1"></span>
                {!props.minimise && "Delete"}
              </Button>
            )}
          </td>
          {orderable && (
            <>
              <td>
                <>
                  <Button
                    variant="link"
                    title="move down 1 position"
                    disabled={index === items.length - 1}
                    onClick={() => handleSort(index, "down")}
                  >
                    <span className="fa fa-angle-down m-0 p-0"></span>
                  </Button>

                  <Button
                    variant="link"
                    title="move to end"
                    disabled={index === items.length - 1}
                    onClick={() => handleSort(index, "end")}
                  >
                    <span className="fa fa-angle-double-down m-0 px-0"></span>
                  </Button>
                  <Button
                    variant="link"
                    title="move up 1 position"
                    disabled={index === 0}
                    onClick={() => handleSort(index, "up")}
                  >
                    <span className="fa fa-angle-up"></span>
                  </Button>
                  <Button
                    variant="link"
                    title="move to start"
                    disabled={index === 0}
                    onClick={() => handleSort(index, "start")}
                  >
                    <span className="fa fa-angle-double-up"></span>
                  </Button>
                </>
              </td>
            </>
          )}
        </tr>
      </>
    ));
  } else
    return (
      <tr>
        <td colSpan="5">
          <Alert variant="danger">
            <span className="fa fa-info mr-2"></span>No items returned. Add one
            to get started
          </Alert>
        </td>
      </tr>
    );
}

export default ItemList;
