import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  Jumbotron,
  Spinner,
  ProgressBar,
  Alert,
} from "react-bootstrap";
import CustomForm from "../../common/CustomForm";

import http from "../../../services/APIService";
import LoadingSpinner from "../../common/LoadingSpinner";
import DeploymentJobStatus from "./DeploymentJobStatus";

function Deployment(props) {
  console.log("deployment props", props);

  const [jobs, setJobs] = useState(undefined);

  const [configurations, setConfigurations] = useState(undefined);
  const [configUiSchema, setConfigUiSchema] = useState(undefined);

  const [loadingState, setLoadingState] = useState(undefined);

  const schema = {
    type: "object",
    title: "Configuration Deployment",
    description:
      "Revise the details of the control system, then choose a configuration to deploy",
    properties: {
      controlSystemId: {
        type: "string",
        title: "Control System GUID",
        readOnly: true,
      },
      name: {
        type: "string",
        title: "Name",
        readOnly: true,
      },
      processor: {
        title: "Processor",
        type: "object",
        properties: {
          processorType: {
            type: "object",
            title: "",
            properties: {
              name: {
                type: "string",
                title: "Type",
                readOnly: true,
              },
            },
          },
          hostname: {
            type: "string",
            title: "Hostname",
            readOnly: true,
          },
          mac: {
            type: "string",
            title: "MAC",
            readOnly: true,
          },
          username: {
            type: "string",
            title: "Username",
            readOnly: true,
          },
          password: {
            type: "string",
            title: "Password",
            readOnly: true,
          },
        },
      },
    },
  };

  const uiSchema = {};

  const configSchema = {
    type: "object",
    title: "",
    required: ["configurationId"],
    properties: {
      configurationId: {
        type: "number",
        title: "Configuration",
      },
      // slot: {
      //   type: "number",
      //   title: "Slot",
      //   min: 1,
      //   max: 10,
      // },
    },
  };

  const handleSubmit = async (e) => {
    console.log("deployment.handleSubmit e", e);

    console.log("props.controlSystem", props.controlSystem);

    const result = await http.callApi(
      "post",
      `jobs/${props.controlSystem.controlSystemId}/deploy`,
      e.formData
    );
    if (result) {
      console.log(
        "deployment post job results",
        result !== undefined ? result.data : "NULL"
      );

      //setConfigurations(result.data);
    }

    getJobs();
  };

  useEffect(() => {
    if (props.controlSystem) {
      //   getConfigurations();
      setConfigUiSchema({
        configurationId: {
          "ui:field": "cg:autoPopulateSelectField",
          "ui:options": {
            "cg:endpoint": `configuration/${props.controlSystem?.controlSystemId}`,
            "cg:titleAccessor": "name",
            "cg:idAccessor": "configurationId",
          },
        },
      });
    }
  }, [props.controlSystem]);

  // get jobs for this control system
  const getJobs = async () => {
    setLoadingState("loading");
    setTimeout(() => {
      setLoadingState("done");
    }, 1000);

    const result = await http.callApi(
      "get",
      "jobs/controlSystem/" + props.controlSystem.controlSystemId + "/deploy"
    );
    if (result) {
      console.log(
        "deployment getJobs() results",
        result !== undefined ? result.data : "NULL"
      );

      setJobs(result.data);
    }
  };

  useEffect(() => {
    if (props.controlSystem) {
      getJobs();
      // getStatusInterval = setInterval(() => {
      //   getJobs();
      // }, 2000);
    }
  }, [props.controlSystem]);

  if (jobs === undefined) {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }

  // if there is a job display status for it
  if (Array.isArray(jobs) && jobs.length > 0) {
    return (
      <DeploymentJobStatus jobs={jobs} controlSystem={props.controlSystem} />
    );
  } else {
    // if no job display control system name, processor hostname, MAC if available (if not request it using a new job and hostname), select a configuration, and show a deploy button
    return (
      <>
        {/* <h4>
        <span className="fa fa-exchange mr-2"></span>Configuration Deployment
      </h4> */}
        {/* <Jumbotron> */}
        <Container>
          <Row>
            <Col>
              <CustomForm
                schema={schema}
                uiSchema={uiSchema}
                formData={props.controlSystem}
                //handleSubmit={handleSubmit}
                //   submitButton={
                //     <>
                //       <span className="fa fa-exchange mr-1"></span>Deploy
                //     </>
                //   }
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {configUiSchema && (
                <CustomForm
                  schema={configSchema}
                  uiSchema={configUiSchema}
                  handleSubmit={handleSubmit}
                  submitButton={
                    <>
                      <span className="fa fa-exchange mr-1"></span>Deploy
                    </>
                  }
                />
              )}
            </Col>
          </Row>
        </Container>
        {/* </Jumbotron> */}
      </>
    );
  }
}

export default Deployment;
