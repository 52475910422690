// AutoPopulateObjectFieldTemplate
// this is used to load a field's schema directly to an object type element
// ie mainly used for loading oneOf schemas built on the backend

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import http from "../../../services/APIService";
import _ from "lodash";
const Mustache = require("mustache");

function AutoPopulateArrayFieldTemplate(props) {
  const params = useParams();
  const {
    id,
    name,
    classNames,
    required,
    idSchema,
    schema,
    //uiSchema,
    formData,
    formContext, // holds extra props (ie configuration, controlSystem)
    onChange,
    registry,
    errorSchema,
    idPrefix,
  } = props;

  const { TitleField, DescriptionField, ObjectField, OneOfField, SchemaField } =
    registry.fields;

  const getData = async (tmpSchema) => {
    console.log("AutoPopulateArrayFieldTemplate props", props);
    let mustachedUISchema = props.uiSchema;
    try {
      mustachedUISchema = JSON.parse(
        Mustache.render(JSON.stringify(props.uiSchema), props.formContext),
        undefined,
        2
      );
    } catch (ex) {
      console.error("Mustache failed on uiSchema", ex);
      mustachedUISchema = props.uiSchema;
    }
    console.log(
      "AutoPopulateArrayFieldTemplate mustachedUISchema",
      mustachedUISchema
    );

    let options = mustachedUISchema["ui:options"];

    if (!options) return;
    let endpoint = options["cg:endpoint"];

    let results = _.get(formContext, endpoint);
    // try get data from local configuration (eg props.FormContext.configuration or props.FormContext.controlSystem) first
    console.log("AutoPopulateArrayFieldTemplate getData()", props, results);

    if (endpoint && !results) {
      const result = await http.callApi("get", endpoint);

      if (!result || result.status !== 200) return;
      results = result.data;
    }

    console.log("AutoPopulateArrayFieldTemplate results", results);

    results = {
      type: "array",
      title: "Input Per Source",
      items: {
        "cg:prefix": "!Source ",
        type: "string",
        title: "Input",
        enum: ["input 1", "input 2", "asd"],
      },
    };

    if (results instanceof Object) {
      formContext.setFieldSchema(name, results);
    }
  };

  useEffect(() => {
    if (schema !== undefined) {
      console.log("AutoPopulateArrayFieldTemplate GETDATA USEEFFECT CALLED");
      getData(schema);
    }
  }, []);

  return <></>;
}

export default AutoPopulateArrayFieldTemplate;
