import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  OverlayTrigger,
  Tooltip,
  Badge,
  Button,
  Alert,
  Modal,
  Image,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function CustomFieldTemplate(props) {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(undefined);

  const {
    id,
    classNames,
    label,
    help,
    required,
    hidden,
    description,
    errors,
    children,
    schema,
    uiSchema,
    rawHelp,
    rawDescription,
  } = props;

  let options = uiSchema["ui:options"];
  let cgTitleField = options && options["cg:titleField"];

  // console.log("CustomFieldTemplate label: ", label, "props", props);
  // console.log("CustomFieldTemplate label: ", label, "children", children);
  //console.log("CustomFieldTemplate props", props);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={classNames}>
      {!hidden &&
        label &&
        schema.type !== "object" &&
        (schema.type !== "array" ||
          (schema.type === "array" && schema.uniqueItems)) && // need to show title if uniqueItems is true (ie is a multi select box) - nonUniqueId array fields are done with arrayFieldTemplate
        schema.type !== "boolean" && ( // getting label for objects/ bools printing twice, let the objectTemplate do it
          // cg title (bold with <hr/> divider or not)
          // could be made collapsable/ accordion ??
          // https://react-bootstrap.netlify.app/components/accordion/
          <label htmlFor={id} className="control-label">
            {/* {"custom: "} */}
            {cgTitleField ? (
              cgTitleField.hr ? (
                <Row className="mt-4">
                  <Col>
                    {cgTitleField.size === "lg" ? (
                      <h5>{label}</h5>
                    ) : (
                      <h6>{label}</h6>
                    )}
                  </Col>
                  <Col>
                    {cgTitleField.size === "lg" ? (
                      <hr className="hr_bold" />
                    ) : (
                      <hr />
                    )}
                  </Col>
                </Row>
              ) : cgTitleField.size === "lg" ? (
                <h5>{label}</h5>
              ) : (
                <h6>{label}</h6>
              )
            ) : (
              <>{label}</>
            )}
            {/* Reuired asterisk and tooltip */}
            {required && (
              <OverlayTrigger
                key={`${id}-required-overlay`}
                placement="top"
                overlay={
                  <Tooltip id={`${id}-required-tooltip`}>
                    This is a required field
                  </Tooltip>
                }
              >
                <i className="fa fa-asterisk fa-1 asterisk"></i>
              </OverlayTrigger>
            )}
            {/* help text tooltip */}
            {rawHelp && (
              <OverlayTrigger
                key={`${id}-help-overlay`}
                placement="top"
                overlay={<Tooltip id={`${id}-help-tooltip`}>{rawHelp}</Tooltip>}
              >
                <i className="fa fa-question-circle  ml-1 question"></i>
              </OverlayTrigger>
            )}
            {/* Link */}
            {uiSchema["ui:help-link"] && (
              <OverlayTrigger
                key={`${id}-link-overlay`}
                placement="top"
                overlay={
                  <Tooltip
                    id={`${id}-link-tooltip`}
                  >{`Go to ${uiSchema["ui:help-link"]}`}</Tooltip>
                }
              >
                <Button
                  onClick={() =>
                    uiSchema["ui:help-link"].includes("http")
                      ? window.open(uiSchema["ui:help-link"], "_blank")
                      : history.push(uiSchema["ui:help-link"])
                  }
                  variant="link"
                  size="sm"
                >
                  <i className="fa fa-link mr-1 link"></i>
                  {uiSchema["ui:help-linkName"]}
                </Button>
              </OverlayTrigger>
            )}
            {/* image modal */}
            {uiSchema["ui:help-image"] && (
              <OverlayTrigger
                key={`${id}-link-overlay`}
                placement="top"
                overlay={
                  <Tooltip
                    id={`${id}-link-tooltip`}
                  >{`Show ${uiSchema["ui:help-image"]}`}</Tooltip>
                }
              >
                <Button
                  onClick={() => {
                    setModalImageUrl("NVXMulticastAddresses.jpg");
                    setShowModal(true);
                  }}
                  variant="link"
                  size="sm"
                >
                  <i className="fa fa-image mr-1 link"></i>
                  {uiSchema["ui:help-imageName"]}
                </Button>
              </OverlayTrigger>
            )}
          </label>
        )}
      {/* // help image modal */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // dialogClassName="modal-large"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="fa fa-info-circle mr-2"></span>
            {uiSchema["ui:help-imageName"] ?? "Information"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image
            src={`../../resources/${uiSchema["ui:help-image"]}`}
            rounded
            fluid
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* {!hidden &&
        rawDescription &&
        schema.type !== "object" &&
        schema.type !== "boolean" && (
          <p>
            <i className="fa fa-info mr-2 mb-0"></i>
            <i>{rawDescription}</i>
          </p>
        )} */}

      {!hidden &&
        schema.type !== "object" &&
        (schema.type !== "array" ||
          (schema.type === "array" && schema.uniqueItems)) && // need to show  if uniqueItems is true (ie is a multi select box) - nonUniqueId array fields are done with arrayFieldTemplate
        schema.type !== "boolean" &&
        description}

      {!hidden && schema.warning && (
        <Alert variant="warning">
          <span className="fa fa-exclamation-triangle mr-2"></span>
          {schema.warning}

          {/* Link */}
          {schema["warning-link"] && (
            <OverlayTrigger
              key={`${id}-warning-link-overlay`}
              placement="top"
              overlay={
                <Tooltip
                  id={`${id}-warning-link-tooltip`}
                >{`Go to ${schema["warning-link"]}`}</Tooltip>
              }
            >
              {/* <a href={schema["warning-link"]}>
                <i className="fa fa-link ml-2"></i>
              </a> */}
              <Alert.Link href={schema["warning-link"]}>
                <i className="fa fa-link ml-2"></i>
              </Alert.Link>
            </OverlayTrigger>
          )}
        </Alert>
      )}

      {children}
      {errors}
      {/* help component (only works if no rules have been added, ie non conditional form) */}
      {!rawHelp && help}
    </div>
  );
}
export default CustomFieldTemplate;
