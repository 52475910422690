import React, { useEffect, useState, useContext } from "react";
import { Button, CardColumns, Card, Breadcrumb } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";

import http from "../../services/APIService";
import Building from "./Building";

import { PieChart } from "react-minimal-pie-chart";

function Location(props) {
  const params = useParams();
  const history = useHistory();
  const browserLocation = useLocation();
  const { locationId } = params;
  let [location, setLocation] = useState(undefined);

  async function getLocation() {
    // const loadingTimeout = setTimeout(() => {
    //   setLoading("done");
    // }, 500);

    const endpoint = `location/${locationId}`;

    const result = await http.callApi("get", endpoint);
    if (result) {
      setLocation(result.data);
      //clearTimeout(loadingTimeout);
      //setLoading("done");
      console.log("getLocations() result", result);
    }
  }

  useEffect(() => {
    if (locationId > 0) getLocation();
  }, [locationId]);

  return (
    <>
      <div>
        <h5>
          <span className="fa fa-university mr-2"></span>
          {location?.name}
        </h5>
      </div>

      {/* show all locations in a card each */}
      <CardColumns>
        {location?.buildings?.map((building) => {
          console.log("location", location);
          console.log("building", building);
          return (
            <Card style={{ width: "12rem" }} className="mx-1">
              <Card.Header>
                <span className="fa fa-building mr-2"></span>Building
              </Card.Header>
              <Card.Body>
                <Card.Title>{building.name}</Card.Title>
                {Array.isArray(building.controlSystems) &&
                building.controlSystems.length > 0 ? (
                  <>
                    <Card.Text>
                      Control Systems: {building.controlSystems.length}
                      {/* <br />
                          ControlSystems:{" "}
                          {location.buildings[0].controlSystems.length} */}
                      <PieChart
                        className="m-1"
                        data={[
                          { title: "One", value: 10, color: "#9db8cf" },
                          { title: "Two", value: 15, color: "#316490" },
                          { title: "Three", value: 20, color: "#5c96c7" },
                        ]}
                      />
                    </Card.Text>

                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => {
                        history.push(
                          `${browserLocation.pathname}/building/${building.buildingId}`
                        );
                        console.log(
                          "browserLocation",
                          browserLocation,
                          "go to ",
                          `/building/${building.buildingId}`
                        );
                      }}
                    >
                      <span className="fa fa-cogs mr-2"></span>
                      Control&nbsp;Systems
                    </Button>
                  </>
                ) : (
                  <Card.Text>
                    No Control Systems for this building
                    <br />
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() =>
                        history.push(
                          `/controlSystem/new/location/${locationId}/building/${building?.buildingId}`
                        )
                      }
                    >
                      <span className="fa fa-plus mr-1"></span>Add
                    </Button>
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          );
        })}
      </CardColumns>
      {/* end locations */}
    </>
  );
}

export default Location;
