import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Button, Table } from "react-bootstrap";
import _ from "lodash";
import { toast } from "react-toastify";

import http from "../../../services/APIService";
import LoadingSpinner from "../../common/LoadingSpinner";
import ModalForm from "../../common/ModalForm";
import { confirmAlert } from "react-confirm-alert";
import DynamicForm from "../../common/DynamicForm";
import ItemList from "../../admin/common/itemList";

function NVXEndpointCard(props) {
  let { id: controlSystemId } = useParams();
  const params = useParams();
  const [modalShow, setModalShow] = useState({});
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState("loading");
  const [schema, setSchema] = useState(props.schema ?? undefined);

  const Mustache = require("mustache");

  const handleClose = () => {
    setModalShow({ state: false });
  };

  const handleShow = (id, name, item) => {
    console.log(
      "handLeShow ",
      item,
      " prop name",
      props.name,
      "id",
      id,
      "name",
      name
    );
    setModalShow({
      state: true,
      id: id,
      name: name,
    });
  };

  const handleClick = (e) => {
    console.log("NVXEndpointCard.handleClick()", e);
    if (e.edit !== undefined) {
      console.log("handleClick", e.edit);
      //props.history.push(`${endpoint}/${e.edit}`);
      handleShow(e.edit);
    } else if (e.delete !== undefined) {
      console.log("handleClick", e.delete);
      handleDelete(e.delete);
    }
  };

  const updateListOrder = async (idArray) => {
    console.log("NVXEndpointCard.updateListOrder()", idArray);
    const result = await http.callApi(
      "post",
      `${props.name}/sort/${params.id}`,
      idArray
    );
    setItems(result.data);
  };

  const handleDelete = async (itemId) => {
    confirmAlert({
      title: "Please Confirm",
      message:
        "This will permenently delete the selected item. Do you wish to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const result = await http.callApi(
                "delete",
                props.name + "/" + controlSystemId + "/" + itemId
              );
              if (result) getItems();

              toast.info("Item deleted successfully");
              props.performUpdate();
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  async function getItems() {
    const loadingTimeout = setTimeout(() => {
      setLoading("done");
    }, 500);

    console.log(
      "use props.formData (",
      props.name,
      ") ",
      props.formData,
      Array.isArray(props.formData)
    );
    if (props.formData !== undefined) {
      let dataArray = [];
      if (!_.isEmpty(props.formData)) {
        if (!Array.isArray(props.formData)) dataArray.unshift(props.formData);
        else dataArray = props.formData;
      }
      console.log("dataArray", dataArray);
      setItems(dataArray);
      clearTimeout(loadingTimeout);
      setLoading("done");
    } else {
      let id = props.currentId !== undefined ? "/" + props.currentId : "";
      const endpoint = props.name + "/" + controlSystemId + id;
      console.log("EquipmentCard calling endpoint", props.name, endpoint);
      const result = await http.callApi("get", endpoint);
      if (result && Array.isArray(result.data)) {
        setItems(result.data); // will come in as an array from backend
        clearTimeout(loadingTimeout);
        setLoading("done");
      }
    }
  }

  async function getSchema(controller) {
    //toast("getting schema from server");
    const loadingTimeout = setTimeout(() => {
      setLoading("done");
    }, 10000);
    const result = await http.callApi("get", controller);
    if (result) {
      setSchema(result.data);

      clearTimeout(loadingTimeout);
      setLoading("done");
      console.log(props.name + " getSChema() result", result);
    }
  }

  useEffect(() => {
    console.log(
      "NVX EQUIPMENTCARD PROPS>DATA CHANGED or PROPS>SCHEMA CHANGED",
      props.formData
    );
    if (props.schema === undefined && props.schemaController !== undefined)
      getSchema(Mustache.render(props.schemaController, params));

    getItems();
  }, [controlSystemId, props.formData]);

  useEffect(() => {
    if (props.schema) {
      let schema = props.schema;
      // _.set(schema, "controlSystem", props.controlSystem);
      setSchema(schema);
      console.log("schema ==>", schema);
    }
  }, [props.schema]);

  const handleFormDataUpdateFromModal = (result) => {
    console.log(
      "!!!!!!!!!!! equipmentCard handleFormDataUpdateFromModal() updatedController",
      props.name,
      result
    );
    console.log("props.performUpdate", typeof props.performUpdate);
    if (typeof props.performUpdate === "function") {
      props.performUpdate();
    } else {
      getItems();
    }
  };

  const handleLocalFormChange = async (field, value) => {
    console.log("handleLocalFormChange", field, value);
    let patchData = [
      {
        value: value,
        path: `/${field}`,
        op: "replace",
      },
    ];

    const result = await http.callApi(
      "patch",
      `controlSystem/${controlSystemId}`,
      patchData
    );
    if (result) {
      toast.success(props.name + " updated successfully");
      console.log("result data ::: ", result);
      //props.handleFormDataUpdate(props.name, result);
      //props.close();
    }
  };

  return (
    <Card>
      <Card.Header>
        <span className={`fa ${props.icon} mr-2`}></span>
        {props.header}
      </Card.Header>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>

        {!_.isEmpty(items) && Array.isArray(items) && items.length > 0 ? (
          <Table striped bordered hover size="sm">
            <tbody>
              <ItemList
                items={items}
                idName={props.idName}
                handleClickProp={handleClick}
                itemOrderUpdated={updateListOrder}
                showDeleteButton
                orderable={props.orderable}
                nameField="name"
                minimise
                commsInTitle={props.commsInTitle}
              />
            </tbody>
          </Table>
        ) : loading === "loading" ? (
          <LoadingSpinner />
        ) : (
          <LoadingSpinner state="no data" />
        )}

        <hr />
        {/* {console.log(props.singleInstance, Array.isArray(items), items.length)} */}
        {!props.singleInstance ||
        (props.singleInstance && Array.isArray(items) && items.length === 0) ? (
          <Button
            variant="secondary"
            className="btn btn-sm"
            onClick={() => handleShow("new", props.name)}
          >
            <span className="fa fa-plus mr-2"></span>Add New
          </Button>
        ) : (
          <></>
        )}

        {schema ? (
          <ModalForm
            controlSystem={props.controlSystem}
            schema={schema}
            uiSchema={props.uiSchema}
            name={props.name}
            idName={props.idName}
            modalFooter={props.footer}
            id={
              modalShow.state //&& modalShow.name === props.name
                ? modalShow.id
                : 0
            }
            show={modalShow.state} //&& modalShow.name === props.name}
            close={() => handleClose()}
            handleFormDataUpdate={handleFormDataUpdateFromModal}
            showCopyButtons={props.showCopyButtons}
            formTitle="Update Existing"
            formTitleIcon="fa fa-pencil"
            schemaRules={props.schemaRules}
            formData={props.formData}
          />
        ) : (
          <LoadingSpinner />
        )}
        {/* {props.secondarySchemaController && (
          <>
            <ModalForm
              schema={secondarySchema}
              //uiSchema={props.uiSchema}
              name="configuration"
              idName={props.idName}
              //modalFooter={props.footer}
              id={
                modalShow.state && modalShow.name === "secondary"
                  ? modalShow.id
                  : 0
              }
              show={modalShow.state && modalShow.name === "secondary"}
              close={() => handleClose()}
              handleFormDataUpdate={handleFormDataUpdateFromModal}
              controlSystemId={controlSystemId}
              showCopyButtons={props.showCopyButtons}
              formTitle="Hardware Configuration"
              formTitleIcon="fa fa-server"
            />
            <Button
              variant="link"
              className="btn btn-sm"
              onClick={() => handleShow(1, "secondary")}
            >
              Inputs
            </Button>
          </>
        )} */}

        {props.localSchema && (
          <>
            <hr />
            {console.log("fmd controlSystem", props.controlSystem)}
            <DynamicForm
              schema={props.localSchema}
              uiSchema={props.localUiSchema}
              // handleFieldChange={(field, value) =>
              //   console.log("local handle field change", field, value)
              // }
              handleFieldChange={handleLocalFormChange}
              hideSubmitAndCancel
              formData={props.controlSystem}
              showCopyButtons={false}
            />
          </>
        )}
      </Card.Body>
      {props.cardFooter && (
        <Card.Footer>
          <small className="text-muted">{props.cardFooter}</small>
        </Card.Footer>
      )}
    </Card>
  );
}

export default NVXEndpointCard;
