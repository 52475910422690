import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CardColumns, CardDeck } from "react-bootstrap";
import _ from "lodash";

import http from "../../services/APIService";
import EquipmentCard from "./Equipment/EqupmentCard";
import LoadingSpinner from "../common/LoadingSpinner";
import SwitcherCard from "./Equipment/SwitcherCard";
import { toast } from "react-toastify";
import NVXEndpointCard from "./Equipment/NVXEndpointCard";

function ControlSystemNVX(props) {
  let { id: controlSystemId } = useParams();
  const params = useParams();
  const [switcher, setSwitcher] = useState({});
  const [schema, setSchema] = useState({});
  const [inputs, setInputs] = useState([]);
  const [loading, setLoading] = useState("loading");

  async function getCurrent() {
    //toast("getCurrent()");

    const loadingTimeout = setTimeout(() => {
      setLoading("done");
    }, 500);

    const endpoint = `switcher/${controlSystemId}/${params.switcherId}`;

    const result = await http.callApi("get", endpoint);
    if (result) {
      setSwitcher(undefined);
      setSwitcher(result.data);
      clearTimeout(loadingTimeout);
      setLoading("done");
      console.log(" getSwitcher() result", result);
    }
  }

  useEffect(() => {
    getCurrent();
  }, [controlSystemId]);

  const encoderSchema = {
    title: "NVX Encoder",
    type: "object",
    properties: {
      endpoint: {
        type: "object",
        title: "",
        required: [
          "nvxEndpointTypeId",
          "name",
          "hostname",
          "ipId",
          "multicastAddress",
        ],
        properties: {
          controlSystemId: {
            type: "string",
          },
          nvxEncoderId: {
            type: "number",
          },
          identifier: {
            type: "string",
          },
          nvxEndpointTypeId: {
            type: "number",
            title: "Type",
          },
          name: {
            type: "string",
            title: "Description",
          },
          hostname: {
            type: "string",
            title: "Hostname",
            description:
              "The value will be used to build the RTSP stream URI in the format rtsp://[hostname]/live.sdp, plus set the IP Table on boot",
          },
          ipId: {
            type: "number",
            title: "IP ID",
          },
          multicastAddress: {
            type: "string",
            title: "Multicast Address",
            description:
              "The value as entered on the transmitter's stream setup webpage",
          },
          username: {
            type: "string",
            title: "Username",
          },
          password: {
            type: "string",
            title: "Password",
          },
          danteAES67Audio: {
            type: "number",
            title: "Dante/ AES67 Audio",
            description:
              "If below is set to anything other than No Change, this Input Route will be set when the system turns on (for supported devices only)",
            enum: [0, 1, 2, 3, 4, 5, 6, 7],
            enumNames: [
              "None",
              "Auto",
              "Input 1",
              "Input 2",
              "Analog",
              "Primary Stream",
              "Secondary Stream",
              "No Change",
            ],
            default: 7,
          },
        },
      },
    },
  };

  const encoderUiSchema = {
    endpoint: {
      "ui:placeholder": "--select--",
      "ui:field": "cg:autoPopulateOneOfField",
      "ui:options": {
        "cg:endpoint": `nvxEndpointType`,
        "cg:titleAccessor": "name",
        "cg:idAccessor": "nvxEndpointTypeId",
        "cg:filter": "e => e.format == 0 || e.format == 2", // enum EndpointFormat { Encoder, Decoder, Both }
      },

      nvxEncoderId: {
        "ui:widget": "hidden",
      },
      controlSystemId: {
        "ui:widget": "hidden",
      },
      identifier: {
        "ui:widget": "hidden",
      },
      nvxEndpointTypeId: {
        "ui:help-link": "https://vimeo.com/226371363",
        "ui:help-linkName": "DM NVX Series Setup Video",
      },
      ipId: {
        "ui:field": "cg:autoPopulateSelectField",
        "ui:options": {
          "cg:endpoint": "controlSystem.occupiedIpIds",
          "cg:titleAccessor": "description",
          "cg:idAccessor": "itemId",
          "cg:pad": {
            min: 3,
            max: 254,
          },
          "cg:displayHex": true,
          "cg:validate": false,
        },
        "ui:placeholder": "--select--",
        "ui:help": "Values are in HEX",
      },
      multicastAddress: {
        "ui:placeholder": "239.x.x.x",
        "ui:help-image": "NVXMulticastAddressing.jpg",
        //"ui:help-imageName": "NVX Multicast Addressing",
      },
      danteAES67Audio: {
        "ui:help": "Auto = Follow Audio Source",
      },
    },
  };

  const decoderSchema = {
    title: "NVX Decoder",
    type: "object",
    properties: {
      endpoint: {
        type: "object",
        title: "",
        required: ["nvxEndpointTypeId", "name", "hostname", "ipId"],
        properties: {
          controlSystemId: {
            type: "string",
          },
          nvxDecoderId: {
            type: "number",
          },
          identifier: {
            type: "string",
          },
          nvxEndpointTypeId: {
            type: "number",
            title: "Type",
          },
          name: {
            type: "string",
            title: "Description",
          },
          hostname: {
            type: "string",
            title: "Hostname",
          },
          ipId: {
            type: "number",
            title: "IP ID",
          },
          username: {
            type: "string",
            title: "Username",
          },
          password: {
            type: "string",
            title: "Password",
          },
        },
      },
    },
  };

  const decoderUiSchema = {
    endpoint: {
      "ui:placeholder": "--select--",
      "ui:field": "cg:autoPopulateOneOfField",
      "ui:options": {
        "cg:endpoint": `nvxEndpointType`,
        "cg:titleAccessor": "name",
        "cg:idAccessor": "nvxEndpointTypeId",
        "cg:filter": "e => e.format == 1 || e.format == 2", // enum EndpointFormat { Encoder, Decoder, Both }
      },
      nvxDecoderId: {
        "ui:widget": "hidden",
      },
      controlSystemId: {
        "ui:widget": "hidden",
      },
      identifier: {
        "ui:widget": "hidden",
      },
      nvxEndpointTypeId: {
        "ui:help-link": "https://vimeo.com/226371363",
        "ui:help-linkName": "DM NVX Series Setup Video",
      },
      ipId: {
        "ui:field": "cg:autoPopulateSelectField",
        "ui:options": {
          "cg:endpoint": "controlSystem.occupiedIpIds",
          "cg:titleAccessor": "description",
          "cg:idAccessor": "itemId",
          "cg:pad": {
            min: 3,
            max: 254,
          },
          "cg:displayHex": true,
          "cg:validate": false,
        },
        "ui:placeholder": "--select--",
        "ui:help": "Values are in HEX",
      },
    },
  };
  const refresh = () => {
    console.log("TIME TO REFRESH");
    props.getControlSystem();
    getCurrent();
  };
  if (loading === "loading") return <h4>loading</h4>;
  return (
    <>
      <h5>NVX Configuration</h5>
      <CardDeck>
        {!_.isEmpty(switcher) ? (
          <>
            <NVXEndpointCard
              controlSystem={props.controlSystem}
              icon="fa-server"
              header="NVX Encoders"
              title="Encoders"
              name="nvxEncoder"
              description=""
              footer="If you require assistance, contact Control Gadgets"
              idName="nvxEncoderId"
              schema={encoderSchema} // if no schema defined, it get one constructed on the server using schemaController prop
              //schemaController="nvxEncoder/schema"
              uiSchema={encoderUiSchema}
              formData={props.controlSystem?.NVXEncoders}
              formTitle="NVX Encoder"
              formTitleIcon="fa fa-pencil"
              showDelete
              performUpdate={refresh}
              orderable
              commsInTitle
            />

            <NVXEndpointCard
              controlSystem={props.controlSystem}
              icon="fa-server"
              header="NVX Decoders"
              title="Decoders"
              name="nvxDecoder"
              description=""
              footer="If you require assistance, contact Control Gadgets"
              idName="nvxDecoderId"
              schema={decoderSchema} // if no schema defined, it get one constructed on the server using schemaController prop
              //schemaController="nvxEncoder/schema"
              uiSchema={decoderUiSchema}
              data={props.controlSystem?.NVXDecoders}
              formTitle="NVX Decoder"
              formTitleIcon="fa fa-pencil"
              showDelete
              performUpdate={refresh}
              orderable
              commsInTitle
            />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </CardDeck>
    </>
  );
}

export default ControlSystemNVX;
