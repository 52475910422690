import React, { useEffect, useState, useContext } from "react";

import { Switch, useParams, useLocation, Redirect } from "react-router-dom";

import _ from "lodash";

import UserContext from "../../contexts/userContext";
import ControlSystemContext from "../../contexts/ControlSystemContext";

import http from "../../services/APIService";
import LoadingSpinner from "../common/LoadingSpinner";
import TabbedNav from "../common/TabbedNav";
import ControlSystemEquipment from "./ControlSystemEquipment";
import ControlSystemSwitcherDMFrame from "./ControlSystemDMFrame";
import ControlSystemSwitcherDMPS from "./ControlSystemDMPS";
import ProtectedRoute from "../common/protectedRoute";

import ControlSystemNVX from "./ControlSystemNVX";
import Configuration from "./Configuration";
import ConfigurationForm from "./ConfigurationForm";

import GenerateConfigFile from "./GenerateConfigFile";
import ControlSystemForm from "./ControlSystemForm";
import ControlSystemProcessor from "./ControlSystemProcessor";
import { toast } from "react-toastify";
import Deployment from "./Deployment/Deployment";
import Analytics from "./Analytics/Analytics";

let apiEndpointName = "controlsystem";

function ControlSystem(props) {
  const params = useParams();
  const { id: controlSystemId } = useParams();
  const user = useContext(UserContext);
  const [controlSystem, setControlSystem] = useState(undefined);

  const [loading, setLoading] = useState("loading");
  let { id } = useParams();

  const [processor, setProcessor] = useState(undefined);

  const [nav, setNav] = useState(undefined);

  const getControlSystem = async () => {
    console.log("Get Control SyStem");
    toast("Refresh Control System");
    const result = await http.callApi(
      "get",
      "controlSystem/" + controlSystemId
    );
    if (result) {
      console.log(
        "controlsystem :{} result.Data ",
        result !== undefined ? result.data : "NULL"
      );

      setControlSystem(result.data);
      setProcessor(result.data.processor);
    }
  };

  useEffect(() => {
    console.log("get control System useeffect", user, controlSystemId);
    // control system
    if (!_.isEmpty(controlSystemId) && controlSystemId !== "new") {
      getControlSystem();
    } else setControlSystem(undefined);
  }, [user, controlSystemId, params]);

  useEffect(() => {
    if (
      controlSystem &&
      !_.isEmpty(processor) &&
      processor !== undefined &&
      processor != null
    ) {
      setNav(fullNav);
    } else {
      setNav(defaultNav);
    }
  }, [processor, controlSystem]);

  const defaultNav = {
    items: [
      {
        name: "System",
        href: "/controlsystem/" + id + "",
        scope: "av.manager",
      },
    ],
  };

  const fullNav = {
    items: [
      {
        name: "System",
        href: "/controlsystem/" + id + "",
        scope: "av.manager",
      },
      {
        name: "Processor",
        href: "/controlsystem/" + id + "/processor",
        scope: "av.manager",
      },
      {
        name: "Equipment",
        href: "/controlsystem/" + id + "/equipment",
        scope: "av.manager",
      },
      {
        name: "Configuration",
        href: "/controlsystem/" + id + "/configuration",
        scope: "av.manager",
      },
      {
        name: "Deployment",
        href: "/controlsystem/" + id + "/deployment",
        scope: "av.manager",
      },
      {
        name: "Live Info",
        href: "/controlsystem/" + id + "/analytics",
        scope: "av.manager",
      },
    ],
  };

  return (
    <>
      <div className="display-4 my-0 py-0">
        <span className="fa fa-cogs mr-2"></span>Control System
      </div>

      {controlSystem && (
        <h5>
          <span className="fa fa-gears mr-2"></span>
          {controlSystem.name}
        </h5>
      )}

      {nav && <TabbedNav path={props.match.path} navData={nav} />}

      <ControlSystemContext.Provider value={controlSystem}>
        {/* {controlSystem ? (
          <> */}
        <Switch>
          <ProtectedRoute
            path="/controlsystem/:id/configuration/:configurationId/section/:sectionTypeId/:sectionTypeName"
            component={ConfigurationForm}
            controlSystem={controlSystem}
          />

          <ProtectedRoute
            exact
            path="/controlsystem/:id/configuration/:configurationId/generate"
            component={GenerateConfigFile}
            controlSystem={controlSystem}
          />

          <ProtectedRoute
            exact
            path="/controlsystem/:id/configuration/:configurationId"
            component={ConfigurationForm}
            controlSystem={controlSystem}
          />

          <ProtectedRoute
            exact
            path="/controlsystem/:id/configuration"
            component={Configuration}
            controlSystem={controlSystem}
          />

          <ProtectedRoute
            path="/controlsystem/:id/nvx/:switcherId"
            component={ControlSystemNVX}
            controlSystem={controlSystem}
            getControlSystem={getControlSystem}
          />

          <ProtectedRoute
            path="/controlsystem/:id/dmcardframe/:switcherId"
            component={ControlSystemSwitcherDMFrame}
            controlSystem={controlSystem}
            getControlSystem={getControlSystem}
          />

          <ProtectedRoute
            path="/controlsystem/:id/dmbladeframe/:switcherId"
            component={ControlSystemSwitcherDMFrame}
            controlSystem={controlSystem}
            getControlSystem={getControlSystem}
          />

          <ProtectedRoute
            path="/controlsystem/:id/dmps/:switcherId"
            component={ControlSystemSwitcherDMPS}
            controlSystem={controlSystem}
            getControlSystem={getControlSystem}
          />

          <ProtectedRoute
            path="/controlsystem/:id/equipment"
            component={ControlSystemEquipment}
            controlSystem={controlSystem}
            getControlSystem={getControlSystem}
          />

          <ProtectedRoute
            path="/controlsystem/:id/processor"
            component={ControlSystemProcessor}
            controlSystem={controlSystem}
          />

          <ProtectedRoute
            exact
            path="/controlsystem/:id/location/:locationId/building/:buildingId"
            component={ControlSystemForm}
          />

          <ProtectedRoute
            path="/controlsystem/:id/deployment"
            component={Deployment}
            controlSystem={controlSystem}
          />

          <ProtectedRoute
            path="/controlsystem/:id/analytics"
            component={Analytics}
            controlSystem={controlSystem}
          />

          <ProtectedRoute
            exact
            path="/controlsystem/:id"
            component={ControlSystemForm}
            controlSystem={controlSystem}
          />

          <Redirect to="/not-found" />
        </Switch>
        {/* </>
        ) : (
          <Switch>
            <ProtectedRoute
              exact
              path="/controlsystem/:id/location/:locationId/building/:buildingId"
              component={ControlSystemForm}
            />

            <ProtectedRoute
              exact
              path="/controlsystem/:id"
              component={ControlSystemForm}
              controlSystem={controlSystem}
            />

            <Redirect to="/not-found" />
          </Switch>
        )} */}
      </ControlSystemContext.Provider>
    </>
  );
}

export default ControlSystem;
