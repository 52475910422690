import SchemaField from "@rjsf/core/lib/components/fields/SchemaField";
import React from "react";

function CustomSchemaField(props, rootIdSchema) {
  //console.log("CustomSchemaField props", props, rootIdSchema);
  if (props.registry.definitions) {
    console.log("stop here");
  }
  const customProps = {};
  try {
    // if (props.idSchema && props.idSchema["$id"] === "root") {
    //   rootIdSchema = { ...props.idSchema };
    //   //console.log("CustomSchemaField root props", props);
    // }

    //Only process if we are dealing with a field, not the parent object
    if (props.name) {
      //console.log("customSchemaField prop name", props.name, props);
      const formContext = props.registry.formContext;
      //Store the original onChange event provided to the SchemaField
      //as well as the name of the field
      const { onChange, name } = props;
      //Provide a new onChange event for the SchemaField
      customProps.onChange = function (formData) {
        //Call the custom handler provided in the formContext, if it exists,
        //with the field name and new value
        if (
          formContext &&
          formContext.onFieldChange &&
          typeof formContext.onFieldChange === "function"
        ) {
          formContext.onFieldChange(name, formData, props.idSchema);
        }
        //Call the original onChange handler
        onChange(formData);
      };
    }
    return <SchemaField {...props} />;
  } catch (e) {
    console.exception("CustomSchemaField exception ", e);
  }
}

export default CustomSchemaField;
