import React, { useEffect, useState, useContext } from "react";
import {
  Alert,
  Table,
  Button,
  CardColumns,
  Card,
  Breadcrumb,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
import { toast } from "react-toastify";

import http from "../../services/APIService";

import SmartItemList from "../common/ItemList/SmartItemList";

function Building(props) {
  const params = useParams();
  const history = useHistory();
  const browserLocation = useLocation();
  const { locationId, buildingId } = params;
  const [building, setBuilding] = useState(undefined);
  const [processorTypes, setProcessorTypes] = useState(undefined);

  console.log("locationId", locationId, "buildingId", buildingId);

  async function getBuilding() {
    // const loadingTimeout = setTimeout(() => {
    //   setLoading("done");
    // }, 500);

    const endpoint = `building/${buildingId}`;

    const result = await http.callApi("get", endpoint);
    if (result) {
      setBuilding(undefined);
      setBuilding(result.data);
      //clearTimeout(loadingTimeout);
      //setLoading("done");
      console.log(" getBuilding() result", result);
    }
  }

  async function getProcessorTypes() {
    const endpoint = `processorType`;

    const result = await http.callApi("get", endpoint);
    if (result) {
      setProcessorTypes(result.data);
      //clearTimeout(loadingTimeout);
      //setLoading("done");
      console.log(" getProcessorTypes() result", result);
    }
  }

  useEffect(() => {
    getProcessorTypes();
    getBuilding();
  }, []);

  if (!building) {
    return <Alert variant="secondary">Nothing Returned</Alert>;
  }
  const handleSelectItem = (item) => {
    console.log("item", item);
    history.push(`/controlSystem/${item}`);
  };

  const handleRemoveItem = async (item) => {
    console.log("handleRemoveItem", item);

    let endpoint = `controlSystem/${item.controlSystemId}`;
    const result = await http.callApi("delete", endpoint);
    if (result) {
      toast("Item deleted successfully");
    }
    getBuilding();
  };

  return (
    <>
      <span className="fa fa-university mr-2"></span>
      {building?.location?.name}
      <h5>
        <span className="fa fa-building mr-2"></span>
        {building?.name}
      </h5>

      <hr />
      <h5>
        <span className="fa fa-cogs mr-2"></span>Control Systems{" "}
        <Button
          size="sm"
          variant="link"
          onClick={() =>
            history.push(
              `/controlSystem/new/location/${locationId}/building/${buildingId}`
            )
          }
        >
          <span className="fa fa-plus mr-1"></span>Add
        </Button>
      </h5>
      <SmartItemList
        listName="Control System"
        nameAccessor="name"
        idAccessor="controlSystemId"
        fields={[
          {
            title: "Name",
            fieldAccessor: "name",
          },
          {
            title: "System Type",
            fieldAccessor: "systemTypeName",
          },
          {
            title: "Processor",
            fieldAccessor: "processor.processorType.name",
          },
          {
            title: "Hostname",
            fieldAccessor: "processor.hostname",
          },
          {
            title: "MAC",
            fieldAccessor: "processor.mac",
          },
        ]}
        data={building.controlSystems}
        onSelectItem={handleSelectItem}
        onRemoveItem={handleRemoveItem}
        pageSize={5}
        filterByType="Processor Type"
        filterByList={processorTypes}
        filterByAllowNull
      />
    </>
  );
}

export default Building;
