import React, { useEffect, useState } from "react";

import {
  Card,
  Button,
  Container,
  Row,
  Col,
  Accordion,
  Spinner,
  ProgressBar,
  Alert,
} from "react-bootstrap";

import { toast } from "react-toastify";
import http from "../../../services/APIService";
import LoadingSpinner from "../../common/LoadingSpinner";
let getStatusInterval;

function DeploymentJobStatus(props) {
  const [loadingState, setLoadingState] = useState(undefined);
  const [jobs, setJobs] = useState(undefined);

  // get deploy jobs for this control system
  const getJobs = async () => {
    setLoadingState("loading");
    setTimeout(() => {
      setLoadingState("done");
    }, 1000);

    const result = await http.callApi(
      "get",
      `jobs/controlSystem/${props.controlSystem.controlSystemId}/deploy`
    );
    if (result) {
      console.log(
        "deployment getJobs() results",
        result !== undefined ? result.data : "NULL"
      );

      setJobs(result.data);
    }
  };

  useEffect(() => {
    if (props.jobs) {
      setJobs(props.jobs);
      if (getStatusInterval) clearInterval(getStatusInterval);
      getStatusInterval = setInterval(() => {
        getJobs();
      }, 2000);
    }

    return () => {
      console.log("JobStatus useEffect() UNMOUNTING >> clearInterval()");
      if (getStatusInterval) clearInterval(getStatusInterval);
    };
  }, [props.jobs]);

  const Loading = (props) => {
    if (loadingState === "loading") {
      return (
        <>
          <Spinner animation="border" size="sm" /> Refreshing..
        </>
      );
    }
    return <>&nbsp;</>;
  };

  const Progress = (props) => {
    if (props.type.toLowerCase() === "deploy") {
      //enum JobStatusType { Empty, Created, SocketInit, Progcomments, FirmwareVersion, ReadyForTransfer, ProcessorGetfile, ProcessorReceivingfile, FileReceivedByProcessor, FileReceiveError, SendingProgreset,
      // StoppingProgram, ProgramStopped, LoadingProgram, ProgramLoaded, Failure, SendingConsoleCommand, ConsoleReplyReceived }
      let level = 0;
      switch (props.status) {
        default:
          level = 0;
          break;
        case "Created":
          level = 5;
          break;
        case "SocketInit":
          level = 10;
          break;
        case "Progcomments":
          level = 14;
          break;
        case "FirmwareVersion":
          level = 20;
          break;
        case "ReadyForTransfer":
          level = 25;
          break;
        case "ProcessorCheckForOldFile":
          level = 30;
          break;
        case "ProcessorDirResponse":
          level = 37;
          break;
        case "ProcessorGetfile":
          level = 40;
          break;
        case "ProcessorReceivingfile":
          level = 42;
          break;
        case "FileReceivedByProcessor":
          level = 46;
          break;
        case "SendingProgreset":
          level = 48;
          break;
        case "StoppingProgram":
          level = 50;
          break;
        case "ProgramStopped":
          level = 55;
          break;
        case "LoadingProgram":
          level = 76;
          break;
        case "ProgramLoaded":
          level = 99;
          break;

        case "Complete":
          level = 100;
          break;

        case "Failure":
          level = -1;
          break;
        case "FileReceiveError":
          level = -1;
          break;
      }

      return (
        <>
          <ProgressBar
            now={level}
            animated={props.status.toLowerCase() !== "complete"}
            variant={
              props.status === "Failure" || props.status === "FileReceiveError"
                ? "danger"
                : "primary"
            }
            label={`${level}%`}
          />
        </>
      );
    }
    return <></>;
  };

  if (jobs === undefined) {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }
  const handleDelete = async (job) => {
    console.log("handleDelete ", job);
    try {
      await http.callApi("delete", `jobs/${job.jobId}`);
      toast.info("Item deleted successfully");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleArchive = async (job) => {
    console.log("handleArchive ", job);
    let patchData = [
      {
        value: new Date(Date.now()),
        path: `/ArchivedTimeStampUTC`,
        op: "replace",
      },
    ];
    try {
      const result = await http.callApi(
        "patch",
        `jobs/${job.jobId}`,
        patchData
      );
      if (result) {
        toast.info("Item completed successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h5>
        <i className="fas fa-wrench mr-2"></i>Current Jobs
      </h5>
      <Accordion
        defaultActiveKey={
          Array.isArray(jobs) && jobs.length > 0 ? jobs[0].jobId : 0
        }
      >
        {jobs.map((job) => (
          <Card key={job.jobId}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={job.jobId}>
                {job.jobStatus?.status.toLowerCase().includes("complete") ? (
                  <>
                    <span className="fa fa-check mr-2 text-success"></span>
                    Job Completed: {job.name}
                  </>
                ) : (
                  <>
                    {job.jobStatus?.status.toLowerCase().includes("error") ||
                    job.jobStatus?.status.toLowerCase().includes("fail") ? (
                      <>
                        <span className="fa fa-exclamation-triangle mr-2 text-danger"></span>
                        Job Failed: {job.name}
                      </>
                    ) : (
                      <>
                        <span className="fa fa-spinner fa-spin mr-2"></span>Job
                        in Progress: {job.name}
                      </>
                    )}
                  </>
                )}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={job.jobId}>
              <Card.Body>
                {/* <Loading state={loadingState} /> */}
                <Card.Title>{job.name}</Card.Title>

                <Container>
                  <Row>
                    <Col sm="2">Type: </Col>
                    <Col>
                      <b>{job.type.toUpperCase()}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="2">Status: </Col>
                    <Col>{job.jobStatus?.status}</Col>
                  </Row>
                  <Row>
                    <Col sm="2">Firmware Version: </Col>
                    <Col>{job.jobStatus?.firmwareVer}</Col>
                  </Row>
                  <Row>
                    <Col sm="2">Configured Version: </Col>
                    <Col>{job.jobStatus?.configuredVer}</Col>
                  </Row>
                  <Row>
                    <Col sm="2">Console Reply: </Col>
                    <Col>{job.jobStatus?.consoleReply}</Col>
                  </Row>
                  <Row>
                    <Col sm="2">Progress: </Col>
                    <Col>
                      {job.jobStatus?.status
                        .toLowerCase()
                        .includes("complete") ? (
                        <>
                          <span className="fa fa-check mr-2 text-success"></span>
                          Complete
                        </>
                      ) : (
                        <>
                          {job.jobStatus?.status
                            .toLowerCase()
                            .includes("error") ||
                            (job.jobStatus?.status
                              .toLowerCase()
                              .includes("fail") && <>fail</>)}
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {job.jobStatus?.status.toLowerCase().includes("error") ||
                      job.jobStatus?.status.toLowerCase().includes("fail") ? (
                        <Alert variant="danger">
                          Error: {job.jobStatus?.status} <br />
                          Reason:{job.jobStatus?.failureReason}
                          <br />
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => handleArchive(job)}
                          >
                            Try Again
                          </Button>
                          {/* <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDelete(job)}
                          >
                            Delete
                          </Button> */}
                        </Alert>
                      ) : (
                        <Progress
                          type={job.type}
                          status={job.jobStatus?.status}
                        />
                      )}
                    </Col>
                    <Col>&nbsp;</Col>
                  </Row>
                  <Row>
                    <Col>
                      {job.jobStatus?.status
                        .toLowerCase()
                        .includes("complete") && (
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => handleArchive(job)}
                        >
                          Ok
                        </Button>
                      )}

                      {/* <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(job)}
                      >
                        Delete
                      </Button> */}
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </>
  );
}

export default DeploymentJobStatus;
