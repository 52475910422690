import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  CardColumns,
  Card,
  Breadcrumb,
  Alert,
  Jumbotron,
  Form,
} from "react-bootstrap";
import {
  Route,
  Switch,
  useParams,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";

import { PieChart } from "react-minimal-pie-chart";

import http from "../services/APIService";
import Location from "./Dashboard/Location";

import userContext from "../contexts/userContext";
import Building from "./Dashboard/Building";
import { AuthService } from "../services/AuthService";

function Dashboard(props) {
  const params = useParams();
  const history = useHistory();
  const browserLocation = useLocation();
  const user = useContext(userContext);
  const { locationId } = params;
  let [locations, setLocations] = useState(undefined);
  const [userInfoEndpoint, setUserInfoEndpoint] = useState(undefined);
  const [userInfo, setUserInfo] = useState(undefined);
  const authService = new AuthService();
  let showClaims = false;

  console.log("dashboard params", params, "location", browserLocation);

  async function getLocations() {
    // const loadingTimeout = setTimeout(() => {
    //   setLoading("done");
    // }, 500);

    const endpoint = `location`;

    const result = await http.callApi("get", endpoint);
    if (result) {
      setLocations(result.data);
      //clearTimeout(loadingTimeout);
      //setLoading("done");
      console.log("getLocations() result", result);
    }
  }

  useEffect(() => {
    getLocations();
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     console.log("user >>>", user);
  //     getEndpoint();
  //   }
  // }, [user]);

  // const getEndpoint = async () => {
  //   authService.userManager.metadataService
  //     .getUserInfoEndpoint()
  //     .then((result) => {
  //       setUserInfoEndpoint(result);
  //       console.log("user info endpoint", result);
  //     });
  // };

  useEffect(() => {
    if (userInfoEndpoint) {
      //getUserInfo();
    }
  }, [userInfoEndpoint]);

  const getUserInfo = async () => {
    console.log("userInfoEndpoint", userInfoEndpoint);
    const result = await http.callRemoteApi(
      "get",
      "https://dev-79399304.okta.com/oauth2/default/v1/userinfo"
    );
    if (result) {
      var data = Object.keys(result).map(function (key) {
        // Using Number() to convert key to number type
        // Using obj[key] to retrieve key value
        return [key, result[key]];
      });

      setUserInfo(data);
      console.log("userInfo result", data);
    }
  };

  {
    if (!user)
      return (
        <>
          <hr />
          <Alert variant="primary">
            <span className="fa fa-info mr-2"></span>Please Log in to continue
          </Alert>
          Enter your credentials by clicking the link at top right of the page
          to proceed
        </>
      );
  }

  return (
    <>
      <div className="display-4 my-0 py-0">
        <span className="fas fa-tachometer-alt mr-2"></span>Dashboard
      </div>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("./")}>
          Home
        </Breadcrumb.Item>
        {browserLocation.pathname.includes("location") &&
          !browserLocation.pathname.includes("building") && (
            <Breadcrumb.Item active>Location</Breadcrumb.Item>
          )}
        {browserLocation.pathname.includes("building") && (
          <>
            <Breadcrumb.Item
              onClick={() => history.push(`/location/${locationId}`)}
            >
              Location
            </Breadcrumb.Item>

            <Breadcrumb.Item active>Buildings</Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>

      <Switch>
        {console.log("dashboard params", params)}
        <Route path="/location/:locationId/building/:buildingId">
          <Building />
        </Route>
        <Route exact path="/location/:locationId/building">
          <Redirect to="/" />
        </Route>
        <Route path="/location/:locationId">
          <Location />
        </Route>
        <Route exact path="/location">
          <Redirect to="/" />
        </Route>
        <Route exact path="/">
          {/* show all locations in a card each */}
          <CardColumns>
            {locations?.map((location) => {
              return (
                <Card
                  key={location.locationId}
                  style={{ width: "11rem" }}
                  className="mx-1"
                >
                  <Card.Header>
                    <span className="fa fa-university mr-2"></span>Campus
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{location.name}</Card.Title>
                    {Array.isArray(location.buildings) &&
                    location.buildings.length > 0 ? (
                      <>
                        <Card.Text>
                          Buildings: {location.buildings.length}
                          {/* <br />
                          ControlSystems:{" "}
                          {location.buildings[0].controlSystems.length} */}
                          <PieChart
                            className="m-1"
                            data={[
                              { title: "One", value: 10, color: "#9db8cf" },
                              { title: "Two", value: 15, color: "#316490" },
                              { title: "Three", value: 20, color: "#5c96c7" },
                            ]}
                          />
                        </Card.Text>

                        <Button
                          variant="outline-secondary"
                          onClick={() => {
                            console.log("location", location);
                            history.push(`/location/${location.locationId}`);
                          }}
                        >
                          <span className="fa fa-building mr-2"></span>
                          Buildings
                        </Button>
                      </>
                    ) : (
                      <Card.Text>
                        No Buildings for this location
                        <br />
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          onClick={() =>
                            history.push(
                              `/admin/location/${location.locationId}`
                            )
                          }
                        >
                          <span className="fa fa-plus mr-1"></span>Add
                        </Button>
                      </Card.Text>
                    )}
                  </Card.Body>
                </Card>
              );
            })}
          </CardColumns>
          {/* end locations */}
        </Route>

        <Route>
          {/* No Match */}
          <Redirect to="/not-found" />
        </Route>
      </Switch>
      <hr />
      {user && showClaims && (
        <Jumbotron>
          {console.log("user", user)}
          {/* <h4>user.profile</h4>{" "}
          <Form.Control as="textarea" rows="3" value={user.profile} /> */}
          <h4>user id token</h4>{" "}
          <Form.Control as="textarea" rows="3" value={user.id_token} />
          <h4>user access token</h4>{" "}
          <Form.Control as="textarea" rows="3" value={user.access_token} />
          <h4>token type</h4> {user.token_type}
          <h4>scope</h4> {user.scope}
          <h4>Profile</h4>
          {user?.profile && (
            <ul>
              <li>amr {user.profile.amr[0]}</li>
              <li>name {user.profile.name}</li>
              <li>given name {user.profile.given_name}</li>
              <li>family name {user.profile.family_name}</li>
              <li>email {user.profile.email}</li>
              <li>idp {user.profile.idp}</li>
              <li>
                email_verified{" "}
                {user.profile.email_verified === true ? "yes" : "no"}
              </li>
              <li>
                sub <b>{user.profile.sub}</b>
              </li>
              <li>
                role{" "}
                {Array.isArray(user.profile.role)
                  ? user.profile.role.map((role) => {
                      return <span className="fa fa-user">{role}</span>;
                    })
                  : user.profile.role}
              </li>
              <li>employeeNumber {user.profile.employeeNumber}</li>
              <li>org {user.profile.organisation}</li>
              {/* <li>org.guid {user.profile["organisation.guid"]}</li> */}
              <li>
                Roles:
                <ol>
                  {console.log("role", user.profile.role)}
                  {Array.isArray(user.profile.role) ? (
                    user.profile.role?.map((role) => {
                      return <li>{role}</li>;
                    })
                  ) : (
                    <li>{user.profile.role}</li>
                  )}
                </ol>
              </li>
            </ul>
          )}
        </Jumbotron>
      )}
      {userInfo && (
        <Jumbotron>
          <h5>User Info</h5>
          <ul>
            {/* {userInfo &&
              userInfo.map((item) => {
                return (
                  <li>
                    <b>{item[0]}</b> {item[1]}
                  </li>
                );
              })} */}
          </ul>
        </Jumbotron>
      )}
    </>
  );
}

export default Dashboard;
