import process from "process";

const development =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

// helper function detects if system is in deveopment mode
// by reading environment variable NODE_ENV
// NODE_ENV will either be undefined or 'development' for dev, or set to 'production'
// returns bool
export default function isDev() {
  return development;
}
