import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";

import applyRules from "react-jsonschema-form-conditionals";
import Engine from "json-rules-engine-simplified";
import predicate from "predicate";
import Form from "react-jsonschema-form";

import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import SchemaField from "react-jsonschema-form/lib/components/fields/SchemaField";
import _ from "lodash";

import http from "../../services/APIService";

import { getPathToJsonObj, deleteAllInJson } from "../../Utils";
import CopyToClip from "./CopyToClipboard";
import LoadingSpinner from "./LoadingSpinner";
import { toast } from "react-toastify";

import copy from "copy-to-clipboard";

import ErrorBoundary from "./ErrorBoundary";
import isDev from "./helpers/DevDetect";

import CustomFieldTemplate from "./CustomFieldsAndTemplates/CustomFieldTemplate";
import ArrayFieldTemplate from "./CustomFieldsAndTemplates/ArrayFieldTemplate";
import AutoPopulateObjectFieldTemplate from "./CustomFieldsAndTemplates/AutoPopulateObjectFieldTemplate";
import AutoPopulateSelectFieldTemplate from "./CustomFieldsAndTemplates/AutoPopulateSelectFieldTemplate";

import JsonEditor from "../admin/JsonEditor";
import CustomTitleField from "./CustomFieldsAndTemplates/CustomTitleField";

var jp = require("jsonpath");
var flatten = require("flat");

const maxTries = new Array(10);

var FormWithConditionals = null;

predicate._isEmpty = predicate.curry((val, flag) => {
  return _.isEmpty(val) === flag;
});

function DynamicForm(props) {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  // mustache stuff
  const Mustache = require("mustache");
  let propsAndParams = { ...props };
  propsAndParams.params = params;
  console.log("props.schema", props.schema);
  let mustachedSchema = JSON.parse(
    Mustache.render(JSON.stringify(props.schema), propsAndParams),
    undefined,
    2
  );
  console.log("mustachedSchema", mustachedSchema);
  console.log("propsAndParams", propsAndParams);
  // _.set(mustachedSchema, "controlSystem", { ...props.controlSystem });
  // console.log("mustachedSchema with controlSystem", mustachedSchema);

  let mustachedUISchema = props.uiSchema;
  // try {
  //   mustachedUISchema = JSON.parse(
  //     Mustache.render(JSON.stringify(props.uiSchema), propsAndParams),
  //     undefined,
  //     2
  //   );
  // } catch (ex) {
  //   console.log("mustache failed on uiSchema", props.uiSchema);
  //   console.error("Mustache failed on uiSchema", ex);
  // }

  // dont think i need mustache in uiSchema ????
  // let uiSchemaStr = "";
  // let mustachedUISchema = props.uiSchema;
  // JSON.stringify fails when there is a react component in the uiSchema - ie control system has "add new processor" modal link
  // try {
  //   uiSchemaStr = JSON.stringify(props.uiSchema);
  //   mustachedUISchema = props.uiSchema
  //     ? JSON.parse(Mustache.render(uiSchemaStr, propsAndParams), undefined, 2)
  //     : {};
  // } catch (e) {
  //   console.log("ui schema exception ", e);
  //   mustachedUISchema = props.uiSchema;
  // }
  // console.log("mustachedUISchema", mustachedUISchema);

  // is empty use props
  if (_.isEmpty(mustachedUISchema)) mustachedUISchema = props.uiSchema;

  let rules = props.rules
    ? JSON.parse(
        Mustache.render(JSON.stringify(props.rules), propsAndParams),
        undefined,
        2
      )
    : [];

  //let { rules } = props;

  const [schema, setSchema] = useState(_.isEmpty(rules) ? mustachedSchema : {});
  const [uiSchema, setUISchema] = useState(mustachedUISchema ?? {});
  // formData is only set when form is submitted (or copy button pressed)
  const [formData, setFormData] = useState(props.formData ?? {});
  // liveformData stores the changed formData object and sets formData when copy button pressed.
  // cant set formData when formChanges - the form looses focus etc when it reloads
  let liveformData = props.formData !== undefined ? props.formData : {};
  // when formData is set/ changed update the copytoClip text
  let rootIdSchema = {};
  const [extraErrors, setExtraErrors] = useState(props.extraErrors); // set as prop value first then serverError can overwrite it
  const [loading, setLoading] = useState("busy");

  // useEffect(() => {
  //   if (_.isEmpty(schema)) {
  //     //console.log("constructing schema", schema, rules);
  //     return;
  //   }

  //   console.log("schema was updated", schema, rules);
  //   if (!_.isEmpty(rules)) {
  //     let rulesEngine = new Engine();
  //     FormWithConditionals = applyRules(
  //       schema,
  //       uiSchema,
  //       [],
  //       rulesEngine
  //     )(Form);

  //     rules.map((rule, i) => {
  //       console.log("adding rule ", i, rule);
  //       rulesEngine.addRule(rule);
  //     });
  //   } else {
  //     FormWithConditionals = Form; // pass props below
  //   }
  // }, [schema]);

  useEffect(() => {
    console.log("dynamicForm props.extraErrors changed", props.extraErrors);
    setExtraErrors(props.extraErrors);
  }, [props.extraErrors]);

  useEffect(() => {
    updateSchema();
    updateUISchema();
  }, []); // load once on first render

  const updateSchema = async () => {
    let count = 1;
    let localSchema = { ...mustachedSchema };
    console.log("DynamicForm.UpdateSchema()", localSchema);
    // temporay

    for (const pass of maxTries) {
      //console.log("PASS #", count++);

      if (
        (await recurseControllers(
          localSchema,
          getControllerArray(localSchema),
          formData,
          uiSchema
        )) === false
      ) {
        //console.log("keep going");
      } else {
        // now load all dynamic properties
        console.log(
          "NOW LOAD ALL DYNAMIC PROPERTIES FOR SCHEMA : ",
          localSchema
        );
        // console.log(
        //   "NOW LOAD ALL DYNAMIC PROPERTIES FOR FORMDATA : ",
        //   formData
        // );
        // console.log(
        //   "getEnumPropertyArray() ",
        //   getEnumPropertyArray(localSchema)
        // );
        // populateSchemaEnumArrays(
        //   localSchema,
        //   getEnumPropertyArray(localSchema),
        //   formData,
        //   uiSchema
        // );
        // console.log("schema post getEnumPropertyArray()", localSchema);

        setSchema({ ...localSchema });
        break;
      }
    }

    // remove cgdone from schema, ready for next read
    deleteAllInJson(schema, "cgdone");
  };

  const populateSchemaEnumArrays = async (
    schema,
    propArray,
    formData,
    uiSchema
  ) => {
    let result = "";
    console.log("populateSchemaEnumArrays() ", schema);
    if (propArray.length == 0) {
      // console.log(
      //   ">>>>>>>>>>>>>>>>>>>>>>>>>>>>> populateSchemaEnumArrays() propArray EMPTY > RETURN <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",
      //   schema
      // );

      return true;
    }
    for (let pathToPropStr of propArray) {
      let propName = jp.query(schema, pathToPropStr + ".cgpopulatelist")[0];
      let listData = jp.query(formData, propName.pathToData)[0];
      let path = pathToPropStr.replace("$.", "");

      //console.log("cgselectprompt")
      console.log("cgpopulatelist", pathToPropStr, propName, listData, path);
      console.log("formData", formData);
      console.log("schema", schema);
      console.log("cgpopulatelist listData", listData);
      let enumArr = [];
      let enumNamesArr = [];

      if (_.isArray(listData) && listData.length > 0) {
        if (propName.filter) {
          let funcStr = "return data.filter(" + propName.filter + ")";
          let filterFunc = new Function("data", funcStr);
          let filteredData = filterFunc(listData);
          listData = { ...filteredData };
        }
        enumArr = _.map(listData, propName.idField);
        enumNamesArr = _.map(listData, propName.nameField);

        console.log("enumArr", enumArr);
        console.log("enumNamesArr", enumNamesArr);

        // if (propName.cgselectprompt) {
        //   // unshift adds to start of array
        //   enumArr.unshift(0);
        //   enumNamesArr.unshift("--select--");
        // }
        console.log(
          "listData[propName.defaultItemIndex]",
          listData[propName.defaultItemIndex]
        );
        if (propName.defaultItemIndex !== undefined) {
          let defaultItem = listData[propName.defaultItemIndex];
          if (propName.idField)
            defaultItem = listData[propName.defaultItemIndex][propName.idField];
          console.log("defaultItem", defaultItem);
          _.set(schema, path + ".default", defaultItem);
        }
        _.set(schema, path + ".enum", enumArr);
        _.set(schema, path + ".enumNames", enumNamesArr);

        console.log("enumArr", enumArr);
        console.log("enumNamesArr", enumNamesArr);
      } else {
        // no data
        // _.set(schema, path + ".type", "null");
        // _.set(schema, path + ".description", "no items found to populate list");
        //let extraErrors = { ...extraErrors };
        console.log("path to sourceGroupId", path);
        //_.set(extraErrors, path + ".__errors", ["no data yo"]);
        //setServerErrors(extraErrors);
      }

      console.log("schema", schema);
    }
  };

  const updateUISchema = async () => {
    // working with uiSchema
    // look for cgquery objects in uiSchema json and call api backend for visibility status
    const updatedUiSchema = await uiSchemaObjectVisibility(uiSchema);
    //console.log("updatedUiSchema:::", updatedUiSchema);
    setUISchema(updatedUiSchema);
  };

  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  // recurseControllers
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  const recurseControllers = async (
    schema,
    controllers,
    formData,
    uiSchema
  ) => {
    let result = "";
    console.log(
      "RECURSECONTROLLERS START ------------------------> controllers :",
      controllers
    );
    //console.log("controllers.length", controllers.length);
    if (controllers.length == 0) {
      console.log(
        ">>>>>>>>>>>>>>>>>>>>>>>>>>>>> CONTROLLERS EMPTY > RETURN <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",
        schema
      );
      console.log("set SCHEMAAAAAAAAAA >>>> ", schema);
      //setSchema(schema);
      setLoading("complete");
      return true;
    }
    for (let pathToControllerStr of controllers) {
      if (jp.query(schema, pathToControllerStr + ".cgdone")[0] === undefined) {
        let prop = jp.query(schema, pathToControllerStr + ".cgcontroller")[0];
        let cgquery = jp.query(schema, pathToControllerStr + ".cgquery")[0];

        const { data, status } = await getServerDataForProp(
          schema,
          pathToControllerStr,
          prop
        );

        // console.log(
        //   "+++++++++++++++ server data for prop [" + prop + "]",
        //   data,
        //   "\nstatusCode:" + status + "\n",
        //   "+++++++++++++++"
        // );
        // ------------------------------------------------------------------------------
        // console.log("cgquery", cgquery);
        // console.log("pathToControllerStr", pathToControllerStr);

        if (!_.isEmpty(data) && status === 200) {
          if (Array.isArray(data) && data.length > 0) {
            assignServerDataToObjectInSchema(
              schema,
              formData,
              data,
              pathToControllerStr
            );
          } // json object return so load into schema (eg oneOf object)
          else if (typeof data === "object") {
            let path = pathToControllerStr.replace("$.", "");
            _.set(schema, path, data);
          }
        } else {
          assignEmptyEnumToObjectInSchema(schema, pathToControllerStr);
        }

        // set as done so it wont be read again
        let path = pathToControllerStr.replace("$.", "");
        _.set(schema, path + ".cgdone", true);
      }
    }
    // console.log(
    //   "SCHEMAAAAAAAAAA being updated with dynamic data >>>> ",
    //   schema
    // );
    // let newSchema = { ...schema };
    // setSchema(newSchema);

    return false;
  };

  // ==============================================================
  // FUNCTION: getServerDataForProp
  // schema: jsonObject in which to set returned json
  // propPath: jsonPath path eg "$.properties.audioDSPTypeId"
  // controller: name of server side controller to fetch values
  // ==============================================================
  const getServerDataForProp = async (schema, propPath, controller) => {
    const cgquery = jp.query(schema, propPath + ".cgquery")[0];

    let serverData = {};

    // convert any {param} values in endpoint controller string
    let modifiedController = controller; //Mustache.render(controller, params);

    // api call
    if (cgquery != null) {
      // post with query json
      let api = `${modifiedController}`;
      serverData = await http.callApi("post", api, cgquery);
    } else {
      // get api call
      serverData = await http.callApi("get", `${modifiedController}`);
    }
    //console.log("getServerDataForProp() serverData", serverData);
    return {
      data: serverData ? serverData.data : null,
      status: serverData?.status,
    };
  };

  // controllers are used to retrieve data from api backend
  // then used recursively
  const getControllerArray = (schema) => {
    let controllerArray = [];
    let temp = [];

    temp = jp.paths(schema, "$..cgcontroller");

    for (let pathToController of temp) {
      pathToController.pop(); // remove cgcontroller from path array
      let pathToControllerStr = jp.stringify(pathToController);
      if (jp.query(schema, pathToControllerStr + ".cgdone")[0] === undefined)
        controllerArray.push(pathToControllerStr);
    }

    return controllerArray;
  };

  // enum property will load a rjsf enum using the already retrieved data

  const getEnumPropertyArray = (schema) => {
    let propertyArray = [];
    let temp = [];
    //console.log("getEnumPropertyArray() schema", schema);
    temp = jp.paths(schema, "$..cgpopulatelist");

    for (let pathToProperty of temp) {
      pathToProperty.pop(); // remove cgcontroller from path array
      let pathToPropertyStr = jp.stringify(pathToProperty);
      if (jp.query(schema, pathToPropertyStr + ".cgdone")[0] === undefined)
        propertyArray.push(pathToPropertyStr);
    }

    return propertyArray;
  };

  // ********************************************
  // Function: assignServerDataToObjectInSchema()
  // the array returned from the backend as requested in the json schema
  // is assigned to enum values
  // ********************************************
  const assignServerDataToObjectInSchema = (
    schema,
    formData,
    data,
    pathToControllerStr
  ) => {
    let propName = pathToControllerStr.split(".").pop();
    let field = jp.query(schema, pathToControllerStr)[0];
    let idName = jp.query(schema, pathToControllerStr + ".cgidname")[0];
    //if (idName === undefined) idName = propName + "Id";
    // console.log(
    //   "assignServerDataToObjectInSchema()",
    //   idName,
    //   formData,
    //   propName,
    //   field
    // );

    let cgselectprompt = jp.query(
      schema,
      pathToControllerStr + ".cgselectprompt"
    )[0];
    let cgallowundefined = jp.query(
      schema,
      pathToControllerStr + ".cgallowundefined"
    )[0];
    let cgdefaultindex = jp.query(
      schema,
      pathToControllerStr + ".cgdefaultindex"
    )[0];

    console.log("DATAAAA ", data);

    if (field !== undefined) {
      var enumArr = [];
      var enumNamesArr = [];
      // LOAD ENUM list using idName
      if (idName !== undefined) {
        for (let e = 0; e < data.length; e++) {
          // handle the situation where could be name or Name
          let name = data[e].name;
          if (name === undefined && data[e].Name != undefined) {
            name = data[e].Name;
          }
          enumArr.push(data[e][idName]);
          enumNamesArr.push(name);
        }
      } // set response as the enum
      else {
        console.log(
          "idName is undefined! setting result array directly as enum value. If this is undesired result, set a idName (no longer is set automatically based on controller)"
        );
        enumArr = data;
      }
      console.log("cgselectprompt", cgselectprompt);
      if (cgselectprompt) {
        // unshift adds to start of array
        enumArr.unshift(0);
        enumNamesArr.unshift("--select--");
      }

      field.enum = enumArr.slice(0, enumArr.length);
      field.enumNames = enumNamesArr.slice(0, enumArr.length);
      // if you add a default there will no longer be the empty item at the top of the list
      if (!cgallowundefined)
        field.default =
          field.enum[cgdefaultindex !== undefined ? cgdefaultindex : 0];

      // set formData for changed field (otherwise wont update) and default collection value (so validation doesnt complain)
      let pathToObjInFormData = getPathToJsonObj(formData, propName);

      // CUrrent Value
      let currentValue = _.get(formData, pathToObjInFormData);

      // use default value if not yet
      if (
        currentValue === null ||
        currentValue === undefined ||
        currentValue === 0
      )
        currentValue = field.default;

      // console.log(
      //   "assignServerDataToObjectInSchema() pathToObjInFormData ",
      //   pathToObjInFormData,
      //   " currentValue",
      //   currentValue
      // );
      if (pathToObjInFormData !== null) {
        _.set(formData, pathToObjInFormData, currentValue);
        setFormData(formData);
      }
    }

    return field;
  };

  // ********************************************
  // Function: assignServerDataToObjectInSchema()
  // the array returned from the backend as requested in the json schema
  // is assigned to enum values
  // ********************************************
  const assignEmptyEnumToObjectInSchema = (schema, pathToControllerStr) => {
    let field = jp.query(schema, pathToControllerStr)[0];

    console.log("assignEmptyEnum", field, pathToControllerStr, schema);

    if (field !== undefined) {
      var enumArr = [];
      var enumNamesArr = [];

      // unshift adds to start of array
      enumArr.unshift(0);
      enumNamesArr.unshift("--empty list--");

      field.enum = enumArr.slice(0, enumArr.length);
      field.enumNames = enumNamesArr.slice(0, enumArr.length);
      // if you add a default there will no longer be the empty item at the top of the list
      field.default = 0;
    }
    return field;
  };

  // ********************************************
  // Function: getQueryObjects()
  // create an array of all objects in the UISchema that have cgquery objects
  // each query object is used to call the backend to verify visibility
  // ********************************************
  const getQueryObjects = () => {
    let objects = [];
    let temp = [];

    temp = jp.paths(uiSchema, "$..cgquery");

    for (let path of temp) {
      path.pop(); // remove queries from path array
      let pathStr = jp.stringify(path);
      objects.push(pathStr);
    }

    return objects;
  };

  // ********************************************
  // Function: uiSchemaObjectVisibility ()
  // api query calls to the backend to determine if an object is visible
  // sets the object's "ui:widget": "hidden" in the UISchema
  // ********************************************
  const uiSchemaObjectVisibility = async (uiSchema) => {
    var queryObjects = getQueryObjects(uiSchema);

    for (let pathToObject of queryObjects) {
      let cgqueryObject = jp.query(uiSchema, pathToObject + ".cgquery")[0];
      //console.log("uiSchemaObjectVisibility() cgqueryObject", cgqueryObject);
      // call query visibility api
      // default endpoint if not defined
      let endpoint = `query/visibililty/${params.id}`; // goooonnnnee
      // convert any {param} values in endpoint controller string
      if (cgqueryObject["endpoint"] !== undefined)
        //endpoint = Mustache.render(cgqueryObject["endpoint"], params);
        endpoint = cgqueryObject["endpoint"];
      var result = await http.callApi("post", endpoint, cgqueryObject);
      if (result.status !== 200)
        setUiSchemaWidget(uiSchema, pathToObject, result.status);
    }
    return uiSchema;
  };

  // ********************************************
  // Function: setUiSchemaWidget()
  // if a response code of 204 (no content) (or anything other than 200)
  // is returned the ui:widget object will be set to "hidden"
  // nothing done if 200 ok response received
  // ********************************************
  const setUiSchemaWidget = (uiSchema, pathToObject, statusCode) => {
    let pathNode = jp.nodes(uiSchema, pathToObject)[0].path;

    let PathToElementInUISchemaStr = jp.stringify(pathNode);

    // remove $. for lodash to work
    PathToElementInUISchemaStr = PathToElementInUISchemaStr + ".ui:widget";
    PathToElementInUISchemaStr = PathToElementInUISchemaStr.replace("$.", "");

    // hidden
    if (statusCode !== 200) {
      // will be 204 no content, but could be 400 bad request or some other server error
      _.set(uiSchema, PathToElementInUISchemaStr, "hidden");
    }
  };

  // -----------------------------------------------------
  // EVENT HANDLERS
  // -----------------------------------------------------

  // ******************************
  // Function: handleFormChange()
  // called when anything on the form changes
  // if a function passed in via props this will be called and passed the formData
  // also sets formData state object
  // ********************************
  const handleFormChange = (e) => {
    console.log("+++++++++++++++ FORM CHANGED ::>", e);
    // pass back to parent
    if (
      props.handleFormChange !== undefined &&
      typeof props.handleFormChange === "function"
    ) {
      props.handleFormChange(e.formData);
    }
    // if  you set formData on every formChange then the form will loose focus when it reloads
    // now done only on submit
    // setFormData(e.formData); // cant do this
    liveformData = e.formData; // update this local var here and when copy button is pressed, then setFormData to this value
  };

  // ******************************
  // Function: handleFieldChange()
  // called when any field changes
  // if a function passed in via props this will be called
  // and passed the name of the field that changed and its current value
  // this function can then perform some function based on the specific field that changed
  // ********************************
  const handleFieldChange = async (name, fieldFormData, idSchema) => {
    //console.log("dynamicForm.handleFieldChange()", name, idSchema.$id);

    if (typeof fieldFormData === "object") {
      return;
    }
    console.log(
      "handleFieldChange()",
      getPathToJsonObj(schema, name),
      name,
      fieldFormData,
      idSchema
    );

    if (
      name.toLowerCase().includes("json") ||
      name.toLowerCase().includes("schema")
    )
      return;

    // pass up to the parent for local handling
    if (
      props.handleFieldChange !== undefined &&
      typeof props.handleFieldChange === "function"
    ) {
      props.handleFieldChange(name, fieldFormData);
    }
  };
  function transformErrors(errors) {
    return errors.map((error) => {
      console.log("transformErrors() error", error);
      if (error.name === "pattern") {
        error.message = "Only digits are allowed";
      }
      return error;
    });
  }

  const findPropAndValidate = (
    errors,
    formData,
    flatFormData,
    prop,
    occupiedList
  ) => {
    // get all props with [prop] in its name (by flattening the json)
    let ipidProps = Object.keys(flatFormData).filter((key) =>
      key.toLowerCase().includes(prop.toLowerCase())
    );
    // using this list iterate through the prop names and pass to the validate function
    ipidProps.map((i) => {
      //console.log("item", i);
      let propName = i.split(".").pop();
      //console.log("propName", propName);

      validateOccupiedItem(formData, errors, propName, occupiedList);
    });
  };

  const validate = (formData, errors) => {
    console.log("validate() errors ", errors);
    // console.log("validate() ", formData, errors, props.controlSystem);

    //console.log("props", props);
    // add custom errors
    //errors.jsonSchema.addError("custom error");

    if (typeof props.validate === "function") {
      props.validate(formData, errors);
    }

    // then must return errors
    return errors;
  };

  const validateOccupiedItem = (formData, errors, field, occupiedItems) => {
    // console.log("validateOccupiedItem", field, formData);
    if (_.isEmpty(occupiedItems)) return;

    let nodes = jp.nodes(formData, "$.." + field);

    for (let node of nodes) {
      //console.log("node", node);

      let result = _.find(occupiedItems, function (o) {
        // console.log("occupiedItem: ", o.occupiedByDeviceId);
        // console.log("formData", formData);
        if (_.isEmpty(o.entityName)) return;
        let deviceIdName = _.camelCase(o.entityName + "Id");
        // console.log("deviceIdName", deviceIdName, formData[deviceIdName]);
        return (
          (o.itemId === node.value &&
            o.portDeviceId === 0 &&
            props.name.toLowerCase() !== o.entityName.toLowerCase()) ||
          (o.itemId === node.value &&
            o.portDeviceId === 0 &&
            props.name.toLowerCase() === o.entityName.toLowerCase() &&
            o.occupiedByDeviceId !== _.get(formData, deviceIdName)) ||
          // o.occupiedBy.toLowerCase() !== formData?.name?.toLowerCase()) ||
          // expansion portDevice ports
          (o.itemId === node.value &&
            o.portDeviceId > 0 &&
            props.name.toLowerCase() === o.entityName.toLowerCase() &&
            o.occupiedBy.toLowerCase() !== formData?.name?.toLowerCase() &&
            o.portDeviceId === formData.jsonCommsData?.portDeviceId) // same port device
        );
      });
      if (result !== undefined) {
        let error = `Value entered is assigned to ${result.entityName}: "${result.occupiedBy}"`;

        let pathStr = jp.stringify(node.path);
        let tmp = jp.apply(errors, pathStr, function (value) {
          value.addError(error);
          return value;
        });
      }
    }
    return errors;
  };

  // ******************************
  // Function: validateOnSubmit()
  // when the form is submitted and if serverSideValidation is enabled
  // an api post to the validation endpoint is called.
  // formdata and other objects sent to help with validation
  // if the validation fails, the RJSF serverErrors object is set to display errors on the form
  // if no errors then the form event data is sent to the parent component for submission of the data to the server
  // todo: *further work required
  // ******************************
  const validateOnSubmit = async (e) => {
    console.log("dynamicForm validateOnSubmit() e", e);

    setFormData(e.formData);

    let validateObject = {
      controlSystemId: params.id,
      path: location.url,
      formData: e.formData,
      schema: schema,
      idAccessor: props.idName,
      dbSetAccessor: props.name,
    };

    //return;
    let data = e.formData;

    let errs = {};
    // if (props.serverSideValidation) {
    //   try {
    //     // call the server
    //     let obj = {
    //       controlSystemId: params.id,
    //       path: location.url,
    //       formData: data,
    //       schema: schema,
    //       device: {
    //         name: props.name,
    //         id: props.id,
    //       },
    //     };

    //     const result = await http.callApi("post", "validation/", obj);
    //   } catch (error) {
    //     //toast.error("Form Data has validation errors");
    //     errs = http.handleHttpErrors(error, {
    //       ...serverErrors,
    //     });

    //     console.log("!!serverErrors", errs.formData);

    //     setServerErrors(errs.formData);
    //   }
    // }
    // only pass to parent if no server errors
    //console.log("_.isEmpty(serverErrors)", _.isEmpty(errs));
    if (_.isEmpty(errs)) {
      console.log("passing to parent handleSubmit()", e);
      props.handleSubmit(e);
    }
  };

  // -----------------------------------------------------
  // RENDER
  // -----------------------------------------------------

  const { showErrorList, liveValidate, handleSubmit } = props;
  // *********************************
  // Function: CustomSchemaField()
  // allows tracking of individual field change events with RJSF
  // when a field's value is changed, the formData state is updated
  // *********************************
  const CustomSchemaField = function (props) {
    //console.log("CustomSchemaField props", props);
    const customProps = {};
    if (props.idSchema && props.idSchema["$id"] === "root") {
      rootIdSchema = { ...props.idSchema };
      //console.log("CustomSchemaField root props", props);
    }

    // test errorSChema
    // if (props.name === "sourceGroupId") {
    //   console.log("idSChema for sourceGroupId", props.idSchema["$id"]);
    // let idschema = props.idSchema["$id"];
    // let path = idschema.split("_");
    // path.shift(); // shift root (remove 1st element)
    // console.log("path", path);
    // let pathStr = path.join(".");
    // console.log("pathStr", pathStr);
    // let extraErrors = { ...serverErrors };
    // _.set(extraErrors, pathStr + ".__errors", ["no data yo"]);

    // }

    //Only process if we are dealing with a field, not the parent object
    if (props.name) {
      //console.log("customSchemaField prop name", props.name, props);
      const formContext = props.registry.formContext;
      //Store the original onChange event provided to the SchemaField
      //as well as the name of the field
      const { onChange, name } = props;
      //Provide a new onChange event for the SchemaField
      customProps.onChange = function (formData) {
        //Call the custom handler provided in the formContext, if it exists,
        //with the field name and new value
        if (
          formContext &&
          formContext.onFieldChange &&
          typeof formContext.onFieldChange === "function"
        ) {
          formContext.onFieldChange(name, formData, props.idSchema);
        }
        //Call the original onChange handler
        onChange(formData);
      };
    }
    return <SchemaField {...props} {...customProps} />;
  };

  const copyFormData = () => {
    copy(JSON.stringify(liveformData, undefined, 2));
    toast("Copied");
  };
  const copyUiSchema = () => {
    copy(JSON.stringify(uiSchema, undefined, 2));
    toast("Copied");
  };

  if (!_.isEmpty(rules)) {
    let rulesEngine = new Engine();
    FormWithConditionals = applyRules(schema, uiSchema, [], rulesEngine)(Form);

    rules.map((rule, i) => {
      console.log("adding rule ", i, rule);
      if (rule !== null && !_.isEmpty(rule)) rulesEngine.addRule(rule);
    });
  } else {
    FormWithConditionals = Form; // pass props below
  }
  let ordering = true;

  let fields = {
    SchemaField: CustomSchemaField,
    TitleField: CustomTitleField,
    "cg:autoPopulateObjectField": (props) => (
      <AutoPopulateObjectFieldTemplate {...props} />
    ),
    "cg:autoPopulateSelectField": (props) => (
      <AutoPopulateSelectFieldTemplate {...props} />
    ), // "use cg:customField from uiSchema to specify this custom field template for the one field"
  };

  const widgets = {
    jsonEditorSchema: (props) => (
      <JsonEditor height="550px" showCopyButtons {...props} />
    ),
    jsonEditorSchemaShort: (props) => (
      <JsonEditor height="300px" showCopyButtons {...props} />
    ),
    jsonEditor: (props) => (
      <JsonEditor
        theme="tomorrow_night"
        height="550px"
        showCopyButtons
        hidePreview
        {...props}
      />
    ),
    jsonEditorShort: (props) => (
      <JsonEditor
        theme="tomorrow_night"
        height="300px"
        showCopyButtons
        hidePreview
        {...props}
      />
    ),
    jsonEditorXShort: (props) => (
      <JsonEditor
        theme="tomorrow_night"
        height="175px"
        showCopyButtons
        hidePreview
        {...props}
      />
    ),
  };
  function ErrorListTemplate(props) {
    console.log("ErrorListTemplate props", props);
    const { errors } = props;
    const [show, setShow] = useState(false);
    if (show) {
      return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>
            <span className="fa fa-exclamation-triangle mr-2"></span>Errors
          </Alert.Heading>

          <ul>
            {errors.map((error) => (
              <li key={error.stack}>{error.stack}</li>
            ))}
          </ul>
          {/* <hr />
          <i>Errors must be resolved before the form can be submitted</i> */}
        </Alert>
      );
    }
    return (
      <Alert variant="danger">
        <Container fluid>
          <Row>
            <Col>
              <span className="fa fa-exclamation-triangle mr-2"></span>Form has
              Errors
            </Col>
            <Col md="auto">
              <Alert.Link variant="danger" onClick={() => setShow(true)}>
                Show
              </Alert.Link>
            </Col>
          </Row>
        </Container>
      </Alert>
    );
  }
  // RENDER RJSForm and Buttons
  return (
    <>
      <h1></h1>
      {loading === "busy" ? (
        <>
          {/* DynamicForm : loading */}
          <LoadingSpinner />
          {/* <h1>Loading</h1> */}
        </>
      ) : (
        <div>
          {FormWithConditionals && (
            <>
              {/* FormWithConditionals */}
              <ErrorBoundary>
                <FormWithConditionals
                  // --
                  // schema and uiSchema used when FormWithConditionals set to plain Form component (when no rules )
                  schema={schema}
                  uiSchema={uiSchema}
                  // --
                  formData={formData}
                  liveValidate={liveValidate ?? true}
                  showErrorList //={props.showErrorList ?? isDev()} // this needs to be true in development, eg if a field gets renamed
                  noHtml5Validate={props.noHtml5Validate ?? true}
                  //onSubmit={e => handleSubmit(e)} // this is how you would pass event with parameter directly to the parent component
                  onSubmit={validateOnSubmit} // i want to receive it here first to validate the form on the server side, then pass to the parent component if valid
                  onChange={handleFormChange}
                  formContext={{
                    onFieldChange: handleFieldChange,
                    configuration: props.configuration,
                    controlSystem: props.controlSystem,
                  }}
                  widgets={widgets}
                  fields={fields}
                  extraErrors={extraErrors}
                  omitExtraData // clears extra data onSubmit only
                  liveOmit={false} // clears extra data onFormChange
                  FieldTemplate={CustomFieldTemplate}
                  ArrayFieldTemplate={ArrayFieldTemplate} // globally assigns arrayFieldTemplate to every array in schema
                  validate={validate} // validate (called on form Submit, just before onSubmit function) cant use this for async calls to server FMD!
                  //transformErrors={transformErrors}
                  ErrorList={ErrorListTemplate}
                  onError={(err) => console.log("DynamicForm onERROR ", err)}
                >
                  {/* this space needs to be here otherwise the default green submit button will show */}{" "}
                  {props.preview ? (
                    <>
                      <hr />
                      <Button
                        variant="primary"
                        onClick={() => toast("Form is for Preview Only")}
                      >
                        <span className="fa fa-save mr-1"></span>Save
                      </Button>
                    </>
                  ) : props.hideSubmitAndCancel === true ? (
                    <>
                      <span className="fa fa-info mr-2"></span>
                      <i>form data will be automatically updated on a change</i>
                    </>
                  ) : (
                    <>
                      <hr />
                      <Button variant="primary" type="submit" className="mr-1">
                        <span className="fa fa-save mr-1"></span>Save
                      </Button>
                      {typeof props.handleCancel === "function" && (
                        <Button
                          variant="secondary"
                          onClick={props.handleCancel}
                          className="mr-1"
                        >
                          <span className="fa fa-times-circle mr-1"></span>
                          Cancel
                        </Button>
                      )}
                      {typeof props.handleReloadFormData === "function" && (
                        <Button
                          variant="secondary"
                          onClick={props.handleReloadFormData}
                        >
                          <span className="fa fa-undo mr-1"></span>
                          Reset
                        </Button>
                      )}
                      {props.showDelete && (
                        <>
                          <Button
                            variant="danger"
                            className="ml-2"
                            onClick={props.handleDelete}
                          >
                            <span className="fa fa-trash mr-1"></span>
                            Delete
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </FormWithConditionals>
              </ErrorBoundary>
            </>
          )}

          {formData && formData.createdBy && !formData.lastModifiedBy && (
            <div>
              created by{" "}
              {`${formData?.createdBy}  ${new Date(formData?.created)}`}
            </div>
          )}

          {formData && formData.lastModifiedBy && (
            <div>
              last modified by{" "}
              {`${formData?.lastModifiedBy}  ${new Date(
                formData?.lastModified
              )}`}
            </div>
          )}

          {isDev() && (
            <>
              <hr />
              Copy to clipboard <br />
              <CopyToClip
                text={JSON.stringify(schema, null, 2)}
                label="Schema"
              />{" "}
              <Button
                variant="light"
                className="btn btn-sm mt-1"
                onClick={copyFormData}
              >
                <i className="fas fa-copy mr-2"></i>Form Data
              </Button>{" "}
              <Button
                variant="light"
                className="btn btn-sm mt-1"
                onClick={copyUiSchema}
              >
                <i className="fas fa-copy mr-2"></i>Ui Schema
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default DynamicForm;
