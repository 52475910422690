import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import _ from "lodash";

import AdminNav from "./adminNav";
import http from "../../../services/APIService";
import JsonEditor from "./../JsonEditor";

import { IsJsonString } from "../../../Utils";

import DynamicForm from "../../common/DynamicForm";
import LoadingSpinner from "../../common/LoadingSpinner";

var jp = require("jsonpath");

function DynamicFormContainer(props) {
  const params = useParams();
  const id = params.id;
  const idName = props.name + "Id";

  const moreName = props.morename;
  const moreId = params[props.morename + "Id"];

  let endpoint =
    props.endpoint !== undefined
      ? `${props.endpoint}/${id}`
      : `${props.name}/${id}`;

  if (moreName !== undefined) {
    endpoint = endpoint + `/${moreName}`;
    if (moreId !== "new") endpoint = endpoint + `/${moreId}`;
  }

  let baseRoute = props.name.toLowerCase();

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState("busy");

  useEffect(() => {
    if (id === "new" || moreId === "new") {
      console.log("add new ", props.schema);
      return;
    }

    // get the item from db
    console.log("formData", formData);
    console.log(
      ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>get item from backend using id in route - id ",
      id
    );

    const fetchData = async () => {
      try {
        //alert(endpoint);
        const result = await http.callApi("get", endpoint);
        console.log("Item returned successfully ", result.data);

        if (result && result.data) {
          let formData = { ...result.data };
          // stringify the fields returned that are json Schemas ready for editing by ace editor
          // this step is needed because rjsf with have an error "should be string" when loading json or null
          // into string type field (ie needs to be a string)
          if (!_.isEmpty(props.uiSchema)) {
            let jsonArray = getJsonSchemaUIWidgets(props.uiSchema, formData);
            convertJsonFieldsInFormData(formData, jsonArray, "stringify");
          }
          // update the form
          setFormData(formData);
          setLoading("ready");
        }
      } catch (error) {
        console.log(error);
        //toast.error("errrrr");
      }
    };

    fetchData();
  }, [id, endpoint]);

  const convertJsonFieldsInFormData = (formData, jsonArray, method) => {
    jsonArray.map((widget, i) => {
      let jpPath = jp.paths(formData, `$..${widget}`);
      console.log("jpPath to jsonSchema in formdata", jpPath);

      jpPath.map((p, i) => {
        let pathStr = jp.stringify(p);
        let propData = jp.query(formData, pathStr)[0];

        console.log("propData", propData);
        console.log("pathStr", jp.stringify(p), "i", i);
        // remove $. for lodash to work
        pathStr = pathStr.replace("$.", "");

        if (method === "stringify")
          _.set(
            formData,
            pathStr,
            propData !== null ? JSON.stringify(propData, undefined, 2) : ""
          );
        else if (method === "parse") {
          if (IsJsonString(propData)) {
            _.set(formData, pathStr, JSON.parse(propData));
            return true;
          }
          return false;
        }
      });
    });
  };
  const validateJsonFieldsInFormData = (formData, widget, errors) => {
    let jpPath = jp.paths(formData, `$..${widget}`);
    console.log("validateJsonFieldsInFormData path", jpPath);

    jpPath.map((p, i) => {
      let pathStr = jp.stringify(p);
      let propData = jp.query(formData, pathStr)[0];

      if (_.isEmpty(propData) || IsJsonString(propData)) {
        return errors;
      } else {
        let errorObj = jp.value(errors, p);
        errorObj.addError("Invalid JSON");
      }
    });
  };

  /*
   * function: getJsonSchemaUIWidgets()
   * return array of strings (prop names)
   * that have been assigned the custom widget "jsonEditor" in UISchema
   * "ui:widget": "jsonEditor" or "jsonEditorShort"
   * this json data needs to be stringify'd before loading in textarea and
   * parsed back into json before sending to the backend
   */
  const getJsonSchemaUIWidgets = (uiSchema, formData) => {
    let jsonArray = [];

    findAllInJson(uiSchema, formData, "ui:widget", "jsonEditor", jsonArray);
    console.log("jsonArray", jsonArray);
    return jsonArray;
  };

  function findAllInJson(obj, formData, prop, filterValue, resultArray) {
    let result = "";
    //console.log("findAllFIltered()", obj);
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        if (typeof obj[p] === "object") {
          console.log(
            "p",
            p,
            "obj[p]",
            obj[p],
            "prop",
            prop,
            "haswonproperty",
            obj[p].hasOwnProperty(prop)
          );
          if (obj[p] !== null && obj[p].hasOwnProperty(prop)) {
            if (
              obj[p][prop].toLowerCase().includes(filterValue.toLowerCase())
            ) {
              console.log("find p in resultArray", _.indexOf(resultArray, p));
              if (_.indexOf(resultArray, p) < 0) resultArray.push(p);
            }
          } else {
            result += findAllInJson(
              obj[p],
              formData,
              prop,
              filterValue,
              resultArray
            );
          }
        }
      }
    }
  }

  /*
   * function: getPathToElementInJSON()
   * using the props in array gained from getJsonSchemaUIWidgets()
   * return the path for the prop in formData
   * uses JsonPath (jp) and lodash
   */
  const getPathToElementInJSON = (formData, prop) => {
    console.log(jp.nodes(formData, prop)[0]);
    let pathNode = jp.nodes(formData, prop)[0]?.path;
    if (!pathNode) return undefined;

    let pathToElementStr = jp.stringify(pathNode);

    // remove $. for lodash to work
    pathToElementStr = pathToElementStr.replace("$.", "");
    console.log("pathToElementStr", pathToElementStr);

    return pathToElementStr;
  };

  const handleSubmit = async ({ formData, uiSchema }) => {
    console.log("DynamicFormContainer.handleSubmit() :>", formData);

    // conversion to json
    // JSON.parse the fields that are json Schemas before posting to backend
    // this step is needed because the json is edited as a string in rjsf
    // and needs to be converted back to json object for the backend to consume

    let jsonArray = getJsonSchemaUIWidgets(uiSchema, formData);
    console.log("formData1", formData);
    convertJsonFieldsInFormData(formData, jsonArray, "parse");

    // let jsonArray = getJsonSchemaUIWidgets(uiSchema, formData);
    // for (let prop of jsonArray) {
    //   let propData = jp.query(formData, prop)[0];
    //   //console.log("prop", prop, " propData", propData);
    //   //let jpPath = jp.paths(formData, `$..${prop}`);
    //   //console.log("jpPath", jpPath);
    //   let pathToElementStr = getPathToElementInJSON(formData, prop);
    //   _.set(formData, pathToElementStr, IsJsonString(propData) && !_.isEmpty(propData) ? JSON.parse(propData) : null);
    // }
    console.log("formData2", formData);

    try {
      if (id === "new") {
        const result = await http.callApi("post", `${props.name}`, formData);
        console.log("result ::> ", result);
        if (result.status !== 201) {
          toast.error(
            "Error adding the item (" +
              result.status +
              " " +
              result.statusText +
              ")"
          );
        } else {
          // created 201
          toast.success("Item added successfully");
          props.history.push("/admin/" + baseRoute);
        }
      } else if (moreId === "new") {
        const result = await http.callApi("post", `${endpoint}`, formData);
        console.log("result ::> ", result);
        if (result.status !== 201) {
          toast.error(
            "Error adding the item (" +
              result.status +
              " " +
              result.statusText +
              ")"
          );
        } else {
          // created 201
          toast.success("Item added successfully");
          props.history.push("/admin/" + baseRoute);
        }
      } else {
        //formData.jsonSchema = JSON.parse(formData.jsonSchema);
        const result = await http.callApi("put", `${endpoint}`, formData);

        if (result.status !== 201 && result.status !== 204) {
          // sometimes created (201) and mostly 204 (no content)
          toast.error(
            "Error updating the item (" +
              result.status +
              " " +
              result.statusText +
              ")"
          );
        } else {
          // noContent 204
          toast.success("Item updated successfully");
          //props.history.push("/admin/" + baseRoute);
          window.location.href = "/admin/" + baseRoute;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (formData, errors) => {
    let jsonArray = getJsonSchemaUIWidgets(props.uiSchema, formData);
    jsonArray.map((widget) => {
      validateJsonFieldsInFormData(formData, widget, errors);
    });
  };

  return (
    <div>
      <div className="display-4 my-0 py-0">
        <span className="fas fa-user-cog mr-2"></span>Administration
      </div>
      <div>
        <AdminNav path={props.match.path} />
      </div>
      {id === "new" || moreId === "new" || loading === "ready" ? (
        <>
          <DynamicForm
            schema={props.schema}
            uiSchema={props.uiSchema}
            formData={formData}
            handleSubmit={handleSubmit}
            liveValidate
            showErrorList={false}
            serverSideValidation={props.serverSideValidation}
            handleCancel={() => props.history.push("/admin/" + baseRoute)}
            showCopyButtons={props.showCopyButtons}
            validate={validate}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}

export default DynamicFormContainer;
